import React, { useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import { orderBy, slice } from 'lodash'
import CmsAction from 'pages/cms/actions/CmsAction'
import { shallowEqual, useDispatch, useSelector } from 'react-redux'
import DocumentCard from './DocumentCard'
import i18n from 'simple-react-i18n'
import { mainWhite } from '../components/styled/theme'
import { mainBlack } from 'components/styled/Theme'
import { Grid, Icon } from '@mui/material'
import useStateProgress from 'utils/customHook/useStateProgress'
import ProgressBar from 'components/progress/ProgressBar'
import { push } from 'connected-react-router'
import useApplicationSetting from 'utils/customHook/useApplicationSetting'
import CmsDialog from './CmsDialog'

const ActualitiesCard = ({
    width = '100%',
    height,
}) => {
    const actualitiesId = useApplicationSetting('categoriesCmsActualites', setting => setting ? parseInt(setting) : undefined)

    const {
        cmsEvents,
        cmsCategories,
        cmsTitle,

        cmsCategoriesStatus,
    } = useSelector(store => ({
        cmsEvents: store.CmsReducer.cmsEvents,
        cmsCategories: store.CmsReducer.cmsCategories,
        cmsTitle: store.CmsReducer.cmsTitle,

        cmsCategoriesStatus: store.DataManagerReducer.cms.cmsCategoriesStatus,
    }), shallowEqual)

    const [eventsLoaded, setEventsLoaded] = useState(false)
    const [isOpenMessages, setIsOpenMessages] = useState(false)
    const [selectedCms, setSelectedCms] = useState(undefined)

    const { isLoaded: categsLoaded } = useStateProgress([ cmsCategoriesStatus ])
    const isLoaded = eventsLoaded && categsLoaded

    const dispatch = useDispatch()

    useEffect(() => {
        if (!cmsEvents.length) {
            dispatch(CmsAction.fetchCMSEvents()).then(() => setEventsLoaded(true))
        } else {
            setEventsLoaded(true)
        }
        if (!cmsCategories.length) {
            dispatch(CmsAction.fetchCMSCategories())
        }
    }, [])

    useEffect(() => {
        if (selectedCms?.id) {
            setIsOpenMessages(true)
        }
    }, [selectedCms])

    const panelHeight = height || `calc(100vh - ${cmsTitle.id ? '266px' : '236px'})`

    return (
        <div style={{ width, backgroundColor: mainWhite, height: panelHeight, borderRadius: '5px', boxShadow: '0px 0px 26px 0px rgb(0 0 0 / 12%)' }}>
            <Grid container sx={{ maxHeight: '100%', overflowY: 'auto' }}>
                <Grid
                    container item xs={12}
                    justifyContent='space-between'
                    alignItems='center'
                    sx={{
                        fontSize: '20px',
                        backgroundColor: mainBlack,
                        color: mainWhite,
                        fontWeight: '600',
                        padding: '0.5rem 1rem',
                        borderRadius: '5px 5px 0 0',
                    }}
                >
                    <Grid item>
                        <span>{i18n.actualities}</span>
                    </Grid>
                    <Grid item>
                        <Icon
                            className='clickable'
                            onClick={() => dispatch(push('/documents/actualities'))}
                        >
                            more_horiz
                        </Icon>
                    </Grid>
                </Grid>
                <Grid container item xs={12} sx={{ maxHeight: 'calc(100% - 48px)', overflowY: 'auto' }}>
                    {isLoaded ?
                        slice(orderBy(cmsEvents.filter((c) => c.idCategory === actualitiesId), 'dateDebut', 'desc'), 0, 6)
                            .map((d) => (
                                <DocumentCard
                                    cms={d}
                                    little
                                    setIsOpenMessages={c => setSelectedCms(c)}
                                />
                            ))
                        : (<ProgressBar indeterminate title={`${i18n.actualitiesInProgress} ...`} />)
                    }
                </Grid>
            </Grid>
            <CmsDialog
                cmsId={selectedCms?.id}
                isOpenMessages={isOpenMessages}
                closeDialog={() => {
                    setSelectedCms(undefined)
                    setIsOpenMessages(false)
                }}
            />
        </div>
    )
}

ActualitiesCard.propTypes = {
    width: PropTypes.string,
    height: PropTypes.string,
}

export default ActualitiesCard