/* eslint-disable react/forbid-prop-types */
import React from 'react'
import PropTypes from 'prop-types'
import { Grid } from '@mui/material'
import { secondaryGrey, mainWhite } from 'pages/online/components/styled/theme'
import { mainBlue } from 'utils/constants/ColorTheme'

const Option = ({
    first = false,
    label = '',
    selected = true,
    onClick = () => {},
    xs = 1.5,
    sx = {},
}) => (
    <Grid
        container
        item xs={xs}
        alignItems='center'
        justifyContent='center'
        sx={{
            height: 30,
            marginLeft: !first && '2px',
            backgroundColor: selected ? mainBlue : secondaryGrey,
            cursor: 'pointer',
            ...sx,
        }}
        onClick={onClick}
    >
        <Grid item>
            <span style={{ fontWeight: 'bold', fontSize: 15, color: mainWhite }}>{label}</span>
        </Grid>
    </Grid>
)

Option.propTypes = {
    first: PropTypes.bool,
    label: PropTypes.string,
    selected: PropTypes.bool,
    onClick: PropTypes.func,
    xs: PropTypes.number,
    sx: PropTypes.shape({}),
}

export default Option
