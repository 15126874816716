export default class DtoCasing {
    constructor(obj) {
        this.idStation = obj.idStation
        this.order = obj.order
        this.startDepth = obj.startDepth
        this.endDepth = obj.endDepth
        this.aquiferCode = obj.aquiferCode
        this.aquiferRoof = obj.aquiferRoof
        this.aquiferFloor = obj.aquiferFloor
        this.internalCasingDiameter = obj.internalCasingDiameter
        this.externalCasingDiameter = obj.externalCasingDiameter
    }
}