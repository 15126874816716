import i18n from 'simple-react-i18n'

const getHardHydroDataTypes = () => [
    { id: 4, label: i18n.height, unit: 'm' },
    { id: 5, label: i18n.flow },
    { id: 6, label: i18n.speed },
]

const getModalite = (observationType, observationModalites4, observationModalites5) => {
    switch (observationType) {
        case 1: return observationModalites4
        case 2: return observationModalites5
        default: return []
    }
}

const getObservationColor = modaliteReference => {
    switch (modaliteReference) {
        case '1':
        case '1a':
            return 'BLUE_OBSERVATIONS'
        case '1f':
            return 'YELLOW_OBSERVATIONS'
        case '2':
            return 'ORANGE_OBSERVATIONS'
        case '3':
            return 'RED_OBSERVATIONS'
        case '4':
            return 'GREY_OBSERVATIONS'
        default:
            return 'WHITE'
    }
}

export { getHardHydroDataTypes, getObservationColor, getModalite }
