import i18n from 'simple-react-i18n'
import ApplicationConf from '../../../conf/ApplicationConf'
import { genericPromise } from '../../../utils/ActionUtils'
import HomeAction from '../../home/actions/HomeAction'
import { push } from 'connected-react-router'
import ToastrAction from 'toastr/actions/ToastrAction'
import { createAsyncThunk } from '@reduxjs/toolkit'
import LogAction from 'log/actions/LogAction'

const AccountAction = {

    fetchUserData: createAsyncThunk(
        'fetchUserData',
        async(login, { rejectWithValue, dispatch }) => {
            try {
                const response = await Promise.all([
                    dispatch(AccountAction.fetchUser(login)),
                    dispatch(AccountAction.fetchAccountHabilitations(login)),
                    dispatch(AccountAction.fetchAccountUserKeyfigure(login)),
                    dispatch(AccountAction.fetchAccountUserSettings(login)),
                    dispatch(AccountAction.fetchAccountStatistics(login)),
                    dispatch(AccountAction.fetchAccountStationStatistics(login)),
                    dispatch(AccountAction.fetchAccountUsageStatistics(login)),
                    dispatch(AccountAction.fetchAccountStations(login)),
                    dispatch(AccountAction.fetchAccountUserCmsStatistic(login)),
                ])
                return response
            } catch (err) {
                dispatch(LogAction.logError(`${i18n.fetchError} ${i18n.users}: ${err}`))
                dispatch(ToastrAction.error(i18n.fetchError))
                return rejectWithValue(err)
            }
        }
    ),

    fetchUser: createAsyncThunk(
        'fetchUser',
        async(login, { rejectWithValue, dispatch }) => {
            try {
                const response = await genericPromise(ApplicationConf.user.get(login))
                return response
            } catch (err) {
                dispatch(LogAction.logError(`${i18n.fetchError} ${i18n.user}: ${err}`))
                dispatch(ToastrAction.error(i18n.fetchError + i18n.user))
                return rejectWithValue(err)
            }
        }
    ),

    updateUser: createAsyncThunk(
        'updateUser',
        async(dataObj, { rejectWithValue, dispatch }) => {
            const { user, authorizedFetch = true } = dataObj
            try {
                const response = await genericPromise(ApplicationConf.user.put(), 'PUT', user)
                if (authorizedFetch) {
                    dispatch(HomeAction.fetchUsers())
                }
                ToastrAction.success(i18n.contactSuccessUpdated)
                return response
            } catch (err) {
                dispatch(LogAction.logError(`${i18n.fetchError} ${i18n.newPassword}: ${err}`))
                dispatch(ToastrAction.error(i18n.fetchError + i18n.newPassword))
                return rejectWithValue(err)
            }
        }
    ),

    updateUserSettings: createAsyncThunk(
        'updateUserSettings',
        async(dataObj, { rejectWithValue, dispatch }) => {
            const { user, settings } = dataObj
            try {
                const response = await genericPromise(ApplicationConf.user.saveUserParameters(user), 'PUT', settings)
                dispatch(AccountAction.fetchAccountUserSettings(user))
                return response
            } catch (err) {
                dispatch(LogAction.logError(`${i18n.updateError} ${i18n.parameters}: ${err}`))
                dispatch(ToastrAction.error(i18n.updateError + i18n.parameters))
                return rejectWithValue(err)
            }
        }
    ),

    changePassword: createAsyncThunk(
        'changePassword',
        async(password, { rejectWithValue, dispatch }) => {
            try {
                const response = await genericPromise(ApplicationConf.user.changePassword(), 'PUT', password)
                if (response.status === 200) {
                    ToastrAction.success(i18n.elementUpdateSuccess, true)
                } else {
                    ToastrAction.error(i18n.updateError + i18n.password, true)
                }
                return response
            } catch (err) {
                dispatch(LogAction.logError(`${i18n.fetchError} ${i18n.password}: ${err}`))
                dispatch(ToastrAction.error(i18n.fetchError + i18n.password))
                return rejectWithValue(err)
            }
        }
    ),

    fetchAccountUserCmsStatistic: createAsyncThunk(
        'fetchAccountUserCmsStatistic',
        async(login, { rejectWithValue, dispatch }) => {
            try {
                const response = await genericPromise(ApplicationConf.user.getCmsStatistics(login))
                return response
            } catch (err) {
                dispatch(LogAction.logError(`${i18n.fetchError}: ${err}`))
                dispatch(ToastrAction.error(i18n.fetchError))
                return rejectWithValue(err)
            }
        }
    ),

    fetchAccountUserKeyfigure: createAsyncThunk(
        'fetchAccountUserKeyfigure',
        async(login, { rejectWithValue, dispatch }) => {
            try {
                const response = await genericPromise(ApplicationConf.user.getKeyFigures(login))
                return response
            } catch (err) {
                dispatch(LogAction.logError(`${i18n.fetchError}: ${err}`))
                dispatch(ToastrAction.error(i18n.fetchError))
                return rejectWithValue(err)
            }
        }
    ),

    fetchAccountHabilitations: createAsyncThunk(
        'fetchAccountHabilitations',
        async(login, { rejectWithValue, dispatch }) => {
            try {
                const response = await genericPromise(ApplicationConf.user.observatoryHabilitations(login))
                return response
            } catch (err) {
                dispatch(LogAction.logError(`${i18n.fetchError}: ${err}`))
                dispatch(ToastrAction.error(i18n.fetchError))
                return rejectWithValue(err)
            }
        }
    ),

    fetchAccountUserSettings: createAsyncThunk(
        'fetchAccountUserSettings',
        async(_, { rejectWithValue, dispatch }) => {
            try {
                const response = await genericPromise(ApplicationConf.user.settings())
                return response
            } catch (err) {
                dispatch(LogAction.logError(`${i18n.fetchError}: ${err}`))
                dispatch(ToastrAction.error(i18n.fetchError))
                return rejectWithValue(err)
            }
        }
    ),

    fetchAccountStatistics: createAsyncThunk(
        'fetchAccountStatistics',
        async(login, { rejectWithValue, dispatch }) => {
            try {
                const response = await genericPromise(ApplicationConf.user.getStatistics(login))
                return response
            } catch (err) {
                dispatch(LogAction.logError(`${i18n.fetchError}: ${err}`))
                dispatch(ToastrAction.error(i18n.fetchError))
                return rejectWithValue(err)
            }
        }
    ),

    fetchAccountStationStatistics: createAsyncThunk(
        'fetchAccountStationStatistics',
        async(login, { rejectWithValue, dispatch }) => {
            try {
                const response = await genericPromise(ApplicationConf.user.getStationStatistics(login))
                return response
            } catch (err) {
                dispatch(LogAction.logError(`${i18n.fetchError}: ${err}`))
                dispatch(ToastrAction.error(i18n.fetchError))
                return rejectWithValue(err)
            }
        }
    ),

    fetchAccountUsageStatistics: createAsyncThunk(
        'fetchAccountUsageStatistics',
        async(login, { rejectWithValue, dispatch }) => {
            try {
                const response = await genericPromise(ApplicationConf.user.getUsageStatistics(login))
                return response
            } catch (err) {
                dispatch(LogAction.logError(`${i18n.fetchError}: ${err}`))
                dispatch(ToastrAction.error(i18n.fetchError))
                return rejectWithValue(err)
            }
        }
    ),

    fetchAccountStations: createAsyncThunk(
        'fetchAccountStations',
        async(login, { rejectWithValue, dispatch }) => {
            try {
                const response = await genericPromise(ApplicationConf.user.userStations(login))
                return response
            } catch (err) {
                dispatch(LogAction.logError(`${i18n.fetchError}: ${err}`))
                dispatch(ToastrAction.error(i18n.fetchError))
                return rejectWithValue(err)
            }
        }
    ),

    updatePassword: createAsyncThunk(
        'updatePassword',
        async(dataObj, { rejectWithValue, dispatch }) => {
            const { login, password } = dataObj
            try {
                const response = await genericPromise(ApplicationConf.user.updatePassword(login), 'PUT', password)
                if (response.status === 200) {
                    ToastrAction.success(i18n.elementUpdateSuccess, true)
                } else {
                    ToastrAction.error(i18n.updateError + i18n.password, true)
                }
                return response
            } catch (err) {
                dispatch(LogAction.logError(`${i18n.fetchError} ${i18n.password}: ${err}`))
                dispatch(ToastrAction.error(i18n.fetchError + i18n.password))
                return rejectWithValue(err)
            }
        }
    ),

    deleteUser: createAsyncThunk(
        'deleteUser',
        async(login, { rejectWithValue, dispatch }) => {
            try {
                const response = await genericPromise(ApplicationConf.user.delete(login), 'DELETE')
                dispatch(HomeAction.fetchUsers()).then(() => {
                    dispatch(push('/users'))
                })
                ToastrAction.success(i18n.deleteUserSuccessMessage)
                return response
            } catch (err) {
                dispatch(LogAction.logError(`${i18n.deleteUserErrorMessage}: ${err}`))
                dispatch(ToastrAction.error(i18n.deleteUserErrorMessage))
                return rejectWithValue(err)
            }
        }
    ),

    fetchContributorAccount: createAsyncThunk(
        'fetchContributorAccount',
        async(id, { rejectWithValue, dispatch }) => {
            try {
                const response = await genericPromise(ApplicationConf.referencial.contributor(id))
                return response
            } catch (err) {
                dispatch(LogAction.logError(`${i18n.fetchError} ${i18n.contributor}: ${err}`))
                dispatch(ToastrAction.error(i18n.fetchError + i18n.contributor))
                return rejectWithValue(err)
            }
        }
    ),
}

export default AccountAction
