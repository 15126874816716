import { useMemo } from 'react'
import { shallowEqual, useSelector } from 'react-redux'

const useApplicationSetting = (field, parser = setting => setting) => {
    const {
        applicationSettings,
    } = useSelector(store => ({
        applicationSettings: store.HomeReducer.applicationSettings,
    }), shallowEqual)

    const res = useMemo(() => {
        const setting = applicationSettings.find(s => s.parameter === field)
        return parser(setting?.value)
    }, [applicationSettings, field])
    return res
}

export default useApplicationSetting