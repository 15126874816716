// Choisir une des deux usedUrl ci dessous

// A utiliser pour le serveur
const myUrl = (window.location.href || document.URL).split('#')[0]

const url = 'https://dev.sieau.fr/'
// const url = 'https://sieau.brgm.fr/'
// const url = 'https://manager.observatoire-nappe-roya.com/'

const observatoryUrl = 'https://recette.observatoire.myliaq.fr/#'

const usedUrl = myUrl.includes('localhost') ? url : myUrl
const observatoryUsedUrl = myUrl.includes('localhost') ? observatoryUrl : `${myUrl}#`

// ////////////////////////////////////////////////////////////
const path = `${usedUrl}${usedUrl.endsWith('/') ? '' : '/'}api/`
const contentsPath = `${usedUrl}${usedUrl.endsWith('/') ? '' : '/'}contents/`
const cmsPath = `${contentsPath}CMS/`

export { path, contentsPath, cmsPath, myUrl, usedUrl, observatoryUsedUrl }
