import React, { useMemo, useState } from 'react'
import PropTypes from 'prop-types'
import { Paper, Table, TableBody, TableCell, TableContainer, TableHead, TablePagination, TableRow, TableSortLabel, Toolbar, Typography } from '@mui/material'
import { isFunction } from 'lodash'
import { nbPerPageLabel } from 'pages/online/referencials/constants/ReferencialConstants'
import { getI18nOrLabel } from 'utils/StringUtil'
import i18n from 'simple-react-i18n'
import { mainBlack } from 'components/styled/Theme'

const descendingComparator = (a, b, orderBy) => {
    if (b[orderBy] < a[orderBy]) {
        return -1
    }
    if (b[orderBy] > a[orderBy]) {
        return 1
    }
    return 0
}

const getComparator = (order, orderBy) => order === 'desc'
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy)

// Since 2020 all major browsers ensure sort stability with Array.prototype.sort().
// stableSort() brings sort stability to non-modern browsers (notably IE11). If you
// only support modern browsers you can replace stableSort(exampleArray, exampleComparator)
// with exampleArray.slice().sort(exampleComparator)
const stableSort = (array, comparator) => {
    const stabilizedThis = array.map((el, index) => [el, index])
    stabilizedThis.sort((a, b) => {
        const order = comparator(a[0], b[0])
        if (order !== 0) {
            return order
        }
        return a[1] - b[1]
    })
    return stabilizedThis.map((el) => el[0])
}

const EnhancedTableToolbar = ({
    title = '',
    actions = [],
}) => (
    <Toolbar
        sx={{
            pl: { sm: 2 },
            pr: { xs: 1, sm: 1 },
        }}
    >
        <Typography
            sx={{ flex: '1 1 100%' }}
            variant='h6'
            id='tableTitle'
            component='div'
        >
            {title}
        </Typography>
        {actions}
    </Toolbar>
)

EnhancedTableToolbar.propTypes = {
    title: PropTypes.string,
    actions: PropTypes.arrayOf(PropTypes.element),
}

const EnhancedTableHead = ({
    headers,
    customHeaders,
    order,
    orderBy,
    onRequestSort,
}) => {
    const createSortHandler = (property) => (event) => {
        onRequestSort(event, property)
    }

    return (
        <TableHead>
            <TableRow>
                {headers.map((h, i) => (
                    <TableCell
                        key={`${h}_${i}`}
                        align='left'
                        sortDirection={orderBy === h ? order : false}
                        sx={{ fontWeight: '600', borderBottom: `1px solid ${mainBlack}`, padding: '0.5rem' }}
                    >
                        <TableSortLabel
                            active={orderBy === h}
                            direction={orderBy === h ? order : 'asc'}
                            onClick={createSortHandler(h)}
                        >
                            {customHeaders[h] || getI18nOrLabel(h)}
                        </TableSortLabel>
                    </TableCell>
                ))}
            </TableRow>
        </TableHead>
    )
}

EnhancedTableHead.propTypes = {
    headers: PropTypes.arrayOf(PropTypes.string),
    customHeaders: PropTypes.shape({}),
    order: PropTypes.oneOf(['asc', 'desc']).isRequired,
    orderBy: PropTypes.string.isRequired,
    onRequestSort: PropTypes.func.isRequired,
}

const SimpleTable = ({
    title = '',
    actions = [],
    headers = [],
    customHeaders = [],
    datas = [],
    showHeaders = true,
    showTitle = false,
    densePadding = false,
    onClick,
    nbPerPage = nbPerPageLabel,
    showPaging = true,
}) => {
    const [order, setOrder] = useState('asc')
    const [orderBy, setOrderBy] = useState(headers[0])
    const [page, setPage] = useState(0)
    const [rowsPerPage, setRowsPerPage] = useState(nbPerPage[0])

    const handleRequestSort = (_, property) => {
        const isAsc = orderBy === property && order === 'asc'
        setOrder(isAsc ? 'desc' : 'asc')
        setOrderBy(property)
    }

    const handleChangePage = (event, newPage) => {
        setPage(newPage)
    }

    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(parseInt(event.target.value, 10))
        setPage(0)
    }

    // Avoid a layout jump when reaching the last page with empty rows.
    const emptyRows = page > 0 ? Math.max(0, (1 + page) * rowsPerPage - datas.length) : 0

    const visibleDatas = useMemo(() => stableSort(datas, getComparator(order, orderBy)).slice(
        page * rowsPerPage,
        page * rowsPerPage + rowsPerPage
    ), [datas, order, orderBy, page, rowsPerPage])

    return (
        <Paper sx={{ width: '100%', mb: 2, marginBottom: 0 }}>
            {!!showTitle && <EnhancedTableToolbar title={title} actions={actions} />}
            <TableContainer component={Paper}>
                <Table sx={{ minWidth: 650 }} aria-label='simple table' size={densePadding ? 'small' : 'medium'}>
                    {!!showHeaders && (
                        <EnhancedTableHead
                            headers={headers}
                            customHeaders={customHeaders}
                            order={order}
                            orderBy={orderBy}
                            onRequestSort={handleRequestSort}
                        />
                    )}
                    <TableBody>
                        {visibleDatas.map((data, index) => (
                            <TableRow
                                hover
                                onClick={() => isFunction(onClick) && onClick(data.id)}
                                tabIndex={-1}
                                key={`${data?.[headers[0]]}_${index}`}
                                sx={{
                                    '&:last-child td, &:last-child th': { border: 0 },
                                    cursor: isFunction(onClick) && 'pointer',
                                }}
                            >
                                {headers.map(h => (
                                    <TableCell
                                        component='th'
                                        scope='row'
                                        sx={{ padding: '0.5rem' }}
                                    >
                                        {data?.[h]}
                                    </TableCell>
                                ))}
                            </TableRow>
                        ))}
                        {emptyRows > 0 && (
                            <TableRow
                                style={{
                                    height: (densePadding ? 33 : 53) * emptyRows,
                                }}
                            >
                                <TableCell colSpan={6} sx={{ padding: '0.5rem' }} />
                            </TableRow>
                        )}
                    </TableBody>
                </Table>
            </TableContainer>
            {!!showPaging && (
                <TablePagination
                    rowsPerPageOptions={nbPerPage}
                    component='div'
                    count={datas.length}
                    rowsPerPage={rowsPerPage}
                    page={page}
                    onPageChange={handleChangePage}
                    onRowsPerPageChange={handleChangeRowsPerPage}
                    labelRowsPerPage={i18n.rowsPerPage}
                    labelDisplayedRows={({ page: nbPage }) => `Page ${nbPage + 1}`}
                />
            )}
        </Paper>
    )
}

SimpleTable.propTypes = {
    title: PropTypes.string,
    actions: PropTypes.arrayOf(PropTypes.element),
    headers: PropTypes.arrayOf(PropTypes.string),
    datas: PropTypes.arrayOf(PropTypes.shape({
        id: PropTypes.number,
    })),
    nbPerPage: PropTypes.arrayOf(PropTypes.number),
    customHeaders: PropTypes.shape({}),

    showHeaders: PropTypes.bool,
    showTitle: PropTypes.bool,
    densePadding: PropTypes.bool,
    showPaging: PropTypes.bool,

    onClick: PropTypes.func,
}

export default SimpleTable