import React from 'react'
import { Accordion, AccordionDetails, AccordionSummary } from '@mui/material'
import { styled } from '@mui/styles'
import Icon from 'components/Icon'
import { mainBlack } from './Theme'

export const AccordionMUI = styled(({ sx, ...otherProps }) => (
    <Accordion
        disableGutters
        TransitionProps={{ unmountOnExit: true, timeout: 250 }}
        sx={{
            borderRadius: '5px',
            boxShadow: '0px 0px 26px 0px rgb(0 0 0 / 12%)',
            ...sx,
        }}
        {...otherProps}
    />))({
})

export const AccordionSummaryMUI = styled(({ sx, iconColor, ...otherProps }) => (
    <AccordionSummary
        expandIcon={
            <Icon
                size='small'
                icon='expand_more'
                style={{
                    borderRadius: '50%',
                    border: 'solid white 2px',
                    color: iconColor || 'white',
                }}
            />
        }
        sx={{
            color: 'white',
            background: otherProps.color || mainBlack,
            borderBottom: 'solid 1px grey',
            minHeight: 40,
            height: 40,
            fontSize: '15px',
            fontWeight: 'bold',
            borderRadius: '5px 5px 0 0',
            ...sx,
        }}
        {...otherProps}
    />))({
})

export const AccordionDetailsMUI = styled(({ sx, ...otherProps }) => (
    <AccordionDetails
        sx={{
            borderRadius: '0 0 5px 5px',
            ...sx,
        }}
        {...otherProps}
    />))({
    padding: (props) => (props.nopadding ? 0 : 'auto'), // nopadding create a warning, use this instead: sx={{ padding: 0 }}
})