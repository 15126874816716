import React from 'react'
import { Button } from '@mui/material'
import { styled } from '@mui/styles'
import { mainBlue, mainRed } from 'utils/constants/ColorTheme'

export const ButtonMUI = styled((props) => (
    <Button
        sx={{
            '&:focus': { backgroundColor: (theme) => props.color || (props.variant === 'outlined' ? 'white' : theme.palette.primary.main) },
        }}
        {...props}
    />))({
})

export const PrimaryButton = styled(({ ...other }) => <Button variant='contained' {...other} />)({
    width: '100%',
    textTransform: 'uppercase',
    border: props => props.reverse ? `1px solid ${mainBlue} !important` : 'none',
    color: props => props.reverse ? 'grey' : 'white' + ' !important',
    backgroundColor: props => props.disabled ? 'lightgray' : (props.reverse ? 'white !important' : mainBlue),
    '&:hover': {
        backgroundColor: props => props.disabled ? 'lightgray' : props.reverse ? 'white !important' : mainBlue,
    },
    margin: '5px 0',
    marginTop: props => props.margintop ? '1rem !important' : '0',
})

export const SecondaryButton = styled(({ ...other }) => <Button variant='contained' {...other} />)({
    width: '100%',
    textTransform: 'uppercase',
    border: props => props.reverse ? `1px solid ${mainBlue} !important` : 'none',
    color: props => props.reverse ? 'grey' : 'white' + ' !important',
    backgroundColor: props => props.disabled ? 'lightgray' : (props.reverse ? 'white !important' : mainBlue),
    '&:hover': {
        backgroundColor: props => props.disabled ? 'lightgray' : props.reverse ? 'white !important' : mainBlue,
    },
    margin: '5px 0',
    marginTop: props => props.margintop ? '1rem !important' : '0',
})

export const RedButton = styled(({ ...other }) => (<Button variant='contained' {...other} />))({
    width: '100%',
    textTransform: 'uppercase',
    border: props => props.reverse ? `1px solid ${mainRed}` : 'none',
    color: props => props.reverse ? 'grey' : 'white' + ' !important',
    backgroundColor: props => props.disabled ? 'lightgray' : props.reverse ? 'white' : mainRed,
    '&:hover': {
        backgroundColor: props => props.disabled ? 'lightgray' : props.reverse ? 'white' : mainRed,
    },
    margin: '5px 0',
    marginTop: props => props.margintop ? '1rem !important' : '0',
})
