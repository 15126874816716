export const mainGreen = '#79DA4C'
export const mainOrange = '#F6B427'
export const mainBlue = '#3f51b5' // '#3f51b5'
export const mainGrey = '#e7eaef'
export const managerBlue = '#3f51b5'
export const mainBlack = '#161832'
export const mainWhite = '#fff'
export const textBlue = '#01136b'
export const secondaryBlue = '#53A1FF'
export const secondaryGrey = '#d5d8de'
