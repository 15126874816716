import { FormControlLabel, Checkbox as CheckboxMui, Typography, Grid } from '@mui/material'
import React from 'react'
import PropTypes from 'prop-types'
import { darkBlue } from 'utils/constants/ColorTheme'

const SimpleCheckbox = ({
    checked = false,
    onToggle = () => {},
    label = '',
    labelStyle = {},
    beforeLabel,
    afterLabel,
}) => {
    return (
        <FormControlLabel
            control={(
                <CheckboxMui
                    checked={checked}
                    color='primary'
                    onChange={onToggle}
                    inputProps={{ 'aria-label': 'controlled' }}
                    sx={{
                        '& .MuiSvgIcon-root': {
                            fontSize: '2rem',
                        },
                    }}
                />
            )}
            label={(
                <Grid container alignItems='center'>
                    {!!beforeLabel && (
                        <Grid item sx={{ paddingRight: '0.5rem' }}>
                            {beforeLabel}
                        </Grid>
                    )}
                    <Grid item>
                        <Typography
                            sx={{
                                color: darkBlue,
                                fontWeight: 'bold',
                                fontSize: '1rem',
                                ...labelStyle,
                            }}
                        >
                            {label}
                        </Typography>
                    </Grid>
                    {!!afterLabel && (
                        <Grid item sx={{ paddingLeft: '0.5rem' }}>
                            {afterLabel}
                        </Grid>
                    )}
                </Grid>
            )}
            sx={{
                '& .MuiButtonBase-root': {
                    padding: '0 9px',
                },
            }}
        />
    )
}

SimpleCheckbox.propTypes = {
    checked: PropTypes.bool,
    onToggle: PropTypes.func,
    label: PropTypes.string,
    labelStyle: PropTypes.object,
    beforeLabel: PropTypes.element,
    afterLabel: PropTypes.element,
}

export default SimpleCheckbox
