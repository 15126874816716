import DtoAccessibility from './DtoAccessibility'
import DtoAltimetrySystem from './DtoAltimetrySystem'
import DtoAquiferLink from './DtoAquiferLink'
import DtoArrangement from './DtoArrangement'
import DtoCasing from './DtoCasing'
import DtoDespoliation from './DtoDespoliation'
import DtoFunction from './DtoFunction'
import DtoGeoData from './DtoGeoData'
import DtoHydrogeologicalEntityLink from './DtoHydrogeologicalEntityLink'
import DtoLandmark from './DtoLandmark'
import DtoLithology from './DtoLithology'
import DtoLithologyType from './DtoLithologyType'
import DtoLocation from './DtoLocation'
import DtoLocationLink from './DtoLocationLink'
import DtoMeasureMethod from './DtoMeasureMethod'
import DtoNetworkLink from './DtoNetworkLink'
import DtoPiezometerContributorLink from './DtoPiezometerContributorLink'
import DtoStationContacts from './DtoStationContacts'
import DtoTempRef from './DtoTempRef'
import DtoUsage from './DtoUsage'
import DtoWatermassLink from './DtoWatermassLink'
import DtoWorkLink from './DtoWorkLink'

export default class DtoPiezometer {
    constructor(obj = {}) {
        this.id = obj.id
        this.code = obj.code
        this.name = obj.name
        this.comment = obj.comment
        this.typeName = 'piezometry'
        this.nature = obj.nature
        this.piezometerNature = obj.nature
        this.altitude = obj.altitude
        this.cote = obj.cote
        this.fieldMode = obj.fieldMode
        this.townCode = obj.townCode
        this.contactCode = obj.contactCode
        this.designation = obj.designation
        this.watershedCode = obj.watershedCode
        this.declarationTownCode = obj.declarationTownCode
        this.countryCode = obj.countryCode
        this.waterSourceType = obj.waterSourceType
        this.creationDate = obj.creation
        this.creation = obj.creation
        this.closeDate = obj.close
        this.close = obj.close
        this.address = obj.address
        this.x = obj.x
        this.y = obj.y
        this.projection = obj.projection
        this.isExploited = obj.isExploited
        this.jobExecutionId = obj.jobExecutionId
        this.localisation = new DtoLocation(obj, obj.altitude)
        this.localization = obj.localization
        this.stateCode = obj.stateCode
        this.link_accessibilities = obj.link_accessibilities ? obj.link_accessibilities.map(la => new DtoAccessibility(la)) : []
        this.link_altimetrySystems = obj.link_altimetrySystems && obj.link_altimetrySystems.length ? obj.link_altimetrySystems.map(l => new DtoAltimetrySystem(l)) : []
        this.link_aquifers = obj.link_aquifers ? obj.link_aquifers.map(l => new DtoAquiferLink(l)) : []
        this.link_arrangements = obj.link_arrangements ? obj.link_arrangements.map(la => new DtoArrangement(la)) : []
        this.link_casings = obj.link_casings ? obj.link_casings.map(lc => new DtoCasing(lc)): []
        this.link_contributors = obj.link_contributors ? obj.link_contributors.map(c => new DtoPiezometerContributorLink(c)): []
        this.link_despoliationModes = obj.link_despoliationModes ? obj.link_despoliationModes.map(d => new DtoDespoliation(d)) : []
        this.link_functions = obj.link_functions ? obj.link_functions.map(lf => new DtoFunction(lf)) : []
        this.link_geo = obj.link_geo ? obj.link_geo.map(g => new DtoGeoData(g)) : []
        this.link_hydroEntity = obj.link_hydroEntity ? obj.link_hydroEntity.map(he => new DtoHydrogeologicalEntityLink(he)) : []
        this.link_landmarks = obj.link_landmarks ? obj.link_landmarks.map(l => new DtoLandmark(l)) : []
        this.link_lithologicType = obj.link_lithologicType ? obj.link_lithologicType.map(lt => new DtoLithologyType(lt)) : []
        this.link_lithology = obj.link_lithology ? obj.link_lithology.map(ll => new DtoLithology(ll)) : []
        this.link_locations = obj.link_locations ? obj.link_locations.map(l => new DtoLocationLink(l)) : []
        this.link_measureMethod = obj.link_measureMethod ? obj.link_measureMethod.map(lmm => new DtoMeasureMethod(lmm)) : []
        this.link_networks = obj.link_networks ? obj.link_networks.map(l => new DtoNetworkLink(l)) : []
        this.link_contacts = obj.link_contacts ? obj.link_contacts.map(lc => new DtoStationContacts(lc)) : []
        // this.stationLinks
        this.link_tempRefs = obj.link_tempRefs ? obj.link_tempRefs.map(tr => new DtoTempRef(tr)) : []
        this.link_usages = obj.link_usages ? obj.link_usages.map(l => new DtoUsage(l)) : []
        this.link_watermasses = obj.link_watermasses ? obj.link_watermasses.map(l => new DtoWatermassLink(l)) : []
        this.link_work = obj.link_work ? obj.link_work.map(lw => new DtoWorkLink(lw)) : []
        this.controlErrors = obj.controlErrors || []
        this.updateLogin = obj.updateLogin
        this.updateDate = obj.updateDate
        this.previsionalVisitNumber = obj.previsionalVisitNumber
        this.monitoring = obj.monitoring
    }
}
