const colorObject = {
    aliceblue: '#f0f8ff',
    antiquewhite: '#faebd7',
    aqua: '#00ffff',
    aquamarine: '#7fffd4',
    azure: '#f0ffff',
    beige: '#f5f5dc',
    bisque: '#ffe4c4',
    black: '#000000',
    blanchedalmond: '#ffebcd',
    blue: '#0000ff',
    blueviolet: '#8a2be2',
    brown: '#a52a2a',
    burlywood: '#deb887',
    cadetblue: '#5f9ea0',
    chartreuse: '#7fff00',
    chocolate: '#d2691e',
    coral: '#ff7f50',
    cornflowerblue: '#6495ed',
    cornsilk: '#fff8dc',
    crimson: '#dc143c',
    cyan: '#00ffff',
    darkblue: '#00008b',
    darkcyan: '#008b8b',
    darkgoldenrod: '#b8860b',
    darkgray: '#a9a9a9',
    darkgreen: '#006400',
    darkgrey: '#a9a9a9',
    darkkhaki: '#bdb76b',
    darkmagenta: '#8b008b',
    darkolivegreen: '#556b2f',
    darkorange: '#ff8c00',
    darkorchid: '#9932cc',
    darkred: '#8b0000',
    darksalmon: '#e9967a',
    darkseagreen: '#8fbc8f',
    darkslateblue: '#483d8b',
    darkslategrey: '#2f4f4f',
    darkturquoise: '#00ced1',
    darkviolet: '#9400d3',
    deeppink: '#ff1493',
    deepskyblue: '#00bfff',
    dimgray: '#696969',
    dimgrey: '#696969',
    dodgerblue: '#1e90ff',
    firebrick: '#b22222',
    floralwhite: '#fffaf0',
    forestgreen: '#228b22',
    fuchsia: '#ff00ff',
    gainsboro: '#dcdcdc',
    ghostwhite: '#f8f8ff',
    gold: '#ffd700',
    goldenrod: '#daa520',
    gray: '#808080',
    green: '#008000',
    greenyellow: '#adff2f',
    grey: '#808080',
    honeydew: '#f0fff0',
    hotpink: '#ff69b4',
    indianred: '#cd5c5c',
    indigo: '#4b0082',
    ivory: '#fffff0',
    khaki: '#f0e68c',
    lavender: '#e6e6fa',
    lavenderblush: '#fff0f5',
    lawngreen: '#7cfc00',
    lemonchiffon: '#fffacd',
    lightblue: '#add8e6',
    lightcoral: '#f08080',
    lightcyan: '#e0ffff',
    lightgoldenrodyellow: '#fafad2',
    lightgray: '#d3d3d3',
    lightgreen: '#90ee90',
    lightgrey: '#d3d3d3',
    lightpink: '#ffb6c1',
    lightsalmon: '#ffa07a',
    lightseagreen: '#20b2aa',
    lightskyblue: '#87cefa',
    lightslategrey: '#778899',
    lightsteelblue: '#b0c4de',
    lightyellow: '#ffffe0',
    lime: '#00ff00',
    limegreen: '#32cd32',
    linen: '#faf0e6',
    magenta: '#ff00ff',
    maroon: '#800000',
    mediumaquamarine: '#66cdaa',
    mediumblue: '#0000cd',
    mediumorchid: '#ba55d3',
    mediumpurple: '#9370db',
    mediumseagreen: '#3cb371',
    mediumslateblue: '#7b68ee',
    mediumspringgreen: '#00fa9a',
    mediumturquoise: '#48d1cc',
    mediumvioletred: '#c71585',
    midnightblue: '#191970',
    mintcream: '#f5fffa',
    mistyrose: '#ffe4e1',
    moccasin: '#ffe4b5',
    navajowhite: '#ffdead',
    navy: '#000080',
    oldlace: '#fdf5e6',
    olive: '#808000',
    olivedrab: '#6b8e23',
    orange: '#ffa500',
    orangered: '#ff4500',
    orchid: '#da70d6',
    palegoldenrod: '#eee8aa',
    palegreen: '#98fb98',
    paleturquoise: '#afeeee',
    palevioletred: '#db7093',
    papayawhip: '#ffefd5',
    peachpuff: '#ffdab9',
    peru: '#cd853f',
    pink: '#ffc0cb',
    plum: '#dda0dd',
    powderblue: '#b0e0e6',
    purple: '#800080',
    rebeccapurple: '#663399',
    red: '#ff0000',
    rosybrown: '#bc8f8f',
    royalblue: '#4169e1',
    saddlebrown: '#8b4513',
    salmon: '#fa8072',
    sandybrown: '#f4a460',
    seagreen: '#2e8b57',
    seashell: '#fff5ee',
    sienna: '#a0522d',
    silver: '#c0c0c0',
    skyblue: '#87ceeb',
    slateblue: '#6a5acd',
    slategray: '#708090',
    snow: '#fffafa',
    springgreen: '#00ff7f',
    steelblue: '#4682b4',
    tan: '#d2b48c',
    teal: '#008080',
    thistle: '#d8bfd8',
    tomato: '#ff6347',
    turquoise: '#40e0d0',
    violet: '#ee82ee',
    wheat: '#f5deb3',
    white: '#ffffff',
    whitesmoke: '#f5f5f5',
    yellow: '#ffff00',
    yellowgreen: '#9acd32',
}

const formatColor = (color = 'white') => {
    const rgbColor = colorObject[color] ?? color
    return rgbColor.replace('#', '%23')
}

const getPiezoMarker = color => {
    const colorFormatted = formatColor(color)
    return `<svg version="1.2" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 33 44" width="33" height="44"><title>map_piezo-svg</title><g id="layer1"><path id="path630" fill="white" d="m16.6 31.5c-7.6 0-13.8-6.4-13.8-14.3 0-7.8 6.2-14.2 13.8-14.2 7.6 0 13.8 6.4 13.8 14.2 0 7.9-6.2 14.3-13.8 14.3z"/><g id="g585"><path id="path587" fill-rule="evenodd" fill="${colorFormatted}" d="m15.6 42.9c-0.3-0.1-0.8-0.8-1.2-1.5-1.1-1.8-2.4-3.3-6-7.1-3.6-3.9-5-5.7-6.3-8.3-1.4-2.9-1.7-4.6-1.7-8.3 0-3.6 0.3-4.9 1.6-7.6 2.1-4.5 5.8-7.6 10.5-8.9 2-0.5 5.6-0.5 7.6-0.1 8.3 2 13.6 9.8 12.6 18.8-0.6 5.2-2.5 8.6-8.2 14.5-2.9 3.1-4.8 5.4-5.8 7.1-1 1.6-1.9 2.1-3.1 1.4zm4.1-14.2c4.1-1 7.4-4.4 8.6-8.6 0.4-1.6 0.4-4.5 0-6.1-1.2-4.3-4.6-7.6-8.9-8.7-1.7-0.5-5.1-0.3-6.7 0.2-3.6 1.3-6.4 4.1-7.8 7.8-0.5 1.6-0.7 4.7-0.2 6.5 1.1 4.8 5.2 8.5 10 9.2 1.3 0.2 3.5 0.1 5-0.3zm-8.4-15.3h-5v-1.5h6.5v3.2c0 1.7 0.1 3.1 0.2 3.1 0.1 0 0.3-0.1 0.4-0.3 0.2-0.2 0.3-0.2 0.5 0.1 0.3 0.5 1.1 0.5 1.4 0l0.3-0.3 0.3 0.3q0.7 0.8 1.3 0l0.4-0.3 0.2 0.3c0.4 0.5 1.1 0.5 1.4 0 0.3-0.3 0.3-0.3 0.6-0.1 0.1 0.2 0.3 0.3 0.4 0.3 0 0 0.1-1.4 0.1-3.1v-3.2h6.6v1.5h-5.1v13.4h-10.5z"/></g></g></svg>`
}
const getCatchmentMarker = color => {
    const colorFormatted = formatColor(color)
    return `<svg version="1.2" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 33 44" width="33" height="44"><title>map_installation_captages-svg</title><g id="g"><path id="ellipse-2" fill="white" d="m16.5 31.2c-7.7 0-14-6.5-14-14.5 0-7.9 6.3-14.4 14-14.4 7.7 0 13.9 6.5 13.9 14.4 0 8-6.2 14.5-13.9 14.5z"/><g id="g-4"><path id="path-12" fill-rule="evenodd" fill="${colorFormatted}" d="m15.4 42.9c-0.2-0.2-0.8-0.9-1.2-1.6-1.1-1.9-2.4-3.3-6-7.2-3.7-3.9-5.1-5.8-6.4-8.5-1.4-2.9-1.7-4.6-1.7-8.4 0-3.6 0.2-5 1.6-7.7 2-4.5 5.8-7.7 10.6-9 2.1-0.5 5.7-0.5 7.7-0.1 8.4 2 13.7 10 12.7 19-0.6 5.3-2.4 8.8-8.2 14.8-3 3.2-4.9 5.4-5.9 7.2-1 1.7-2 2.1-3.2 1.5zm4.2-14.5c4.2-1 7.5-4.4 8.7-8.8 0.4-1.6 0.4-4.5 0-6.1-1.2-4.4-4.7-7.8-9-8.9-1.7-0.4-5.1-0.3-6.8 0.3-3.6 1.2-6.5 4.1-7.8 7.9-0.6 1.6-0.8 4.8-0.3 6.5 1.1 4.9 5.2 8.7 10.2 9.5 1.2 0.2 3.5 0 5-0.4z"/></g><path id="Layer" fill-rule="evenodd" fill="${colorFormatted}" d="m17.7 7.9c0.9 0.6 0.9 1 0.2 1.9-0.7 0.8-0.7 1.2 0.6 1.4 1.1 0.4 1.5 1 1.5 2.7 0 1.4-0.3 2.6-0.8 2.8-1.7 1.1-0.6 2.1 2.2 1.6l2.8-0.3-2.4 0.8c-3.8 1.6-3.8 2.1-0.3 2 2.1-0.1 3.3 0.2 3.7 0.8 0.2 0.9 0 0.9-1.2 0.3-2.1-1.1-5.5 0.3-5.5 2.3 0 1-0.6 1.4-2.3 1.4-1.7 0-2.2-0.4-2.2-1.5 0-1.9-4.2-3.4-6.2-2.2-0.9 0.4-1.5 0.4-1.5 0 0-1.2 2.8-1.9 5.6-1.3 3.2 0.7 1.9-0.8-1.8-1.8l-2.6-0.8 3.2 0.3c3 0.4 4.3-0.3 2.5-1.6-1.3-0.7-0.9-4.9 0.4-5.5 0.8-0.2 1-0.8 0.8-1.6-0.3-0.6 0-1.4 0.4-1.8 1.3-0.7 1.6-0.7 2.9 0.1zm-0.7 11.6c0-0.5-0.4-0.8-0.8-0.8-0.4 0-0.7 0.3-0.7 0.8 0 0.4 0.3 0.7 0.7 0.7 0.4 0 0.8-0.3 0.8-0.7zm-0.8 1.9c-0.4-0.3-0.7 0.2-0.7 1.1 0 0.8 0.3 1.6 0.7 1.6 0.5 0 0.8-0.5 0.8-1.1 0-0.6-0.3-1.4-0.8-1.6z"/><path id="Forme 1" fill="${colorFormatted}" d="m20.9 13.7c0-0.8 0.6-1.4 1.3-1.4h7.4v2.7h-7.4c-0.7 0-1.3-0.6-1.3-1.3z"/></g></svg>`
}
const getHydroMarker = color => {
    const colorFormatted = formatColor(color)
    return `<svg version="1.2" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 33 44" width="33" height="44"><title>map_hydro-svg</title><g id="g-1"><g id="g25"><path id="ellipse-1" fill="white" d="m16.6 31.4c-7.6 0-13.8-6.3-13.8-14.2 0-7.9 6.2-14.2 13.8-14.2 7.6 0 13.8 6.3 13.8 14.2 0 7.9-6.2 14.2-13.8 14.2z"/><g id="g458"><path id="path466" fill-rule="evenodd" fill="${colorFormatted}" d="m7.5 21.5c0-1.9 0-3.4 0-3.4 0 0 0.5 0.2 1 0.4 0.5 0.2 1.5 0.4 2.3 0.5l1.3 0.1v-10.7h7.9v9.3h1.9c1.1 0 2.1 0 2.5 0.1l0.5 0.1v6.9h-17.4zm8.5-3c1.2-0.2 2.2-0.4 2.4-0.5l0.5-0.2-0.1-4-0.1-4.1h-5.4v0.4c0 0.5 0.1 0.5 0.8 0.6 0.8 0 0.8 0.1 0.8 0.6 0 0.4-0.2 0.4-0.8 0.5-0.8 0.1-0.8 0.1-0.8 0.5 0 0.5 0 0.5 1.4 0.5h1.5v1l-1.4 0.1c-1.3 0.1-1.5 0.1-1.5 0.5-0.1 0.4 0 0.4 0.8 0.4 0.8 0 0.8 0 0.8 0.6 0 0.5 0 0.5-0.8 0.6-0.7 0-0.8 0.1-0.8 0.5 0 0.4 0.1 0.4 0.8 0.5 0.8 0.1 0.8 0.1 0.8 0.6 0 0.4-0.2 0.5-0.8 0.5-0.7 0.1-0.8 0.2-0.8 0.4 0 0.4 0.1 0.6 0.5 0.5 0.1 0 1.2-0.3 2.2-0.5z"/></g><g id="g-2"><path id="path-6" fill-rule="evenodd" fill="${colorFormatted}" d="m15.6 42.9c-0.3-0.1-0.8-0.8-1.3-1.5-1-1.8-2.3-3.3-5.8-7.1-3.7-3.9-5.1-5.7-6.4-8.4-1.4-2.9-1.7-4.5-1.7-8.2 0-3.6 0.3-4.9 1.6-7.6 2-4.5 5.8-7.7 10.5-8.9 2-0.5 5.6-0.5 7.6-0.1 8.3 2 13.6 9.8 12.6 18.7-0.6 5.3-2.5 8.6-8.1 14.6-3 3.1-4.9 5.4-5.9 7.1-0.9 1.6-1.9 2-3.1 1.4zm4.1-14.2c4.1-1 7.4-4.4 8.6-8.7 0.4-1.5 0.4-4.5 0-6-1.2-4.3-4.6-7.6-9-8.8-1.6-0.4-5-0.2-6.6 0.3-3.6 1.2-6.4 4.1-7.7 7.8-0.6 1.6-0.8 4.7-0.3 6.4 1.1 4.8 5.1 8.5 10 9.3 1.3 0.2 3.5 0.1 5-0.3z"/></g></g></g></svg>`
}
const getPluvioMarker = color => {
    const colorFormatted = formatColor(color)
    return `<svg version="1.2" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 33 44" width="33" height="44"><title>map_pluvio-svg</title><g id="g-1"><path id="ellipse-1" fill="white" d="m16.6 31.5c-7.6 0-13.8-6.5-13.8-14.5 0-8.1 6.2-14.6 13.8-14.6 7.6 0 13.8 6.5 13.8 14.6 0 8-6.2 14.5-13.8 14.5z"/><g id="g428"><path id="path430" fill="${colorFormatted}" d="m19.1 26.1c-0.4-0.4-0.4-1.3 0.1-2.1 0.5-0.8 0.9-0.8 1.3-0.2 0.6 0.8 0.7 1.9 0.3 2.3-0.5 0.5-1.3 0.5-1.7 0zm-6.9-3c-0.6-0.7-0.3-2.3 0.5-3.2 0.4-0.3 0.4-0.3 1 0.2 0.8 1 1 2.4 0.3 3.1-0.4 0.4-1.5 0.3-1.8-0.1zm3.1-4.4c-5.1-0.1-5.2-0.1-5.8-0.6q-1.2-0.8-1.2-2.3c0-1.4 1.1-2.7 2.3-3 0.6-0.1 0.7-0.2 0.8-0.7 0.4-1.9 2.4-3.1 4.1-2.4 0.3 0.1 0.6 0 1.2-0.4 0.6-0.5 1.1-0.6 1.9-0.7 2.7-0.2 4.5 1.6 4.5 4.3 0 0.6 0.1 0.7 0.8 1.1 1.8 0.9 1.9 3.5 0.1 4.4-0.7 0.4-2.3 0.4-8.7 0.3z"/></g><g id="g-2"><path id="path-6" fill-rule="evenodd" fill="${colorFormatted}" d="m15.6 43.3c-0.3-0.1-0.8-0.9-1.3-1.5-1-1.9-2.3-3.4-5.8-7.3-3.7-4-5.1-5.9-6.4-8.6-1.4-2.9-1.7-4.6-1.7-8.4 0-3.7 0.3-5 1.6-7.8 2-4.6 5.8-7.8 10.5-9.1 2-0.5 5.6-0.5 7.6-0.1 8.3 2.1 13.6 10.1 12.6 19.2-0.6 5.4-2.5 8.8-8.1 14.9-3 3.2-4.9 5.5-5.9 7.2-0.9 1.7-1.9 2.1-3.1 1.5zm4.1-14.5c4.1-1.1 7.4-4.5 8.6-8.8 0.4-1.7 0.4-4.7 0-6.3-1.2-4.4-4.6-7.8-9-9-1.6-0.4-5-0.2-6.6 0.3-3.6 1.3-6.4 4.2-7.7 8-0.6 1.6-0.8 4.8-0.3 6.6 1.1 4.9 5.1 8.7 10 9.5 1.3 0.2 3.5 0.1 5-0.3z"/></g></g></svg>`
}
const getQualityMarker = color => {
    const colorFormatted = formatColor(color)
    return `<svg version="1.2" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 33 44" width="33" height="44"><title>map_qualito-svg</title><g id="layer1"><path id="path630" fill="white" d="m16.7 31.4c-7.4 0-13.3-6.3-13.3-14.2 0-7.9 5.9-14.2 13.3-14.2 7.4 0 13.4 6.3 13.4 14.2 0 7.9-6 14.2-13.4 14.2z"/><g id="g382"><path id="path388" fill-rule="evenodd" fill="${colorFormatted}" d="m8.1 24.3q-0.7-0.3 0.7-2.1c0.5-0.7 1.9-2.5 3-4l2-2.8v-4c0-4.9-0.2-4.6 2.8-4.6 2.8 0 2.6-0.2 2.6 4.6l0.1 3.9 2.7 3.7c1.5 2 2.8 3.9 3 4.2 0.6 1.2 0.8 1.2-8.5 1.2-4.5 0-8.3-0.1-8.4-0.1zm15.1-1.3c0-0.1-0.2-0.5-0.5-0.9-0.6-0.7-0.7-0.7-1.6-0.7-0.5 0-1-0.1-1-0.2-0.1-0.1 0.2-0.2 0.7-0.2 0.4 0 0.8-0.1 0.8-0.1 0-0.1-0.3-0.5-0.6-1-0.6-0.7-0.6-0.8-1.7-0.8-0.6 0-1.1 0-1.1-0.1 0-0.1 0.4-0.2 0.9-0.2 0.5 0 0.9-0.1 0.9-0.1 0-0.1-0.3-0.5-0.6-1-0.6-0.8-0.6-0.8-1.6-0.8-0.6 0-1.1-0.1-1.1-0.2 0-0.1 0.4-0.2 0.8-0.2 0.8 0 1.1-0.2 0.7-0.4-0.1-0.1-0.2-1-0.2-2v-1.9h-2.7v3.5l-2.8 3.7-2.7 3.7h6.7c3.7 0 6.7-0.1 6.7-0.1zm-5.2-14.9h-2.7v2.8h2.7z"/></g><g id="g585"><path id="path587" fill-rule="evenodd" fill="${colorFormatted}" d="m15.7 42.9c-0.2-0.1-0.8-0.8-1.2-1.5-1-1.8-2.2-3.3-5.7-7.1-3.6-3.9-4.9-5.7-6.1-8.3-1.4-3-1.7-4.6-1.7-8.3 0-3.6 0.2-4.9 1.5-7.6 2-4.5 5.7-7.6 10.3-8.9 1.9-0.5 5.4-0.6 7.3-0.1 8.1 2 13.2 9.8 12.2 18.8-0.6 5.2-2.4 8.6-7.9 14.5-2.9 3.1-4.7 5.4-5.6 7.1-1 1.6-1.9 2.1-3.1 1.4zm4-14.2c4-1 7.2-4.4 8.4-8.6 0.4-1.6 0.4-4.6 0-6.2-1.2-4.2-4.5-7.5-8.7-8.7-1.6-0.4-4.9-0.3-6.5 0.3-3.5 1.2-6.2 4.1-7.5 7.8-0.6 1.6-0.7 4.7-0.3 6.4 1.2 4.8 5.1 8.5 9.8 9.3 1.2 0.2 3.3 0.1 4.8-0.3z"/></g></g></svg>`
}
const getInstMarker = color => {
    const colorFormatted = formatColor(color)
    return `<svg version="1.2" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 50 65" width="50" height="65"><title>map_installation-svg</title><g id="layer1"><path id="path630" fill="white" d="m25.4 46.5c-11.6 0-20.9-9.6-20.9-21.5 0-11.9 9.3-21.5 20.9-21.5 11.5 0 20.8 9.6 20.8 21.5 0 11.9-9.3 21.5-20.8 21.5z"/><path id="path280" fill="${colorFormatted}" d="m17.5 36.7c0-2.4 0-2.4 0.7-2.4h0.7v-4.1c0-4 0-4.2-0.7-5-0.7-0.8-0.7-1-0.7-4.6 0-3.6 0-3.8-0.6-4-0.3 0-0.5-0.3-0.5-0.4 0-0.3 7.7-6 8.5-6.3 0.4-0.1 8.8 5.7 9 6.3 0 0.1-0.2 0.3-0.5 0.4-0.5 0.2-0.6 0.4-0.6 3.8 0 3.5 0 3.7-0.7 4.7-0.7 1-0.7 1.3-0.7 5.1 0 4 0 4.1 0.6 4.2 0.6 0 0.7 0.2 0.8 2.4v2.3h-7.6-7.7z"/><path id="path270" fill="black" d=""/><path id="path268" fill="black" d=""/><path id="path266" fill="black" d=""/><path id="path264" fill="black" d=""/><path id="path260" fill="black" d=""/><g id="g585"><path id="path587" fill-rule="evenodd" fill="${colorFormatted}" d="m23.8 63.9c-0.4-0.2-1.2-1.3-1.9-2.3-1.6-2.8-3.5-5-8.9-10.8-5.5-5.8-7.6-8.6-9.6-12.6-2.1-4.4-2.6-6.9-2.6-12.5 0-5.4 0.4-7.4 2.4-11.5 3.1-6.7 8.8-11.5 16-13.4 3-0.7 8.4-0.8 11.4-0.1 12.6 3 20.6 14.8 19.1 28.3-0.9 7.9-3.7 13-12.3 22-4.5 4.7-7.4 8.1-8.9 10.7-1.4 2.5-2.9 3.1-4.7 2.2zm6.2-21.5c6.3-1.6 11.3-6.6 13.1-13 0.6-2.5 0.6-6.9 0-9.3-1.8-6.5-7-11.5-13.5-13.2-2.6-0.6-7.7-0.4-10.2 0.4-5.4 1.9-9.7 6.2-11.7 11.8-0.9 2.4-1.1 7.1-0.4 9.8 1.7 7.2 7.8 12.8 15.2 14 1.9 0.3 5.3 0.1 7.5-0.5z"/></g></g></svg>`
}
const getUnitProdMarker = color => {
    const colorFormatted = formatColor(color)
    return `<svg version="1.2" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 50 65" width="50" height="65"><title>map_unit_prod-svg</title><g id="g-3"><path id="ellipse-2" fill="white" d="m25.4 47.1c-11.6 0-20.9-9.6-20.9-21.5 0-11.9 9.3-21.5 20.9-21.5 11.5 0 20.8 9.6 20.8 21.5 0 11.9-9.3 21.5-20.8 21.5z"/><path id="path-7" fill="${colorFormatted}" d=""/><path id="path-8" fill="${colorFormatted}" d=""/><path id="path-9" fill="${colorFormatted}" d=""/><path id="path-10" fill="${colorFormatted}" d=""/><path id="path-11" fill="${colorFormatted}" d=""/><g id="g-4"><path id="path-12" fill-rule="evenodd" fill="${colorFormatted}" d="m23.8 64.5c-0.4-0.2-1.2-1.3-1.9-2.3-1.6-2.8-3.5-5-8.9-10.8-5.5-5.8-7.6-8.6-9.6-12.6-2.1-4.4-2.6-6.9-2.6-12.5 0-5.4 0.4-7.4 2.4-11.5 3.1-6.7 8.8-11.5 16-13.4 3-0.8 8.4-0.8 11.4-0.1 12.6 3 20.6 14.8 19.1 28.3-0.9 7.9-3.7 13-12.3 22-4.5 4.7-7.4 8.1-8.9 10.7-1.4 2.5-2.9 3.1-4.7 2.2zm6.2-21.5c6.3-1.6 11.3-6.6 13.1-13.1 0.6-2.4 0.6-6.8 0-9.2-1.8-6.5-7-11.5-13.5-13.2-2.6-0.6-7.7-0.4-10.2 0.4-5.4 1.9-9.7 6.2-11.7 11.8-0.9 2.4-1.1 7.1-0.4 9.7 1.7 7.3 7.8 12.9 15.2 14.1 1.9 0.3 5.3 0.1 7.5-0.5z"/></g><path id="path97" fill="${colorFormatted}" d="m10.4 28.6v-4.6l3.1-2.8 2.5-2.3v1.3l-0.1 3.4 1.5-2.2 1.6-2.4v1.1l0.1 3.3h1.9l1.5-0.1c0.7-3.3 1.3-6.6 1.8-10h2.4 2.4v9.1h1.9c0.2-3.2 0.4-6 0.5-9.2h2.4 2.7q0.4 4.3 0.7 8.6l1 0.9 0.9 1v5.7 5.8h-14.4-14.4v-5.6z"/></g></svg>`
}

const getArrowUp = color => {
    const colorFormatted = formatColor(color)
    return `<svg version="1.2" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 62 84" width="36" height="53"><title>arrow_up-svg</title><g id="layer1"><path id="path630" fill="${colorFormatted}" stroke="black" stroke-linejoin="round" d="M 31,0 0,84 31,66 62,84 Z"/></g></svg>`
}

const getArrowDown = color => {
    const colorFormatted = formatColor(color)
    return `<svg version="1.2" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 62 84" width="36" height="53"><title>map_arrow_down-svg</title><g id="layer1"><path id="path630" fill="${colorFormatted}" stroke="black" stroke-linejoin="round" d="M 31,84 0,0 31,18 62,0 Z"/></g></svg>`
}

const getArrowRight = color => { // yes it's a square
    const colorFormatted = formatColor(color)
    return `<svg version="1.2" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 50 50" width="35" height="35"><title>square-svg</title><g id="layer1"><rect id="path630" fill="${colorFormatted}" stroke="black" width="50" height="50" x="0" y="0" rx="10" ry="10"/></g></svg>`
}

export {
    getPiezoMarker,
    getCatchmentMarker,
    getHydroMarker,
    getPluvioMarker,
    getQualityMarker,
    getInstMarker,
    getArrowUp,
    getArrowDown,
    getArrowRight,
    getUnitProdMarker,
}
