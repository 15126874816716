import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { push } from 'connected-react-router'
import { connect } from 'react-redux'
import { Icon, Grid, Breadcrumbs } from '@mui/material'
import HomeAction from 'pages/home/actions/HomeAction'
import DtoUser from '../account/dto/DtoUser'
import DtoAccountStatistic from '../account/dto/DtoAccountStatistic'
import DtoCMSEvent from 'pages/cms/dto/DtoCMSEvent'
import CmsAction from '../cms/actions/CmsAction'
import { getLogin } from 'utils/LocalStorageUtils'
import { hasValue } from 'utils/NumberUtil'
import AccountAction from '../account/actions/AccountAction'
import { mainBlack } from 'components/styled/Theme'
import DtoApplicationSettings from 'pages/home/dto/DtoApplicationSettings'
import { mainGrey, mainWhite } from 'pages/online/components/styled/theme'

class NavBar extends Component {
    constructor(props) {
        super(props)
        this.state = {
            actuToShow: [],
        }
    }

    componentDidMount() {
        this.props.fetchApplicationSettings().then(() => {
            const { applicationSettings } = this.props
            const applicationNameOfSettings = applicationSettings.find(({ parameter }) => parameter === 'applicationName') || {}
            if (applicationNameOfSettings.value) {
                document.title = applicationNameOfSettings.value
            }
        })
        this.props.fetchSandreCodes()
        this.props.fetchCities()
        this.props.fetchContributors()
        this.props.fetchUserData(getLogin()).then(() => {
            const { accountUser } = this.props
            if (hasValue(accountUser.contributorCode)) {
                this.props.fetchContributorAccount(accountUser.contributorCode)
            }
            this.props.fetchCmsActualities()
        })
    }

    getBreadcrumbs = (color) => {
        return this.props.title.map((o) => {
            if (o && o.title) {
                const title = o.title.length > 100 ? `${o.title.substring(0, 100)}...` : o.title
                return (
                    <span
                        key={o.title}
                        style={{ display: 'inline-block', color, cursor: 'pointer', fontWeight: 'bold' }}
                        onClick={() => this.props.push(o.href)}
                    >
                        {title}
                    </span>
                )
            }
            return null
        })
    }

    backHome = () => {
        this.props.push('/home')
        window.scrollTo(0, 0)
    }

    render() {
        const { cmsTitle } = this.props

        const color = cmsTitle.id ? mainBlack : mainWhite

        return (
            <div>
                <Grid
                    container
                    direction='row'
                    justifyContent='flex-start'
                    alignItems='center'
                    style={ {
                        position: 'fixed',
                        top: cmsTitle.id ? '96px' : '66px',
                        height: '32px',
                        backgroundColor: cmsTitle.id ? mainGrey : mainBlack,
                        color,
                        fontWeight: 'bold',
                        fontSize: '1.3rem',
                        zIndex: '1000',
                        padding: '5px 10px',
                    } }
                >
                    <Grid item style={{ cursor: 'pointer' }} onClick={ this.backHome }>
                        <Icon>home</Icon>
                        <Icon sx={ { color } }>keyboard_arrow_right</Icon>
                    </Grid>
                    <Grid item>
                        <Breadcrumbs
                            separator={ <Icon sx={ { color } }>keyboard_arrow_right</Icon> }
                            aria-label='breadcrumb'
                            sx={ {
                                background: 'transparent',
                                boxShadow: 'none',
                                display: 'flex',
                            } }
                        >
                            { this.getBreadcrumbs(color) }
                        </Breadcrumbs>
                    </Grid>
                </Grid>
            </div>
        )
    }
}

NavBar.propTypes = {
    push: PropTypes.func,
    title: PropTypes.arrayOf(PropTypes.instanceOf(PropTypes.object)).isRequired,
    fetchApplicationSettings: PropTypes.func,
    accountUser: PropTypes.instanceOf(DtoUser),
    accountStatistics: PropTypes.arrayOf(PropTypes.instanceOf(DtoAccountStatistic)),
    actualities: PropTypes.arrayOf(PropTypes.instanceOf(DtoCMSEvent)),
    applicationSettings: PropTypes.arrayOf(PropTypes.instanceOf(DtoApplicationSettings)),
    fetchCmsActualities: PropTypes.func,
    fetchUserData: PropTypes.func,
    fetchCities: PropTypes.func,
    fetchSandreCodes: PropTypes.func,
    fetchContributors: PropTypes.func,
    fetchContributorAccount: PropTypes.func,
    cmsTitle: PropTypes.instanceOf(DtoCMSEvent),
}

const mapStateToProps = (store) => ({
    title: store.HomeReducer.title,
    accountUser: store.AccountReducer.accountUser,
    accountStatistics: store.AccountReducer.accountStatistics,
    actualities: store.CmsReducer.actualities,
    applicationSettings: store.HomeReducer.applicationSettings,
    cmsTitle: store.CmsReducer.cmsTitle,
})

const mapDispatchToProps = {
    fetchApplicationSettings: HomeAction.fetchApplicationSettings,
    fetchCities: HomeAction.fetchCities,
    fetchSandreCodes: HomeAction.fetchSandreCodes,
    fetchContributors: HomeAction.fetchContributors,
    fetchCmsActualities: CmsAction.fetchCmsActualities,
    fetchUserData: AccountAction.fetchUserData,
    fetchContributorAccount: AccountAction.fetchContributorAccount,
    push,
}

export default connect(mapStateToProps, mapDispatchToProps)(NavBar)
