export default class DtoAssociatedStation {
    constructor(obj) {
        // station sur laquelle on est
        this.code = obj[0]
        this.stationType = obj[1]
        this.id = obj[2]
        this.name = obj[3]

        // station liée
        this.stationLinkedCode = obj[4]
        this.stationLinkedType = obj[5]
        this.stationLinkedId = obj[6]
        this.stationLinkedName = obj[7]
        this.typeName = obj[8] // type de la station liée

        this.linkType = obj[9]
        this.countType = obj[10]
    }
}