export default class DtoLandmark {
    constructor(object) {
        this.idStation = object.idStation
        this.startDate = object.startDate
        this.endDate = object.endDate
        this.height = object.height
        this.origin = object.origin
        this.comment = object.comment
        this.altimetrySystemNature = object.altimetrySystemNature
        this.altimetrySystemDate = object.altimetrySystemDate
    }
}
