export default class DtoHydrogeologicalEntityLink {
    constructor(obj) {
        this.idStation = obj.idStation
        this.hydrologicalEntity = obj.hydrologicalEntity
        this.startDate = obj.startDate
        this.endDate = obj.endDate
        this.qualityAssociation = obj.qualityAssociation
        this.contributor = obj.contributor
        this.comment = obj.comment
    }
}
