/* eslint-disable quotes */
import { push } from 'connected-react-router'
import i18n from 'simple-react-i18n'
import { SIEAU_TOKEN, CREDENTIALS, TOKEN_MOBILE_LOGIN, MODULE } from '../pages/home/constants/HomeConstants'
import AppStore from '../store/AppStore'
import HomeAction from '../pages/home/actions/HomeAction'
import ToastrAction from 'toastr/actions/ToastrAction'
import LogAction from 'log/actions/LogAction'
import WaitAction from 'wait/WaitAction'
import { createAsyncThunk } from '@reduxjs/toolkit'
import { removeNullKeys } from './StoreUtils'

const codes = {
    200: (res) => res,
    201: (res) => res,
    403: () => {
        AppStore.dispatch({ type: 'RESET_ALL_STORE' })
        AppStore.dispatch(push('/login'))
        localStorage.removeItem(SIEAU_TOKEN)
        AppStore.dispatch(HomeAction.logout())
        throw new Error('Not Authorized')
    },
    404: () => {
        throw new Error('404 Not Found')
    },
    409: () => {
        throw new Error('409 Conflict')
    },
    429: () => {
        AppStore.dispatch(ToastrAction.error(i18n.tooManyLoginRequests))
        throw new Error('429 Too Many Requests')
    },
    500: () => {
        throw new Error('500 error')
    },
}

const checkError = (json, errorCodeManagement = {}) => {
    if (json.error && errorCodeManagement[json.error]) {
        errorCodeManagement[json.error]()
    } else if (json.error) {
        AppStore.dispatch(WaitAction.waitStop())
        throw new Error(json.error)
    }
    return json
}

const resetCredentials = () => {
    localStorage.removeItem(SIEAU_TOKEN)
    localStorage.removeItem(CREDENTIALS)
    AppStore.dispatch(push('/login'))
}

const getLoginPassword = () => {
    const credentials = localStorage.getItem(CREDENTIALS)
    return credentials ? atob(credentials).split(':') : null
}

const getJson = response => response.json()

const checkStatus = (obj, response) => {
    const code = Object.keys(obj).find((element) => `${response.status}` === `${element}`)
    if (!code) {
        throw new Error(`Unhandled Error during fetch${response.status}`)
    }
    return obj[code](response)
}

const checkAuth = (response, overrideStatus = {}) => {
    return checkStatus({
        ...codes,
        ...overrideStatus,
    }, response)
}

const catchError = (err, message = '') => {
    AppStore.dispatch(WaitAction.waitStop())
    AppStore.dispatch(LogAction.logError(`${i18n.fetchError + message} : ${err}`))
    AppStore.dispatch(ToastrAction.error(i18n.fetchError + message))
}

const checkAuthV2 = (response, overrideStatus = {}) => {
    return checkStatus({
        ...codes,
        ...overrideStatus,
    }, response)
}

const getAuthorization = () => ({
    Authorization: `Bearer ${localStorage.getItem(SIEAU_TOKEN)}`,
    Module: MODULE,
    Lang: navigator.language || navigator.userLanguage,
})

const getAuthorizationLogin = () => ({
    Authorization: TOKEN_MOBILE_LOGIN,
    Module: MODULE,
    Lang: navigator.language || navigator.userLanguage,
})

const removeToken = () => {
    localStorage.removeItem(SIEAU_TOKEN)
}

const getPayload = () => {
    const token = localStorage.getItem(SIEAU_TOKEN)
    if (token) {
        return atob(token.split('.')[1])
    }
    return ''
}

const genericPromise = (url, method='GET', body=null, overrideStatus = {}, signal) => {
    return fetch(url, removeNullKeys({
        method,
        headers: getAuthorization(),
        body: body ? JSON.stringify(body) : null,
        signal,
    }))
        .then(r => checkAuth(r, overrideStatus))
        .then(getJson)
        .then(checkError)
}

const genericPromise2 = (url, options = {}, overrideStatus = {}) => {
    return fetch(url, {
        method: options.method ?? 'GET',
        body: options.body ? JSON.stringify(options.body) : undefined,
        headers: getAuthorization(),
        signal: options.signal,
    })
        .then(r => checkAuth(r, overrideStatus))
        .then(getJson)
        .then(checkError)
}

const genericPromiseHome = (url, method='GET', body=null, overrideStatus = {}) => {
    return fetch(url, removeNullKeys({
        method,
        headers: getAuthorizationLogin(),
        body: body ? JSON.stringify(body) : null,
    }))
        .then(r => checkAuth(r, overrideStatus))
        .then(getJson)
        .then(checkError)
}

const genericFetch = (callName, promise = async() => {}, errorName = '', cb = () => {}) => createAsyncThunk(
    callName,
    async(_, { rejectWithValue, dispatch }) => {
        try {
            const response = await promise()
            cb()
            return response
        } catch (err) {
            dispatch(LogAction.logError(`${i18n.fetchError} ${errorName}: ${err}`))
            dispatch(ToastrAction.error(i18n.fetchError))
            return rejectWithValue(err)
        }
    }
)

const promiseAllProgress = (promises, callback) => {
    let d = 0
    callback(0)
    promises.forEach((p) => {
        p.then(()=> {
            d++
            callback((d * 100) / promises.length)
        })
    })
    return Promise.all(promises)
}

const promiseAllProgressSeq = async (urlsDatas = [], progressCallback = () => {}) => {
    const results = []
    let d = 0
    progressCallback(0)
    return AppStore.dispatch(HomeAction.setLoadingData(true)).then(async () => {
        for (const u of urlsDatas) {
            if (AppStore.getState().HomeReducer.loadingData) {
                const res = await genericPromise(u.url, u.method, u.body, u.overrideStatus, u.signal)
                d++
                progressCallback((d * 100) / urlsDatas.length)
                if (u.setResult) {
                    u.setResult(results, res)
                } else {
                    results.push(res)
                }
            }
        }
        return results
    })
}

const formatData = (body) => {
    const header = {}
    const headers = body[0].customHeaders ? body[0].customHeaders : body[0].headers
    body[0].headers.map((e, i) => {
        header[e] = {
            value: (() => {
                try {
                    return i18n[headers[i]]
                } catch (err) {
                    return headers[i]
                }
            })(),
        }
    })

    const data = [header].concat(body).map(line => {
        return body[0].headers.map(key => {
            if (line[key] || line[key] === 0) {
                if (line[key].value || line[key].value === 0) {
                    return {
                        ...line[key],
                        value: typeof line[key].value === 'boolean' && line[key].value ? i18n.yes : line[key].value.toString(),
                    }
                }
                return { value: typeof line[key] === 'boolean' && line[key] ? i18n.yes : line[key].toString() }
            }
            return { value: '' }
        })
    })
    return body[0].tableTitle ? [[{ value: body[0].tableTitle }], ...data] : data
}

export {
    checkAuth,
    checkStatus,
    checkError,
    getAuthorization,
    getPayload,
    catchError,
    getLoginPassword,
    resetCredentials,
    getJson,
    getAuthorizationLogin,
    removeToken,
    checkAuthV2,
    genericPromise,
    genericPromiseHome,
    genericFetch,
    promiseAllProgress,
    genericPromise2,
    formatData,
    promiseAllProgressSeq,
}
