import React, { useEffect } from 'react'
import PropTypes from 'prop-types'
import CmsAction from 'pages/cms/actions/CmsAction'
import { shallowEqual, useDispatch, useSelector } from 'react-redux'
import i18n from 'simple-react-i18n'
import { Grid, Icon } from '@mui/material'
import useStateProgress from 'utils/customHook/useStateProgress'
import ProgressBar from 'components/progress/ProgressBar'
import aquasysLogo from 'ressources/pictures/logos/AquasysPetitLogo.png'
import { cmsPath } from 'conf/basepath'
import { mainBlack, mainWhite } from 'pages/online/components/styled/theme'
import useApplicationSetting from 'utils/customHook/useApplicationSetting'
import { aquasysPath } from 'conf/SieauConstants'
import { lightGrey } from 'utils/constants/ColorTheme'


const SupportCard = ({
    width = '100%',
    height,
}) => {
    const {
        cmsEvents,
        cmsTitle,

        cmsEventsStatus,
        applicationSettingsStatus,
    } = useSelector(store => ({
        cmsEvents: store.CmsReducer.cmsEvents,
        cmsTitle: store.CmsReducer.cmsTitle,

        cmsEventsStatus: store.DataManagerReducer.cms.cmsEventsStatus,
        applicationSettingsStatus: store.DataManagerReducer.home.applicationSettingsStatus,
    }), shallowEqual)

    const categoryId = useApplicationSetting('categorieCmsPartenaires', setting => setting ? parseInt(setting) : undefined)
    const mail = useApplicationSetting('supportMailAddress', setting => setting)
    const phoneNumber = useApplicationSetting('supportPhoneNumber', setting => setting)
    const schedule = useApplicationSetting('supportContactSchedule', setting => setting)
    const cmsSupportId = useApplicationSetting('cmsSupport', setting => setting ? parseInt(setting) : undefined)

    const { progress, isLoaded } = useStateProgress([
        cmsEventsStatus,
        applicationSettingsStatus,
    ])

    const dispatch = useDispatch()

    useEffect(() => {
        if (!cmsEvents.length) {
            dispatch(CmsAction.fetchCMSEvents())
        }
    }, [])

    const cmsSupport = cmsEvents.find(c => c.id === cmsSupportId)?.comment || ''
    const images = categoryId ? cmsEvents.filter(c => c.idCategory === categoryId && c?.document[0]).map(c => ({
        link: c.link,
        docName: c.document[0].name,
        name: c.title,
    })) : []

    const openWebSite = link => !!link && window.open(link, '_blank')

    const panelHeight = height || `calc(100vh - ${cmsTitle.id ? '266px' : '236px'})`

    return (
        <>
            <div style={{ width, backgroundColor: mainWhite, height: panelHeight, borderRadius: '5px', boxShadow: '0px 0px 26px 0px rgb(0 0 0 / 12%)' }}>
                <Grid container sx={{ height: '100%', maxHeight: '100%', overflowY: 'auto' }}>
                    <Grid
                        item xs={12}
                        sx={{
                            fontSize: '20px',
                            backgroundColor: mainBlack,
                            color: mainWhite,
                            fontWeight: '600',
                            padding: '0.5rem 1rem',
                            borderRadius: '5px 5px 0 0',
                            height: '40px',
                        }}
                    >
                        <span>{i18n.support}</span>
                    </Grid>
                    {isLoaded ? (
                        <Grid
                            container item xs={12}
                            direction='column'
                            justifyContent='space-between'
                            alignItems='stretch'
                            sx={{ height: 'calc(100% - 40px)' }}
                        >
                            <Grid container item alignItems='center' sx={{ padding: '15px' }}>
                                <Grid container item xs={12}>
                                    <Grid item xs={12} sx={{ '& p': { margin: 0 }, '& span': { fontSize: '16px !important' } }}>
                                        <div dangerouslySetInnerHTML={{ __html: cmsSupport }} />
                                    </Grid>
                                    <Grid container item xs={12} alignItems='center' sx={{ marginTop: '1rem' }}>
                                        <Grid item>
                                            <Icon>mail</Icon>
                                        </Grid>
                                        <Grid item sx={{ paddingLeft: '0.75rem' }}>
                                            <span>{mail}</span>
                                        </Grid>
                                    </Grid>
                                    <Grid container item xs={12} alignItems='center' sx={{ marginTop: '0.5rem' }}>
                                        <Grid item>
                                            <Icon>phone</Icon>
                                        </Grid>
                                        <Grid item sx={{ paddingLeft: '0.75rem' }}>
                                            <span>{phoneNumber}</span>
                                        </Grid>
                                    </Grid>
                                    <Grid container item xs={12} alignItems='center' sx={{ marginTop: '0.5rem' }}>
                                        <Grid item>
                                            <Icon>schedule</Icon>
                                        </Grid>
                                        <Grid item sx={{ paddingLeft: '0.75rem' }}>
                                            <span>{schedule}</span>
                                        </Grid>
                                    </Grid>
                                </Grid>
                            </Grid>
                            <Grid container item justifyContent='space-around' alignItems='center' sx={{ padding: '0.5rem 0', maxHeight: { md: '35vh', xs: '20vh' }, borderTop: `1px solid ${lightGrey}`, overflowY: 'auto' }}>
                                <Grid
                                    container item
                                    justifyContent='center'
                                    alignItems='center'
                                >
                                    {[(
                                        <Grid item>
                                            <img
                                                src={aquasysLogo}
                                                alt='logo aquasys'
                                                style={{
                                                    cursor: 'pointer',
                                                    height: '4.5rem',
                                                    width: 'auto',
                                                    padding: '1rem 0.5rem',
                                                }}
                                                onClick={() => openWebSite(aquasysPath)}
                                            />
                                        </Grid>
                                    ), ...images.map((i, index) => {
                                        return (
                                            <Grid item key={index}>
                                                <img
                                                    src={`${cmsPath}${i.docName}`}
                                                    alt={`logo partenaire ${i.name}`}
                                                    onClick={() => openWebSite(i.link)}
                                                    style={{
                                                        cursor: 'pointer',
                                                        height: '4.5rem',
                                                        width: 'auto',
                                                        padding: '1rem 0.5rem',
                                                    }}
                                                />
                                            </Grid>
                                        )
                                    })]}
                                </Grid>
                            </Grid>
                        </Grid>
                    ) : (
                        <ProgressBar progress={progress} style={{ paddingTop: 0 }} />
                    )}
                </Grid>
            </div>
        </>
    )
}

SupportCard.propTypes = {
    width: PropTypes.string,
    height: PropTypes.string,
}

export default SupportCard