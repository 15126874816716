import React from 'react'
import {
    BLUE,
    WHITE,
    YELLOW,
    GREEN,
    RED,
    ORANGE,
    GREY,
    PURPLE,
} from '../components/constants/ColorConstant'
import { mainBlack, mainWhite } from 'pages/online/components/styled/theme'
import { isNaN, isNil } from 'lodash'

const colors = [
    {
        constant: BLUE,
        color: 'blue',
    }, {
        constant: WHITE,
        color: 'white',
    }, {
        constant: YELLOW,
        color: 'yellow',
    }, {
        constant: GREEN,
        color: 'green',
    }, {
        constant: RED,
        color: 'red',
    }, {
        constant: ORANGE,
        color: 'orange',
    }, {
        constant: GREY,
        color: 'grey',
    }, {
        constant: PURPLE,
        color: 'purple',
    },
]

const colorReferences = [
    { color: 'aliceblue', hex: '#f0f8ff' },
    { color: 'antiquewhite', hex: '#faebd7' },
    { color: 'aqua', hex: '#00ffff' },
    { color: 'aquamarine', hex: '#7fffd4' },
    { color: 'azure', hex: '#f0ffff' },
    { color: 'beige', hex: '#f5f5dc' },
    { color: 'bisque', hex: '#ffe4c4' },
    { color: 'black', hex: '#000000' },
    { color: 'blanchedalmond', hex: '#ffebcd' },
    { color: 'blue', hex: '#0000ff' },
    { color: 'blueviolet', hex: '#8a2be2' },
    { color: 'brown', hex: '#a52a2a' },
    { color: 'burlywood', hex: '#deb887' },
    { color: 'cadetblue', hex: '#5f9ea0' },
    { color: 'chartreuse', hex: '#7fff00' },
    { color: 'chocolate', hex: '#d2691e' },
    { color: 'coral', hex: '#ff7f50' },
    { color: 'cornflowerblue', hex: '#6495ed' },
    { color: 'cornsilk', hex: '#fff8dc' },
    { color: 'crimson', hex: '#dc143c' },
    { color: 'cyan', hex: '#00ffff' },
    { color: 'darkblue', hex: '#00008b' },
    { color: 'darkcyan', hex: '#008b8b' },
    { color: 'darkgoldenrod', hex: '#b8860b' },
    { color: 'darkgray', hex: '#a9a9a9' },
    { color: 'darkgreen', hex: '#006400' },
    { color: 'darkgrey', hex: '#a9a9a9' },
    { color: 'darkkhaki', hex: '#bdb76b' },
    { color: 'darkmagenta', hex: '#8b008b' },
    { color: 'darkolivegreen', hex: '#556b2f' },
    { color: 'darkorange', hex: '#ff8c00' },
    { color: 'darkorchid', hex: '#9932cc' },
    { color: 'darkred', hex: '#8b0000' },
    { color: 'darksalmon', hex: '#e9967a' },
    { color: 'darkseagreen', hex: '#8fbc8f' },
    { color: 'darkslateblue', hex: '#483d8b' },
    { color: 'darkslategrey', hex: '#2f4f4f' },
    { color: 'darkturquoise', hex: '#00ced1' },
    { color: 'darkviolet', hex: '#9400d3' },
    { color: 'deeppink', hex: '#ff1493' },
    { color: 'deepskyblue', hex: '#00bfff' },
    { color: 'dimgray', hex: '#696969' },
    { color: 'dimgrey', hex: '#696969' },
    { color: 'dodgerblue', hex: '#1e90ff' },
    { color: 'firebrick', hex: '#b22222' },
    { color: 'floralwhite', hex: '#fffaf0' },
    { color: 'forestgreen', hex: '#228b22' },
    { color: 'fuchsia', hex: '#ff00ff' },
    { color: 'gainsboro', hex: '#dcdcdc' },
    { color: 'ghostwhite', hex: '#f8f8ff' },
    { color: 'gold', hex: '#ffd700' },
    { color: 'goldenrod', hex: '#daa520' },
    { color: 'gray', hex: '#808080' },
    { color: 'green', hex: '#008000' },
    { color: 'greenyellow', hex: '#adff2f' },
    { color: 'grey', hex: '#808080' },
    { color: 'honeydew', hex: '#f0fff0' },
    { color: 'hotpink', hex: '#ff69b4' },
    { color: 'indianred', hex: '#cd5c5c' },
    { color: 'indigo', hex: '#4b0082' },
    { color: 'ivory', hex: '#fffff0' },
    { color: 'khaki', hex: '#f0e68c' },
    { color: 'lavender', hex: '#e6e6fa' },
    { color: 'lavenderblush', hex: '#fff0f5' },
    { color: 'lawngreen', hex: '#7cfc00' },
    { color: 'lemonchiffon', hex: '#fffacd' },
    { color: 'lightblue', hex: '#add8e6' },
    { color: 'lightcoral', hex: '#f08080' },
    { color: 'lightcyan', hex: '#e0ffff' },
    { color: 'lightgoldenrodyellow', hex: '#fafad2' },
    { color: 'lightgray', hex: '#d3d3d3' },
    { color: 'lightgreen', hex: '#90ee90' },
    { color: 'lightgrey', hex: '#d3d3d3' },
    { color: 'lightpink', hex: '#ffb6c1' },
    { color: 'lightsalmon', hex: '#ffa07a' },
    { color: 'lightseagreen', hex: '#20b2aa' },
    { color: 'lightskyblue', hex: '#87cefa' },
    { color: 'lightslategrey', hex: '#778899' },
    { color: 'lightsteelblue', hex: '#b0c4de' },
    { color: 'lightyellow', hex: '#ffffe0' },
    { color: 'lime', hex: '#00ff00' },
    { color: 'limegreen', hex: '#32cd32' },
    { color: 'linen', hex: '#faf0e6' },
    { color: 'magenta', hex: '#ff00ff' },
    { color: 'maroon', hex: '#800000' },
    { color: 'mediumaquamarine', hex: '#66cdaa' },
    { color: 'mediumblue', hex: '#0000cd' },
    { color: 'mediumorchid', hex: '#ba55d3' },
    { color: 'mediumpurple', hex: '#9370db' },
    { color: 'mediumseagreen', hex: '#3cb371' },
    { color: 'mediumslateblue', hex: '#7b68ee' },
    { color: 'mediumspringgreen', hex: '#00fa9a' },
    { color: 'mediumturquoise', hex: '#48d1cc' },
    { color: 'mediumvioletred', hex: '#c71585' },
    { color: 'midnightblue', hex: '#191970' },
    { color: 'mintcream', hex: '#f5fffa' },
    { color: 'mistyrose', hex: '#ffe4e1' },
    { color: 'moccasin', hex: '#ffe4b5' },
    { color: 'navajowhite', hex: '#ffdead' },
    { color: 'navy', hex: '#000080' },
    { color: 'oldlace', hex: '#fdf5e6' },
    { color: 'olive', hex: '#808000' },
    { color: 'olivedrab', hex: '#6b8e23' },
    { color: 'orange', hex: '#ffa500' },
    { color: 'orangered', hex: '#ff4500' },
    { color: 'orchid', hex: '#da70d6' },
    { color: 'palegoldenrod', hex: '#eee8aa' },
    { color: 'palegreen', hex: '#98fb98' },
    { color: 'paleturquoise', hex: '#afeeee' },
    { color: 'palevioletred', hex: '#db7093' },
    { color: 'papayawhip', hex: '#ffefd5' },
    { color: 'peachpuff', hex: '#ffdab9' },
    { color: 'peru', hex: '#cd853f' },
    { color: 'pink', hex: '#ffc0cb' },
    { color: 'plum', hex: '#dda0dd' },
    { color: 'powderblue', hex: '#b0e0e6' },
    { color: 'purple', hex: '#800080' },
    { color: 'rebeccapurple', hex: '#663399' },
    { color: 'red', hex: '#ff0000' },
    { color: 'rosybrown', hex: '#bc8f8f' },
    { color: 'royalblue', hex: '#4169e1' },
    { color: 'saddlebrown', hex: '#8b4513' },
    { color: 'salmon', hex: '#fa8072' },
    { color: 'sandybrown', hex: '#f4a460' },
    { color: 'seagreen', hex: '#2e8b57' },
    { color: 'seashell', hex: '#fff5ee' },
    { color: 'sienna', hex: '#a0522d' },
    { color: 'silver', hex: '#c0c0c0' },
    { color: 'skyblue', hex: '#87ceeb' },
    { color: 'slateblue', hex: '#6a5acd' },
    { color: 'slategray', hex: '#708090' },
    { color: 'snow', hex: '#fffafa' },
    { color: 'springgreen', hex: '#00ff7f' },
    { color: 'steelblue', hex: '#4682b4' },
    { color: 'tan', hex: '#d2b48c' },
    { color: 'teal', hex: '#008080' },
    { color: 'thistle', hex: '#d8bfd8' },
    { color: 'tomato', hex: '#ff6347' },
    { color: 'turquoise', hex: '#40e0d0' },
    { color: 'violet', hex: '#ee82ee' },
    { color: 'wheat', hex: '#f5deb3' },
    { color: 'white', hex: '#ffffff' },
    { color: 'whitesmoke', hex: '#f5f5f5' },
    { color: 'yellow', hex: '#ffff00' },
    { color: 'yellowgreen', hex: '#9acd32' },
]

const getColor = (constant) => {
    const color = colors.find((c) => c.constant == constant)
    return color ? color.color : 'white'
}

const getRGBColor = (constant) => {
    const color = colors.find((c) => c.constant == constant)
    return color ? color.rgb : 'white'
}

/* When bool=true => res=GREEN | bool=false => res=RED | bool=undefined => res=GREY  */
const getBooleanColor = (bool) => {
    if (!bool) {
        return (bool===false) ? RED : GREY
    }
    return GREEN
}

const getNewsColor = (newsLevel) => {
    switch (newsLevel) {
        case 1:
            return 'BLUE'
        case 2:
            return 'GREEN'
        case 3:
            return 'ORANGE'
        case 4:
            return 'RED'
        case -1: default:
            return null
    }
}

const getThresholdColors = () => [
    'black',
    'blue',
    'green',
    'cyan',
    'red',
    'darkmagenta',
    'yellow',
    'white',
    'grey',
    'lightblue',
    'lightgreen',
    'lightcyan',
    'indianred',
    'magenta',
    'lightyellow',
    'whitesmoke',
]

const getThresholdColor = color => {
    const clrs = getThresholdColors()
    return color < clrs.length ? clrs[color] : clrs[0]
}

const getEventColor = eventType => {
    switch (eventType) {
        case 'M':
            return 'RED'
        case 'G':
            return 'BLUE'
        case 'P':
            return 'YELLOW'
        case 'S':
            return 'ORANGE'
        case 'C':
            return 'DARKGREEN'
        case 'T':
            return 'BROWN'
        default:
            return 'GREY'
    }
}

const getColorFromPalette = (index) => {
    return ['#0000ff', '#c23531', '#2f4554', '#61a0a8', '#d48265', '#66c722', '#749f83', '#ca8622', '#bda29a', '#6e7074', '#f775d2'][index % 11]
}


const getColorFromPalette2 = (index) => {
    return ['#2799fa', '#191954', '#127520', '#a93dd4', '#c75c22', '#9f1f1f', '#ca8622', '#26aabd', '#6e7074', '#f775d2', '#66c457'][index % 11]
}

const getColorCircleElement = (rgbColor, JSX, obj) => {
    if (JSX) {
        return <span style={ { display: 'inline-block', 'margin-right': '5px', 'border-radius': '10px', width: '9px', height: '9px', 'background-color': rgbColor } } {...obj}/>
    }
    return `<span style="display:inline-block;margin-right:5px;border-radius:10px;width:9px;height:9px;background-color:${rgbColor};"></span>`
}

const defaultContrast = 255

const getContrast = color => {
    const hexColor = color.startsWith('#') ? color.slice(1) : colorReferences.find(c => c.color === color)?.hex.slice(1)

    if (isNil(hexColor) || isNaN(hexColor)) {
        return defaultContrast
    }

    const formattedHexColor = hexColor.length === 3 ? hexColor.split('').map(hex => hex + hex).join('') : hexColor

    const r = parseInt(formattedHexColor.substr(0, 2), 16)
    const g = parseInt(formattedHexColor.substr(2, 2), 16)
    const b = parseInt(formattedHexColor.substr(4, 2), 16)

    return ((r * 299) + (g * 587) + (b * 114)) / 1000
}

const getTextColorByBackground = (color) => {
    const yiq = getContrast(color)

    return (yiq > 128) ? mainBlack : mainWhite
}

export { getColor, getBooleanColor, getNewsColor, getThresholdColor, getThresholdColors, getRGBColor, getEventColor, getColorFromPalette2, getColorCircleElement, getColorFromPalette, getTextColorByBackground }
