import DtoCMSEvent from 'pages/cms/dto/DtoCMSEvent'
import { createSlice } from '@reduxjs/toolkit'
import CmsAction from '../actions/CmsAction'
import DtoCMSCategory from '../dto/DtoCMSCategory'
import DtoCMSMessage from '../dto/DtoCMSMessage'

export const initialState = {
    cmsEvents: [],
    actualities: [],
    cmsCategories: [],
    cmsMessages: [],
    cmsTitle: {},
}

const store = createSlice({
    name: 'Cms',
    initialState,
    reducers: {
        reset: (state) => {
            state.cmsEvents = []
            state.actualities = []
            state.cmsCategories = []
            state.cmsMessages = []
            state.cmsTitle = {}
        },
        resetCmsMessages: (state) => {
            state.cmsMessages = []
        },
    },
    extraReducers: {
        [CmsAction.fetchCMSEvents.fulfilled]: (state, action) => {
            state.cmsEvents = action.payload.map((n) => new DtoCMSEvent(n))
        },
        [CmsAction.fetchCmsActualities.fulfilled]: (state, action) => {
            state.actualities = action.payload.map((n) => new DtoCMSEvent(n))
        },
        [CmsAction.fetchCMSCategories.fulfilled]: (state, action) => {
            state.cmsCategories = action.payload.map((n) => new DtoCMSCategory(n))
        },
        [CmsAction.fetchCmsMessages.fulfilled]: (state, action) => {
            state.cmsMessages = action.payload.map((n) => new DtoCMSMessage(n))
        },
        [CmsAction.fetchCmsTitle.fulfilled]: (state, action) => {
            state.cmsTitle = new DtoCMSEvent(action.payload)
        },
    },
})

export const CmsActionConstant = store.actions
export default store.reducer