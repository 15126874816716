import { useMemo } from 'react'
import { shallowEqual, useSelector } from 'react-redux'

const useAccountSetting = (field, parser = setting => setting) => {
    const {
        accountUserSettings,
    } = useSelector(store => ({
        accountUserSettings: store.AccountReducer.accountUserSettings,
    }), shallowEqual)

    const res = useMemo(() => {
        const setting = accountUserSettings?.find(s => s.parameter === field)
        return parser(setting?.value)
    }, [accountUserSettings, field])
    return res
}

export default useAccountSetting