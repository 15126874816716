import { createSlice } from '@reduxjs/toolkit'
import DtoCMSEvent from 'pages/cms/dto/DtoCMSEvent'
import DtoIntervenant from 'pages/contact/dto/DtoIntervenant'
import HomeAction from '../actions/HomeAction'
import DtoApplicationSettings from '../dto/DtoApplicationSettings'
import DtoPiezometerLight from '../dto/DtoPiezometerLight'
import DtoHydrometricStation from '../dto/DtoHydrometricStation'
import PluviometerDto from '../dto/PluviometerDto'
import DtoQualitometerLight from '../dto/DtoQualitometerLight'
import DtoProductionUnit from '../dto/DtoProductionUnit'
import DtoCity from 'pages/online/referencials/city/dto/DtoCity'
import { createIndex } from 'utils/StoreUtils'
import LayerDto from '../dto/LayerDto'
import CartographyThemeDto from '../dto/CartographyThemeDto'
import DtoAssociatedStation from '../dto/DtoAssociatedStation'
import DtoSandreCode from 'pages/online/referencials/dto/DtoSandreCode'
import DtoPiezometryStationMeasure from 'pages/online/referencials/dto/DtoPiezometryStationMeasure'
import DtoInstallationLight from '../dto/DtoInstallationLight'
import DtoContributorLink from '../dto/DtoContributorLink'
import DtoPiezometer from '../dto/DtoPiezometer'
import DtoQualitometer from '../dto/DtoQualitometer'
import DtoDataType from 'pages/online/referencials/dto/DtoDataType'
import DtoContributorGroup from 'pages/contact/dto/DtoContributorGroup'

export const initialState = {
    title: [],
    dateValidCgu: '',
    cms: [],
    cmsCGU: [],
    applicationSettings: [],
    reload: false,
    cgu: {},
    contributors: [],
    contributorsGroups: [],
    piezometersLight: [],
    piezometer: {},
    hydrometers: [],
    hydrologicalStation: {},
    pluviometers: [],
    pluviometer: {},
    qualitometersLight: [],
    qualitometer: {},
    productionUnits: [],
    productionUnit: {},
    cities: [],
    citiesIndex: {},
    mapLayers: [],
    cartographyThemes: [],
    linkedStations: [],
    linkedStationsEvents: [],
    sandreCodes: [],
    piezoMeasures: [],
    installationsLight: [],
    contributorsLinks: [],
    piezoDataTypes: [],
    hydroDataTypes: [],
    pluvioDataTypes: [],
    loadingData: false,
}

const store = createSlice({
    name: 'Home',
    initialState,
    reducers: {
        reset: (state) => {
            state.title = []
            state.dateValidCgu = ''
            state.cms = []
            state.cmsCGU = []
            state.applicationSettings = []
            state.reload = false
            state.cgu = {}
            state.contributors = []
            state.contributorsGroups = []
            state.piezometersLight = []
            state.piezometer = {}
            state.hydrometers = []
            state.hydrologicalStation = {}
            state.pluviometers = []
            state.pluviometer = {}
            state.qualitometersLight = []
            state.qualitometer = {}
            state.productionUnits = []
            state.productionUnit = {}
            state.cities = []
            state.citiesIndex = {}
            state.mapLayers = []
            state.cartographyThemes = []
            state.linkedStations = []
            state.linkedStationsEvents = []
            state.sandreCodes = []
            state.piezoMeasures = []
            state.installationsLight = []
            state.contributorsLinks = []
            state.piezoDataTypes = []
            state.hydroDataTypes = []
            state.pluvioDataTypes = []
        },
        resetFollow: (state) => {
            state.piezometer = {}
            state.hydrologicalStation = {}
            state.pluviometer = {}
            state.qualitometer = {}
            state.productionUnit = {}
        },
        resetLinkedStations: (state) => {
            state.linkedStations = []
            state.linkedStationsEvents = []
        },
    },
    extraReducers: {
        [HomeAction.setTitle.fulfilled]: (state, action) => {
            state.title = action.payload
        },
        [HomeAction.getDateValidCGU.fulfilled]: (state, action) => {
            state.dateValidCgu = action.payload
        },
        [HomeAction.getCmsPartenaire.fulfilled]: (state, action) => {
            state.cms = action.payload
        },
        [HomeAction.getCGUFromCms.fulfilled]: (state, action) => {
            state.cmsCGU = action.payload.map((c) => new DtoCMSEvent(c))
        },
        [HomeAction.fetchContributors.fulfilled]: (state, action) => {
            state.contributors = action.payload.map(c => new DtoIntervenant(c))
        },
        [HomeAction.fetchContributorsGroups.fulfilled]: (state, action) => {
            state.contributorsGroups = action.payload.map(c => new DtoContributorGroup(c))
        },
        [HomeAction.setWindowReload.fulfilled]: (state, action) => {
            state.reload = action.payload
        },
        [HomeAction.getAllCGU.fulfilled]: (state, action) => {
            state.cgu = action.payload
        },
        [HomeAction.fetchApplicationSettings.fulfilled]: (state, action) => {
            state.applicationSettings = action.payload.map((s) => new DtoApplicationSettings(s))
        },
        [HomeAction.fetchPiezometersLight.fulfilled]: (state, action) => {
            state.piezometersLight = action.payload.map((s) => new DtoPiezometerLight(s))
        },
        [HomeAction.fetchPiezometer.fulfilled]: (state, action) => {
            state.piezometer = new DtoPiezometer(action.payload)
        },
        [HomeAction.fetchHydrometers.fulfilled]: (state, action) => {
            state.hydrometers = action.payload.map((s) => new DtoHydrometricStation(s))
        },
        [HomeAction.fetchHydrologicalStation.fulfilled]: (state, action) => {
            state.hydrologicalStation = new DtoHydrometricStation(action.payload)
        },
        [HomeAction.fetchPluviometers.fulfilled]: (state, action) => {
            state.pluviometers = action.payload.map((s) => new PluviometerDto(s))
        },
        [HomeAction.fetchPluviometer.fulfilled]: (state, action) => {
            state.pluviometer = new PluviometerDto(action.payload)
        },
        [HomeAction.fetchQualitometersLight.fulfilled]: (state, action) => {
            state.qualitometersLight = action.payload.map((s) => new DtoQualitometerLight(s))
        },
        [HomeAction.fetchInstallationsLight.fulfilled]: (state, action) => {
            state.installationsLight = action.payload.map((s) => new DtoInstallationLight(s))
        },
        [HomeAction.fetchAllContributorsLink.fulfilled]: (state, action) => {
            state.contributorsLinks = action.payload.map((s) => new DtoContributorLink(s))
        },
        [HomeAction.fetchQualitometer.fulfilled]: (state, action) => {
            state.qualitometer = new DtoQualitometer(action.payload)
        },
        [HomeAction.fetchProductionUnits.fulfilled]: (state, action) => {
            state.productionUnits = action.payload.map((s) => new DtoProductionUnit(s))
        },
        [HomeAction.fetchProductionUnit.fulfilled]: (state, action) => {
            state.productionUnit = new DtoProductionUnit(action.payload)
        },
        [HomeAction.fetchPiezoMeasures.fulfilled]: (state, action) => {
            state.piezoMeasures = action.payload.filter((d) => d.status !== 1 && [1, 2].includes(d.qualification)).map((d) => new DtoPiezometryStationMeasure(d))
        },
        [HomeAction.fetchAllLinkedStation.fulfilled]: (state, action) => {
            state.linkedStations = action.payload.map((s) => new DtoAssociatedStation(s))
        },
        [HomeAction.fetchAllLinkedUnitsStations.fulfilled]: (state, action) => {
            state.linkedStations = action.payload.map((s) => new DtoAssociatedStation(s))
        },
        [HomeAction.fetchAllLinkedStationsEvents.fulfilled]: (state, action) => {
            state.linkedStationsEvents = action.payload
        },
        [HomeAction.fetchSandreCodes.fulfilled]: (state, action) => {
            state.sandreCodes = action.payload.map((s) => new DtoSandreCode(s))
        },
        [HomeAction.fetchCities.fulfilled]: (state, action) => {
            const citiesDto = action.payload.map((city) => new DtoCity(city))
            const cities = citiesDto.filter((city) => city.id)
            state.cities = cities
            state.citiesIndex = createIndex(cities)
        },
        [HomeAction.fetchMapLayers.fulfilled]: (state, action) => {
            state.mapLayers = action.payload.map((s) => new LayerDto(s))
        },
        [HomeAction.fetchCartographyThemes.fulfilled]: (state, action) => {
            state.cartographyThemes = action.payload.map((s) => new CartographyThemeDto(s))
        },
        [HomeAction.fetchDataTypesHydro.fulfilled]: (state, action) => {
            state.hydroDataTypes = action.payload.map((s) => new DtoDataType(s))
        },
        [HomeAction.setLoadingData.fulfilled]: (state, action) => {
            state.loadingData = action.payload
        },
    },
})

export const HomeActionConstant = store.actions
export default store.reducer