export default class DtoHydrometryThreshold {
    constructor(obj = {}) {
        this.id = obj.id
        this.stationId = obj.code
        this.title = obj.name
        this.value = obj.value
        this.color = obj.color
        this.isOverrunThreshold = obj.isOverrunThreshold
        this.dataType = obj.dataType
        this.name = obj.name
        this.htmlColor = obj.htmlColor
    }
}