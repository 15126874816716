import React, { useEffect, useMemo, useState } from 'react'
import PropTypes from 'prop-types'
import { getDate } from 'utils/DateUtil'
import { Grid, Icon, Tooltip } from '@mui/material'
import DtoCMSEvent from 'pages/cms/dto/DtoCMSEvent'
import { getNewsColor } from 'utils/ColorUtil'
import { cmsPath } from 'conf/basepath'
import { shallowEqual, useSelector } from 'react-redux'
import { getLogin } from 'utils/LocalStorageUtils'
import { getI18nTitleData } from 'utils/StringUtil'
import i18n from 'simple-react-i18n'
import useApplicationSetting from 'utils/customHook/useApplicationSetting'
import { mainBlack } from '../components/styled/theme'

const openWebSite = (link) => link && window.open(link, '_blank')
const openDocument = (name) => name && window.open(cmsPath + name, '_blank')

const DocumentCard = ({
    cms,
    little = false,
    setIsOpenMessages,
}) => {
    const cmsAnswersParam = useApplicationSetting('cmsReponses', setting => setting === 'true')

    const {
        cmsCategories,
    } = useSelector(store => ({
        cmsCategories: store.CmsReducer.cmsCategories,
    }), shallowEqual)

    const [isFollowed, setIsFollowed] = useState(false)

    const category = useMemo(() => cmsCategories.find(c => c.id === cms.idCategory), [cms, cmsCategories])

    useEffect(() => {
        if (cms.id) {
            setIsFollowed(cms?.cmsFollowers?.some(u => u === getLogin()))
        }
    }, [cms])

    const cmsMessages = cms?.cmsMessages?.filter(c => !c.archive) || []

    return (
        <Grid
            container
            justifyContent='stretch'
            alignItems='center'
            className='clickable'
            sx={{ padding: little ? '0.5rem' : '0.5rem 1rem', marginBottom: little && '1rem' }}
            onClick={() => setIsOpenMessages?.(cms)}
        >
            <Grid container item xs direction='column'>
                <Grid item xs={12} container justifyContent='space-between'>
                    <Grid container alignItems='center' item sx={{ fontSize: little ? '14px' : '18px', width: 'fit-content' }}>
                        {cms.levelContent && <Grid item sx={{ width: '30px', height: '100%', marginRight: '10px', borderRadius: 5, backgroundColor: getNewsColor(cms.levelContent)?.toLowerCase() }} />}
                        <Grid item className='bold'>
                            {getDate(cms.dateDebut || cms.updateDate) || ''}
                        </Grid>
                        {!!isFollowed && (
                            <Grid container alignItems='center' item sx={{ paddingLeft: '0.5rem', width: 'fit-content' }}>
                                <Grid item>
                                    -
                                </Grid>
                                <Grid item sx={{ paddingLeft: '0.5rem' }}>
                                    {i18n.subscriber}
                                </Grid>
                                <Grid item sx={{ paddingLeft: '0.35rem' }}>
                                    <Icon sx={{ color: mainBlack, fontSize: '14px', fontWeight: 'bold' }} size='small'>check</Icon>
                                </Grid>
                            </Grid>
                        )}
                    </Grid>
                    <Grid container item alignItems='center' className='bold' sx={{ fontSize: little ? '14px' : '18px', width: 'fit-content' }}>
                        {!!cms?.link && (
                            <Grid item sx={{ paddingRight: '1rem' }}>
                                <Tooltip title={cms.link}>
                                    <Icon sx={{ fontSize: '1.35rem', transform: 'rotate(135deg)', cursor: 'pointer' }} onClick={() => openWebSite(cms.link)}>
                                        link
                                    </Icon>
                                </Tooltip>
                            </Grid>
                        )}
                        <Grid item>
                            {!little && (category?.title || '')}
                        </Grid>
                    </Grid>
                </Grid>
                {little && (
                    <Grid container alignItems='center' sx={{ fontSize: little ? '14px' : '18px', marginTop: '0.5rem' }}>
                        {cms.title}
                    </Grid>
                )}
                <Grid item xs={12} sx={{ fontSize: little ? '14px' : '18px', maxWidth: '100% !important', overflowX: 'hidden', marginTop: '0.5rem' }}>
                    <div dangerouslySetInnerHTML={{ __html: cms.comment || '' }} />
                </Grid>
                <Grid container item xs={12} alignItems='center' justifyContent='space-between'>
                    <Grid item sx={{ fontSize: '12px', padding: '0.35rem 0' }}>
                        {!!cmsAnswersParam && `${cmsMessages.length} ${getI18nTitleData(i18n.message, i18n.messages, cmsMessages)}`}
                    </Grid>
                    <Grid item container alignItems='center' sx={{ width: 'fit-content' }}>
                        {cms.hasTrad && (
                            <Grid item sx={{ paddingRight: little ? '0.65rem' : '1rem' }}>
                                <Icon sx={{ fontSize: '20px' }}>translate</Icon>
                            </Grid>
                        )}
                        {!!cms?.document?.length && (
                            <Grid item sx={{ paddingRight: little ? '0.65rem' : '1rem' }}>
                                <Icon className='clickable' sx={{ fontSize: '30px' }} onClick={() => openDocument(cms?.document[0].name)}>description</Icon>
                            </Grid>
                        )}
                        <Grid
                            container item
                            id='edit-cms-container'
                            justifyContent='center'
                            alignItems='center'
                            sx={{
                                width: 'fit-content',
                                padding: '0.35rem 0.65rem',
                                borderRadius: '50px',
                                textAlign: 'center',
                                backgroundColor: 'transparent',
                                border: `solid 1px ${mainBlack}`,
                                color: mainBlack,
                                marginRight: '0.5rem',
                                cursor: 'pointer',
                                transition: 'transform 0.3s ease-in-out',
                                '&:hover': {
                                    transform: 'scale(1.05)',
                                },
                            }}
                            onClick={() => cmsAnswersParam && setIsOpenMessages?.(cms)}
                        >
                            <>
                                <Grid item>
                                    <span style={{ fontSize: '12px' }}>{i18n.consult}</span>
                                </Grid>
                                <Grid item sx={{ paddingLeft: '0.35rem' }}>
                                    <Icon sx={{ fontSize: '0.85rem' }}>visibility</Icon>
                                </Grid>
                            </>
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>
        </Grid>
    )
}

DocumentCard.propTypes = {
    cms: PropTypes.arrayOf(PropTypes.instanceOf(DtoCMSEvent)),
    little: PropTypes.bool,
    setIsOpenMessages: PropTypes.func,
}

export default DocumentCard