import { hasValue } from 'utils/NumberUtil'

export default class LayerDto {
    constructor(obj) {
        this.id = obj.id
        this.name = obj.name
        this.type = obj.typeLayer && obj.typeLayer.code ? obj.typeLayer.code : obj.typeLayer
        this.typeLayer = obj.typeLayer
        this.url = obj.url
        this.layer = obj.layer || `${obj.id}`
        this.theme = hasValue(obj.theme) ? obj.theme : obj.themeId
        this.visibility = obj.visibility
        this.projection = obj.projection
    }
}