import moment from 'moment'

const AUTO_Y_SCALE = 0
const MANUAL_Y_SCALE = 1

const DEFAULT_NB_POINT_MOVING_AVERAGE = 1

const AUTO = 'AUTO'
const YEAR = 'year'
const MONTH = 'month'
const WEEK = 'week'
const DAY = 'day'
const HOUR = 'hour'

const OTHER = 'OTHER'
const ONE_WEEK = 'ONE_WEEK'
const ONE_MONTH = 'ONE_MONTH'
const ONE_YEAR = 'ONE_YEAR'

const THRESHOLD = 0
const AREA = 1
const NOTHING = 2

const yearIntervalFormatter = {
    interval: 3600 * 24 * 1000 * 365.5,
    formatter: timestamp => moment(timestamp).year(),
}

const monthIntervalFormatter = {
    interval: 3600 * 24 * 1000 * 30.5,
    formatter: timestamp => {
        const date = moment(timestamp)
        return `${date.format('DD/MM')}\n${date.format('YYYY')}`
    },
}

const weekIntervalFormatter = {
    interval: 3600 * 24 * 1000 * 7,
    formatter: timestamp => {
        const date = moment(timestamp)
        return `${date.format('DD/MM')}\n${date.format('YYYY')}`
    },
}

const dayIntervalFormatter = {
    interval: 3600 * 24 * 1000,
    formatter: timestamp => {
        const date = moment(timestamp)
        return `${date.format('DD/MM')}\n${date.format('YYYY')}`
    },
}

const hourIntervalFormatter = {
    interval: 3600 * 1000,
    formatter: timestamp => moment(timestamp).format('HH:mm:ss'),
}

const getIntervalFormatter = (minDate, maxDate, maxInterval = AUTO) => { // maxInterval in [AUTO, YEAR, MONTH, WEEK, DAY, HOUR]
    switch (maxInterval) {
        case YEAR: return yearIntervalFormatter
        case MONTH: return monthIntervalFormatter
        case WEEK: return weekIntervalFormatter
        case DAY: return dayIntervalFormatter
        case HOUR: return hourIntervalFormatter
        default: {
            const duration = moment.duration(maxDate.diff(minDate))
            if (duration.years() > 30) {
                return { ...yearIntervalFormatter, interval: yearIntervalFormatter.interval * 4 }
            }
            if (duration.years() > 20) {
                return { ...yearIntervalFormatter, interval: yearIntervalFormatter.interval * 3 }
            }
            if (duration.years() > 10) {
                return { ...yearIntervalFormatter, interval: yearIntervalFormatter.interval * 2 }
            }
            if (duration.years() > 2) {
                return yearIntervalFormatter
            }
            if (duration.years() === 2) {
                return { ...monthIntervalFormatter, interval: monthIntervalFormatter.interval * 6 }
            }
            if (duration.years() === 1) {
                return { ...monthIntervalFormatter, interval: monthIntervalFormatter.interval * 2 }
            }
            if (duration.months() > 2) {
                return monthIntervalFormatter
            }
            if (duration.months() > 1) {
                return { ...weekIntervalFormatter, interval: weekIntervalFormatter.interval * 2 }
            }
            if (duration.days() > 14) {
                return weekIntervalFormatter
            }
            if (duration.days() > 7) {
                return { ...dayIntervalFormatter, interval: dayIntervalFormatter.interval * 2 }
            }
            if (duration.days() >= 2) {
                return dayIntervalFormatter
            }
            if (duration.days() === 1) {
                return { ...hourIntervalFormatter, interval: hourIntervalFormatter.interval * 6 }
            }
            if (duration.hours() > 12) {
                return { ...hourIntervalFormatter, interval: hourIntervalFormatter.interval * 3 }
            }
            return hourIntervalFormatter
        }
    }
}

const MEASURE_COTE = {
    NGF: 1,
    DEPTH: 2,
    GROUND: 3,
    LANDMARK: 4,
}

const DISPLAY_COTE = 'DISPLAY_COTE'
const PIEZO_TAB_DISPLAY_MODES = 'PIEZO_TAB_DISPLAY_MODES'

export {
    AUTO,
    YEAR,
    MONTH,
    WEEK,
    DAY,
    HOUR,

    OTHER,
    ONE_WEEK,
    ONE_MONTH,
    ONE_YEAR,

    DEFAULT_NB_POINT_MOVING_AVERAGE,

    AUTO_Y_SCALE,
    MANUAL_Y_SCALE,

    THRESHOLD,
    AREA,
    NOTHING,

    MEASURE_COTE,
    DISPLAY_COTE,
    PIEZO_TAB_DISPLAY_MODES,

    getIntervalFormatter,
}