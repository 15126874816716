import React, { useEffect, useMemo, useRef, useState } from 'react'
import PropTypes from 'prop-types'
import { maxBy, orderBy } from 'lodash'
import CmsAction from 'pages/cms/actions/CmsAction'
import { shallowEqual, useDispatch, useSelector } from 'react-redux'
import { getYear } from 'utils/DateUtil'
import DocumentCard from './DocumentCard'
import i18n from 'simple-react-i18n'
import { Grid, Icon, InputAdornment, useMediaQuery } from '@mui/material'
import ActualitiesCard from './ActualitiesCard'
import ProgressBar from 'components/progress/ProgressBar'
import { mainBlack, mainGrey, mainWhite } from '../components/styled/theme'
import SupportCard from 'pages/account/components/SupportCard'
import CmsDialog from './CmsDialog'
import useApplicationSetting from 'utils/customHook/useApplicationSetting'
import { hasValue } from 'utils/NumberUtil'
import CmsFaqDialog from './CmsFaqDialog'
import { InputRow } from 'components/styled/Inputs'
import useTitle from 'utils/customHook/useTitle'

const NORMAL_STATUS = 1

const FAQ = ({
    actualities = false,
}) => {
    const inputRef = useRef()

    const cmsFAQParam = useApplicationSetting('categoriesCmsFaq', setting => setting ? parseInt(setting) : undefined)
    const cmsAnswersParam = useApplicationSetting('cmsReponses', setting => setting === 'true')

    const [cmsLoaded, setCmsLoaded] = useState(false)
    const [search, setSearch] = useState('')

    const [isOpenMessages, setIsOpenMessages] = useState(false)
    const [selectedCms, setSelectedCms] = useState(undefined)
    const [isOpenFaq, setIsOpenFaq] = useState(false)

    const {
        cmsEvents,
        cmsCategories,
        applicationSettings,
    } = useSelector(store => ({
        cmsEvents: store.CmsReducer.cmsEvents,
        cmsCategories: store.CmsReducer.cmsCategories,
        applicationSettings: store.HomeReducer.applicationSettings,
    }), shallowEqual)

    const dispatch = useDispatch()
    const lgMatches = useMediaQuery((t) => t.breakpoints.up('lg'))

    useTitle(() => [{
        title: cmsCategories.find(categ => categ.id === cmsFAQParam)?.title || i18n.forum,
        href: '/forum',
    }], [])

    useEffect(() => {
        if (!cmsEvents.length) {
            dispatch(CmsAction.fetchCMSEvents()).then(() => setCmsLoaded(true))
        } else {
            setCmsLoaded(true)
        }
    }, [cmsEvents])

    useEffect(() => {
        if (!cmsCategories.length) {
            dispatch(CmsAction.fetchCMSCategories())
        }
    }, [cmsCategories])

    useEffect(() => {
        if (selectedCms?.id) {
            setIsOpenMessages(true)
        }
    }, [selectedCms])

    const documents = useMemo(() => {
        const faqCms = cmsFAQParam ? cmsEvents.filter(c => c.idCategory === cmsFAQParam) : []
        const filteredCMS = faqCms.filter(c => c.status === NORMAL_STATUS &&
            `${c.title} ${c.subtitle} ${c.comment} ${c.author} ${c?.document[0]?.name} ${cmsCategories.find((cat) => cat.id === c.idCategory)?.title}`.toLocaleLowerCase().includes(search?.toLocaleLowerCase()))
        return filteredCMS.map((c) => ({ ...c, year: getYear(c.dateDebut || c.updateDate) }))
    }, [applicationSettings, actualities, cmsEvents, cmsCategories, search])

    const withFaq = hasValue(cmsFAQParam) && cmsAnswersParam

    return (
        <Grid container>
            <Grid container item xs={12} alignItems='center' sx={{ padding: '0.5rem 1rem', backgroundColor: mainGrey, borderTop: `solid 1px ${mainBlack}` }}>
                <Grid item md={2.5} xs={12}>
                    <InputRow
                        id='search'
                        label={i18n.search}
                        value={search}
                        onChange={e => setSearch(e.target.value)}
                        inputRef={inputRef}
                        InputProps={{
                            endAdornment: (
                                <InputAdornment position='end'>
                                    <Icon>search</Icon>
                                </InputAdornment>
                            ),
                        }}
                    />
                </Grid>
            </Grid>
            {cmsLoaded ? (
                <Grid container item xs={12} sx={{ padding: lgMatches ? '30 50' : '30 30' }}>
                    <Grid item lg={8.5} xs={12}>
                        {orderBy(documents, d => maxBy(d.cmsMessages, 'dateMessage')?.dateMessage || d.dateDebut).map(doc => (
                            <Grid container sx={{ borderRadius: '5px', boxShadow: '0px 0px 26px 0px rgb(0 0 0 / 12%)', marginBottom: '1.5rem' }}>
                                <Grid
                                    container item xs={12}
                                    sx={{
                                        fontSize: '20px',
                                        backgroundColor: mainBlack,
                                        color: mainWhite,
                                        fontWeight: '600',
                                        padding: '0.5rem 1rem',
                                        borderRadius: '5px 5px 0 0',
                                    }}
                                >
                                    {`${doc.title}`}
                                </Grid>
                                <Grid container item xs={12} sx={{ borderRadius: '0 0 5px 5px' }}>
                                    <DocumentCard
                                        cms={doc}
                                        setIsOpenMessages={c => setSelectedCms(c)}
                                    />
                                </Grid>
                            </Grid>
                        ))}
                    </Grid>
                    <Grid item lg={3.5} xs={12} style={{ paddingLeft: lgMatches ? 50 : 0 }}>
                        {actualities ? <SupportCard /> : <ActualitiesCard />}
                    </Grid>
                </Grid>
            ) : (
                <Grid item xs={12}>
                    <ProgressBar indeterminate />
                </Grid>
            )}
            {!!withFaq && (
                <Grid
                    container
                    alignItems='center'
                    justifyContent='center'
                    onClick={() => setIsOpenFaq(true)}
                    sx={{
                        position: 'fixed',
                        bottom: '1rem',
                        right: '1.5rem',
                        width: 'fit-content',
                        padding: '0.5rem 1rem',
                        backgroundColor: mainBlack,
                        color: mainWhite,
                        borderRadius: '50px',
                        cursor: 'pointer',
                        transition: 'transform 0.3s ease-in-out',
                        '&:hover': {
                            transform: 'scale(1.05)',
                        },
                    }}
                >
                    <Grid item>
                        <p style={{ fontSize: '16px', margin: 0 }}>{i18n.addNewSubject}</p>
                    </Grid>
                    <Grid item sx={{ paddingLeft: '0.5rem' }}>
                        <Icon>add</Icon>
                    </Grid>
                </Grid>
            )}
            <CmsDialog
                cmsId={selectedCms?.id}
                isOpenMessages={isOpenMessages}
                closeDialog={() => {
                    setSelectedCms(undefined)
                    setIsOpenMessages(false)
                }}
            />
            {!!withFaq && (
                <CmsFaqDialog
                    isOpenFaq={isOpenFaq}
                    setIsOpenFaq={setIsOpenFaq}
                />
            )}
        </Grid>
    )
}

FAQ.propTypes = {
    actualities: PropTypes.bool,
}

export default FAQ