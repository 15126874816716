/* eslint-disable indent */
import { Dialog, DialogActions, DialogContent, DialogTitle, Slide } from '@mui/material'
import { styled } from '@mui/system'
import React from 'react'
import { mainBlue, mainWhite } from './theme'

const Transition = React.forwardRef((props, ref) => {
    return <Slide direction='up' ref={ref} {...props} />
})

export const DialogMUI = styled(({ PaperProps: { sx, ...otherPaperProps } = {}, ...other }) => (
    <Dialog
        fullWidth
        maxWidth='lg'
        TransitionComponent={Transition}
        PaperProps={{
            sx: {
                minHeight: '90vh',
                maxHeight: '90vh',
                ...sx,
            },
            ...otherPaperProps,
        }}
        {...other}
    />
))({})

export const DialogTitleMUI = styled((other) => (
    <DialogTitle
        {...other}
    />))(() => ({
        borderBottom: 'solid 1px grey',
        fontSize: '20px',
        lineHeight: '20px',
        color: mainWhite,
        backgroundColor: mainBlue,
        padding: '12px 20px',
    }))

export const DialogContentMUI = styled((other) => (
    <DialogContent
        {...other}
    />))({
        backgroundColor: 'white',
        padding: '12px 20px !important',
    })

export const DialogActionsMUI = styled((other) => (
    <DialogActions
        {...other}
    />))({
        borderTop: 'solid 1px grey',
        padding: '12px 20px',
        maxHeight: '10rem',
    })