import DtoContact from 'pages/dto/DtoContact'
import { createSlice } from '@reduxjs/toolkit'
import ContactAction from '../actions/ContactAction'

export const initialState = {
    contact: {},
    message: [],
    listOfMessages: [],
}

const store = createSlice({
    name: 'Contact',
    initialState,
    reducers: {},
    extraReducers: {
        [ContactAction.fetchContact.fulfilled]: (state, action) => {
            state.contact = new DtoContact(action.payload)
        },
        [ContactAction.getMessage.fulfilled]: (state, action) => {
            state.message = action.payload
        },
        [ContactAction.getMessagesOf.fulfilled]: (state, action) => {
            state.listOfMessages = action.payload
        },
    },
})

export default store.reducer