import { createSlice } from '@reduxjs/toolkit'
import ResourcesAction from '../actions/ResourcesAction'
import DtoResource from '../dto/DtoResource'

export const initialState = {
    resources: [],
    resource: {},
}

const store = createSlice({
    name: 'Resources',
    initialState,
    reducers: {},
    extraReducers: {
        [ResourcesAction.fetchResources.fulfilled]: (state, action) => {
            state.resources = action.payload.map((r) => new DtoResource(r))
        },
        [ResourcesAction.fetchResource.fulfilled]: (state, action) => {
            state.resource = new DtoResource(action.payload)
        },
    },
})

export default store.reducer