import { createTheme } from '@mui/material/styles'

const mainBlue = '#3f51b5'
const mainOrange = '#ee5b2e'
const secondaryBlue = '#232052'
const badWarning = '#f6b427'
const mainBlack = '#161832'
const mainGreen = '#92D050'
const lightBlue = '#53A1FF'

const mainTheme = createTheme({
    palette: {
        primary: {
            main: mainBlue,
        },
        secondary: {
            main: mainGreen,
        },
        black: {
            main: mainBlack,
        },
        action: {
            disabledBackground: 'lightgrey',
            disabled: 'white',
        },
    },
    typography: {
        fontFamily: ['Helvetica Neue', 'Helvetica', 'Arial', 'sans-serif'].join(','),
        button: {
            fontSize: '1rem',
            textTransform: 'none',
        },
    },
    components: {
        MuiCssBaseline: {
            styleOverrides: () => ({
                body: {
                    margin: 0,
                    padding: 0,
                    lineHeight: 'normal',
                },
            }),
        },
    },
})

export { mainBlue, mainOrange, secondaryBlue, badWarning, mainBlack, mainTheme, mainGreen, lightBlue }