import { createAsyncThunk } from '@reduxjs/toolkit'
import i18n from 'simple-react-i18n'
import ToastrAction from 'toastr/actions/ToastrAction'
import LogAction from 'log/actions/LogAction'
import ApplicationConf from 'conf/ApplicationConf'
import { genericPromise } from 'utils/ActionUtils'

const ResourcesAction = {
    fetchResources: createAsyncThunk(
        'fetchResources',
        async(_, { rejectWithValue, dispatch }) => {
            try {
                const response = await genericPromise(ApplicationConf.referencial.resources())
                return response
            } catch (err) {
                dispatch(LogAction.logError(`${i18n.fetchError} ${i18n.resources}: ${err}`))
                dispatch(ToastrAction.error(i18n.fetchError))
                return rejectWithValue(err)
            }
        }
    ),

    fetchResource: createAsyncThunk(
        'fetchResource',
        async(id, { rejectWithValue, dispatch }) => {
            try {
                const response = await genericPromise(ApplicationConf.referencial.resource(id))
                return response
            } catch (err) {
                dispatch(LogAction.logError(`${i18n.fetchError} ${i18n.resource}: ${err}`))
                dispatch(ToastrAction.error(i18n.fetchError))
                return rejectWithValue(err)
            }
        }
    ),
}

export default ResourcesAction
