export default class DtoReferencial {
    constructor(object) {
        this.id = object.id
        this.code = object.code
        this.name = object.name
        this.status = object.status
        this.labelWithCode = object.code
            ? `${object.name || ''} [${object.code}]`
            : object.name || ''
        this.usermaj = object.usermaj
        this.datemaj = object.datemaj
    }
}
