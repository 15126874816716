import { createSlice } from '@reduxjs/toolkit'
import { IDLE, LOADING, REJECTED, SUCCEEDED } from './DataManagerConstants'
import AccountAction from 'pages/account/actions/AccountAction'
import CmsAction from 'pages/cms/actions/CmsAction'
import HomeAction from 'pages/home/actions/HomeAction'
import FollowAction from 'pages/online/follows/actions/FollowAction'
import ResourcesAction from 'pages/online/resources/actions/ResourcesAction'

export const initialState = { // garder la même structure que les reducer
    account: {
        accountHabilitationsStatus: IDLE,
        accountUserSettingsStatus: IDLE,
    },
    cms: {
        cmsEventsStatus: IDLE,
        cmsCategoriesStatus: IDLE,
    },
    home: {
        applicationSettingsStatus: IDLE,
        citiesStatus: IDLE,
        mapLayersStatus: IDLE,
        cartographyThemesStatus: IDLE,
        linkedStationsStatus: IDLE,
        linkedStationsEventsStatus: IDLE,
    },
    piezometer: {
        piezometersLightStatus: IDLE,
        piezoMeasuresStatus: IDLE,
        piezometerStatus: IDLE,
    },
    hydrometers: {
        hydrometersStatus: IDLE,
        hydrologicalStationStatus: IDLE,
    },
    pluviometers: {
        pluviometersStatus: IDLE,
        pluviometerStatus: IDLE,
    },
    qualitometers: {
        qualitometersLightStatus: IDLE,
        qualitometerStatus: IDLE,
    },
    productionUnits: {
        productionUnitsStatus: IDLE,
        productionUnitStatus: IDLE,
    },
    installationsLight: {
        installationsLightStatus: IDLE,
    },
    follow: {
        piezoObservatoryFollowResultsStatus: IDLE,
        hydroObservatoryFollowResultsStatus: IDLE,
        pluvioObservatoryFollowResultsStatus: IDLE,
        qualitoObservatoryFollowResultsStatus: IDLE,
        watermassesStatus: IDLE,
        stationEventsStatus: IDLE,
        piezoMeasuresStatsStatus: IDLE,
        piezoThresholdsStatus: IDLE,
        hydrometryThresholdsStatus: IDLE,
        hydroStatisticsStatus: IDLE,
        pluviometerAllThresholdsStatus: IDLE,
        pluviometerStatisticsStatus: IDLE,
        qualificationsStatus: IDLE,
        statusStatus: IDLE,
        parametersStatus: IDLE,
        unitsStatus: IDLE,
    },
    resources: {
        resourcesStatus: IDLE,
        resourceStatus: IDLE,
    },
}

const getThunk = (fetch, reducer, stateName) => {
    return {
        [fetch.pending]: state => {
            state[reducer][stateName] = LOADING
        },
        [fetch.fulfilled]: state => {
            state[reducer][stateName] = SUCCEEDED
        },
        [fetch.rejected]: (state, action) => {
            if (!action.meta.condition || state[reducer][stateName] === IDLE) {
                state[reducer][stateName] = REJECTED
            }
        },
    }
}

const store = createSlice({
    name: 'dataManager',
    initialState,
    reducers: {},
    extraReducers: {
        'Home/resetFollow': state => {
            state.piezometer.piezometerStatus = IDLE
            state.hydrometers.hydrologicalStationStatus = IDLE
            state.pluviometers.pluviometerStatus = IDLE
            state.qualitometers.qualitometerStatus = IDLE
        },
        'Home/reset': state => {
            state.applicationSettingsStatus = IDLE
            state.citiesStatus = IDLE
            state.mapLayersStatus = IDLE
            state.cartographyThemesStatus = IDLE
            state.piezometersLightStatus = IDLE
            state.piezoMeasuresStatus = IDLE
            state.piezometerStatus = IDLE
            state.hydrometersStatus = IDLE
            state.hydrologicalStationStatus = IDLE
            state.pluviometersStatus = IDLE
            state.pluviometerStatus = IDLE
            state.qualitometersLightStatus = IDLE
            state.qualitometerStatus = IDLE
            state.productionUnitsStatus = IDLE
            state.productionUnitStatus = IDLE
            state.installationsLightStatus = IDLE
            state.resourcesStatus = IDLE
            state.resourceStatus = IDLE
        },
        'Home/resetLinkedStations': state => {
            state.linkedStationsStatus = IDLE
            state.linkedStationsEventsStatus = IDLE
        },
        'Follow/reset': state => {
            state.piezoObservatoryFollowResultsStatus = IDLE
            state.hydroObservatoryFollowResultsStatus = IDLE
            state.pluvioObservatoryFollowResultsStatus = IDLE
            state.qualitoObservatoryFollowResultsStatus = IDLE
            state.watermassesStatus = IDLE
            state.stationEventsStatus = IDLE
            state.piezoMeasuresStatsStatus = IDLE
            state.piezoThresholdsStatus = IDLE
            state.hydrometryThresholdsStatus = IDLE
            state.hydroStatisticsStatus = IDLE
            state.pluviometerAllThresholdsStatus = IDLE
            state.pluviometerStatisticsStatus = IDLE
            state.qualificationsStatus = IDLE
            state.statusStatus = IDLE
            state.parametersStatus = IDLE
            state.unitsStatus = IDLE
        },
        'Cms/reset': state => {
            state.cmsEventsStatus = IDLE
            state.cmsCategoriesStatus = IDLE
        },
        'Account/reset': state => {
            state.accountHabilitationsStatus = IDLE
            state.accountUserSettingsStatus = IDLE
        },
        ...getThunk(AccountAction.fetchAccountHabilitations, 'account', 'accountHabilitationsStatus'),
        ...getThunk(CmsAction.fetchCMSEvents, 'cms', 'cmsEventsStatus'),
        ...getThunk(CmsAction.fetchCMSCategories, 'cms', 'cmsCategoriesStatus'),
        ...getThunk(HomeAction.fetchApplicationSettings, 'home', 'applicationSettingsStatus'),
        ...getThunk(HomeAction.fetchCities, 'home', 'citiesStatus'),
        ...getThunk(HomeAction.fetchMapLayers, 'home', 'mapLayersStatus'),
        ...getThunk(HomeAction.fetchCartographyThemes, 'home', 'cartographyThemesStatus'),
        ...getThunk(HomeAction.fetchPiezometersLight, 'piezometer', 'piezometersLightStatus'),
        ...getThunk(HomeAction.fetchPiezometer, 'piezometer', 'piezometerStatus'),
        ...getThunk(HomeAction.fetchHydrometers, 'hydrometers', 'hydrometersStatus'),
        ...getThunk(HomeAction.fetchHydrologicalStation, 'hydrometers', 'hydrologicalStationStatus'),
        ...getThunk(HomeAction.fetchPluviometers, 'pluviometers', 'pluviometersStatus'),
        ...getThunk(HomeAction.fetchInstallationsLight, 'installationsLight', 'installationsLightStatus'),
        ...getThunk(HomeAction.fetchPluviometer, 'pluviometers', 'pluviometerStatus'),
        ...getThunk(HomeAction.fetchQualitometersLight, 'qualitometers', 'qualitometersLightStatus'),
        ...getThunk(HomeAction.fetchQualitometer, 'qualitometers', 'qualitometerStatus'),
        ...getThunk(HomeAction.fetchProductionUnits, 'productionUnits', 'productionUnitsStatus'),
        ...getThunk(HomeAction.fetchProductionUnit, 'productionUnits', 'productionUnitStatus'),
        ...getThunk(HomeAction.fetchAllLinkedStation, 'home', 'linkedStationsStatus'),
        ...getThunk(HomeAction.fetchAllLinkedUnitsStations, 'home', 'linkedStationsStatus'),
        ...getThunk(HomeAction.fetchPiezoMeasures, 'piezometer', 'piezoMeasuresStatus'),
        ...getThunk(HomeAction.fetchAllLinkedStationsEvents, 'home', 'linkedStationsEventsStatus'),
        ...getThunk(FollowAction.fetchpiezoObservatoryFollowResults, 'follow', 'piezoObservatoryFollowResultsStatus'),
        ...getThunk(FollowAction.fetchhydroObservatoryFollowResults, 'follow', 'hydroObservatoryFollowResultsStatus'),
        ...getThunk(FollowAction.fetchpluvioObservatoryFollowResults, 'follow', 'pluvioObservatoryFollowResultsStatus'),
        ...getThunk(FollowAction.fetchqualitoObservatoryFollowResults, 'follow', 'qualitoObservatoryFollowResultsStatus'),
        ...getThunk(ResourcesAction.fetchResources, 'resources', 'resourcesStatus'),
        ...getThunk(ResourcesAction.fetchResource, 'resources', 'resourceStatus'),
        ...getThunk(FollowAction.fetchWatermasses, 'follow', 'watermassesStatus'),
        ...getThunk(FollowAction.fetchPiezometerThresholds, 'follow', 'piezoThresholdsStatus'),
        ...getThunk(FollowAction.fetchPiezoMeasuresStats, 'follow', 'piezoMeasuresStatsStatus'),
        ...getThunk(FollowAction.fetchStationsEvents, 'follow', 'stationEventsStatus'),
        ...getThunk(FollowAction.fetchHydrometricThresholds, 'follow', 'hydrometryThresholdsStatus'),
        ...getThunk(FollowAction.fetchHydroStatistics, 'follow', 'hydroStatisticsStatus'),
        ...getThunk(FollowAction.fetchPluviometerAllThresholds, 'follow', 'pluviometerAllThresholdsStatus'),
        ...getThunk(FollowAction.fetchPluvioStatistics, 'follow', 'pluviometerStatisticsStatus'),
        ...getThunk(FollowAction.fetchQualifications, 'follow', 'qualificationsStatus'),
        ...getThunk(FollowAction.fetchStatus, 'follow', 'statusStatus'),
        ...getThunk(FollowAction.fetchParameters, 'follow', 'parametersStatus'),
        ...getThunk(FollowAction.fetchUnits, 'follow', 'unitsStatus'),
        ...getThunk(AccountAction.fetchAccountUserSettings, 'account', 'accountUserSettingsStatus'),
    },
})

export default store.reducer