import React from 'react'
import PropTypes from 'prop-types'
import i18n from 'simple-react-i18n'
import { Grid, LinearProgress, styled } from '@mui/material'
import { mainGrey, mainWhite } from 'pages/online/components/styled/theme'

const Progress = styled((props) => (
    <LinearProgress
        sx={{
            height: 7,
            borderRadius: 15,
            backgroundColor: mainWhite,
        }}
        color='black'
        {...props}
    />
))({})

const ProgressBar = ({
    progress = 0,
    indeterminate = false,
    style = {},
    title,
}) => (
    <div style={{ paddingTop: 20, width: '100%' }}>
        <div
            style={{
                backgroundColor: mainGrey,
                borderRadius: 4,
                padding: 25,
                width: '80%',
                margin: 'auto',
                ...style,
            }}
        >
            <Grid container>
                <Grid item xs={12}>
                    <h3 style={{ fontSize: 18, textAlign: 'center' }}>{title || i18n.progressLoading}</h3>
                </Grid>
                <Grid item xs={12}>
                    {indeterminate ? (
                        <Progress />
                    ) : (
                        <Progress variant='determinate' value={progress} />
                    )}
                </Grid>
            </Grid>
        </div>
    </div>
)

ProgressBar.propTypes = {
    progress: PropTypes.number,
    indeterminate: PropTypes.bool,
    style: PropTypes.shape({}),
    title: PropTypes.string,
}

export default ProgressBar
