module.exports = {
    RECEIVE_PLUVIOMETERS: 'RECEIVE_PLUVIOMETERS',
    RECEIVE_PLUVIOMETERS_WITH_CAMPAIGNS_AND_EVENTS: 'RECEIVE_PLUVIOMETERS_WITH_CAMPAIGNS_AND_EVENTS',
    RECEIVE_PLUVIOMETER: 'RECEIVE_PLUVIOMETER',
    UPDATE_PLUVIOMETER: 'UPDATE_PLUVIOMETER',
    DELETE_PLUVIOMETER: 'DELETE_PLUVIOMETER',
    RECEIVE_NORMALES: 'RECEIVE_NORMALES',
    UPDATE_NORMALES: 'UPDATE_NORMALES',
    RECEIVE_PLUVIOMETER_KEYFIGURES: 'RECEIVE_PLUVIOMETER_KEYFIGURES',
    RECEIVE_PLUVIOMETRY_SITUATIONS: 'RECEIVE_PLUVIOMETRY_SITUATIONS',
    RECEIVE_PLUVIOMETRY_DAYS_SITUATION: 'RECEIVE_PLUVIOMETRY_DAYS_SITUATION',
    RECEIVE_PLUVIOMETER_STATISTICS: 'RECEIVE_PLUVIOMETER_STATISTICS',
    RECEIVE_PLUVIOMETER_LAST_MEASURES: 'RECEIVE_PLUVIOMETER_LAST_MEASURES',
    RECEIVE_PLUVIOMETER_THRESHOLDS: 'RECEIVE_PLUVIOMETER_THRESHOLDS',
    RECEIVE_ALL_PLUVIOMETER_THRESHOLDS: 'RECEIVE_ALL_PLUVIOMETER_THRESHOLDS',
    RECEIVE_PLUVIOMETRY_DATA_TYPES: 'RECEIVE_PLUVIOMETRY_DATA_TYPES',
    RECEIVE_PLUVIOMETRY_CAMPAIGNS: 'RECEIVE_PLUVIOMETRY_CAMPAIGNS',
    RECEIVE_PLUVIOMETRY_CAMPAIGNS_PROGRESS: 'RECEIVE_PLUVIOMETRY_CAMPAIGNS_PROGRESS',
    CREATE_PLUVIOMETER: 'CREATE_PLUVIOMETER',
    RECEIVE_PLUVIOMETRY_CAMPAIGN_STATIONS: 'RECEIVE_PLUVIOMETRY_CAMPAIGN_STATIONS',
    RECEIVE_NETWORK_PLUVIO_LINK: 'RECEIVE_NETWORK_PLUVIO_LINK',
    RECEIVE_ALL_PLUVIOMETERS_CONTRIBUTORS: 'RECEIVE_ALL_PLUVIOMETERS_CONTRIBUTORS',
    RESET_PLUVIOMETER_STATION: 'RESET_PLUVIOMETER_STATION',
    RECEIVE_PLUVIO_CHRONIC_MEASURES: 'RECEIVE_PLUVIO_CHRONIC_MEASURES',
    DATA_TYPE: {
        VIRTUAL_EFFECTIVE_RAIN: -1,
        RAIN: 1,
        EFFECTIVERAIN: 2,
        ETP: 3,
        VALUE_1: 4,
        VALUE_2: 5,
        VALUE_3: 6,
    },
    MONTHS: [
        { id: 1, value: 'january' },
        { id: 2, value: 'february' },
        { id: 3, value: 'march' },
        { id: 4, value: 'april' },
        { id: 5, value: 'may' },
        { id: 6, value: 'june' },
        { id: 7, value: 'july' },
        { id: 8, value: 'august' },
        { id: 9, value: 'september' },
        { id: 10, value: 'october' },
        { id: 11, value: 'november' },
        { id: 12, value: 'december' },
        { id: 13, value: 'total' },
    ],
}
