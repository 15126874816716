import { orderBy } from 'lodash'
import i18n from 'simple-react-i18n'

const getStateLabels = () => ({
    0: { value: `[0] ${i18n.unknown}`, color: 'white' },
    1: { value: `[1] ${i18n.veryGood}`, color: 'blue' },
    2: { value: `[2] ${i18n.good}`, color: 'green' },
    3: { value: `[3] ${i18n.medium}`, color: 'yellow' },
    4: { value: `[4] ${i18n.poorState}`, color: 'orange' },
    5: { value: `[5] ${i18n.badState}`, color: 'red' },
})

const getStatuses = () => [
    { code: 1, name: 'Brute' },
    { code: 2, name: 'Contrôlée niveau 1' },
    { code: 3, name: 'Contrôlée niveau 2' },
    { code: 4, name: 'Interprétée' },
]

const getStatusesWithNoStatus = () => [
    { code: 1, name: 'Brute' },
    { code: 2, name: 'Contrôlée niveau 1' },
    { code: 3, name: 'Contrôlée niveau 2' },
    { code: 4, name: 'Interprétée' },
    { code: -1, name: 'Sans statut' },
]

const getShortStatuses = () => [
    { code: 1, name: 'Brute' },
    { code: 2, name: 'N1' },
    { code: 3, name: 'N2' },
    { code: 4, name: 'Interprétée' },
]

const getQualifications = () => [
    { code: 1, name: 'Correcte' },
    { code: 2, name: 'Incorrecte' },
    { code: 3, name: 'Incertaine' },
    { code: 4, name: 'Non qualifié' },
]

const filterOperationFiles = (operation, files) => {
    const { analysisNumber = '', refAnalysis = '' } = operation
    const labelsMatch = orderBy([analysisNumber, refAnalysis].filter(s => !!s), 'length', 'desc').map(l => l.toLowerCase().split(/[-,._]/g).map(s => s.replace(/^[0]*/g, '')).join(' '))
    return files.filter(f => {
        const name = f.name.toLowerCase().split(/[-,._]/g).map(s => s.replace(/^[0]*/g, ' ')).join('')
        return labelsMatch.some(l => name.includes(` ${l} `))
    })
}


export {
    getStateLabels,
    getStatuses,
    getShortStatuses,
    getQualifications,
    getStatusesWithNoStatus,
    filterOperationFiles,
}
