export default class DtoAltimetrySystem {
    constructor(obj) {
        this.startDate = obj.startDate
        this.endDate = obj.endDate
        this.origin = obj.natureCode
        this.altitude = obj.altitude
        this.altimetricSystem = obj.altimetrySystem
        this.productionMode = obj.productionMode
        this.comment = obj.comment
    }
}