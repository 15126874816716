/* eslint-disable no-process-env */
import fetch from 'isomorphic-fetch'
import { isDeveloppmentEnv } from 'utils/SettingUtils'
import ApplicationConf from '../../conf/ApplicationConf'
import { myUrl } from '../../conf/basepath'
import { getAuthorization } from '../../utils/ActionUtils'

const LogAction = {
    logError: (message = '') => () => {
        // eslint-disable-next-line no-console
        console.error(message)
        if (!myUrl.includes('localhost')) {
            return fetch(ApplicationConf.log.error(), {
                method: 'POST',
                headers: getAuthorization(),
                body: JSON.stringify({
                    message,
                    service: 'SIEAU_FRONTEND',
                    class: (window.location.href || document.URL).split('?_k')[0],
                    version: process.envs.VERSION,
                    function: '',
                }),
            })
        }
        return undefined
    },
    logInfo: (message = '') => () => {
        if (isDeveloppmentEnv()) {
            return fetch(ApplicationConf.log.info(), {
                method: 'POST',
                headers: getAuthorization(),
                body: JSON.stringify({
                    message,
                    service: 'SIEAU_FRONTEND',
                    class: (window.location.href || document.URL).split('?_k')[0],
                    version: process.envs.VERSION,
                    function: '',
                }),
            })
        }
        return undefined
    },
    logDebug: (message = '') => () => fetch(ApplicationConf.log.debug(), {
        method: 'POST',
        headers: getAuthorization(),
        body: JSON.stringify({
            message,
            service: 'SIEAU_FRONTEND',
            class: (window.location.href || document.URL).split('?_k')[0],
            version: process.envs.VERSION,
            function: '',
        }),
    }),

}

export default LogAction