export default class DtoCMSEvent {
    constructor(obj) {
        this.id = obj.id
        this.title = obj.title
        this.subtitle = obj.subtitle
        this.idCategory = obj.idCategory
        this.comment = (obj.comment || '').replaceAll('background-color: rgb(255,255,255)', '')
        this.author = obj.author
        this.dateDebut = obj.dateDebut
        this.dateFin = obj.dateFin
        this.footer = obj.footer
        this.footerContent = obj.footerContent
        this.link = obj.link
        this.authorization = obj.authorization
        this.status = obj.status
        this.updateDate = obj.updateDate
        this.order = obj.order
        this.levelContent = obj.levelContent
        this.login = obj.login
        this.loginMaj = obj.loginMaj
        this.x = obj.x
        this.y = obj.y
        this.projection = obj.projection
        this.categoryWPName = obj.categoryWPName
        this.document = obj.cmsDocument
        this.cmsMessages = obj.cmsMessages
        this.cmsFollowers = obj.cmsFollowers
        this.hasTrad = obj.hasTrad
        this.cmsTrads = obj.cmsTrads || []
        this.cmsLinked = obj.cmsLinked || []
    }
}
