module.exports = {
    // ROLES
    ADMIN: 'ADMIN',
    GEST: 'GEST',
    TECH: 'TECH',
    CONSULT: 'CONSULT',
    // COMMON HABILITATION
    CARTO: 'CARTO',
    DISTRIBUTION: 'DISTRIBUTION',
    HYDRO: 'HYDRO',
    INSTALLATION: 'INSTALLATION',
    CATCHMENT: 'CATCHMENT',
    RESOURCE: 'RESOURCE',
    MAT: 'MAT',
    PIEZO: 'PIEZO',
    PLUVIO: 'PLUVIO',
    PRODUCTION: 'PRODUCTION',
    QUALITO: 'QUALITO',
    SITUATION: 'SITUATION',
    AGRI: 'AGRI',
    QAPTYS: 'QAPTYS',
    OBSERVATORY: 'OBSERVATORY',
    AQUADB: 'AQUADB',
    PGSSE: 'PGSSE',
    // CARTO
    CARTO_HYDRO: 'CARTO_HYDRO',
    CARTO_HYDRO_LABEL: 'cartoHydro',
    CARTO_HYDRO_HTML: 'CARTO_HYDRO_HTML',
    CARTO_HYDRO_HTML_LABEL: 'cartoHydroHtml',
    CARTO_PIEZO: 'CARTO_PIEZO',
    CARTO_PIEZO_LABEL: 'cartoPiezo',
    CARTO_PIEZO_HTML: 'CARTO_PIEZO_HTML',
    CARTO_PIEZO_HTML_LABEL: 'cartoPiezoHtml',
    CARTO_PIEZO_SIT: 'CARTO_PIEZO_SIT',
    CARTO_PIEZO_SIT_LABEL: 'cartoPiezoSituation',
    CARTO_PIEZO_SIT_MOY: 'CARTO_PIEZO_SIT_MOY',
    CARTO_PIEZO_SIT_MOY_LABEL: 'cartoPiezoSituationMoyenne',
    CARTO_PLUVIO: 'CARTO_PLUVIO',
    CARTO_PLUVIO_LABEL: 'cartoPluvio',
    CARTO_PLUVIO_HTML: 'CARTO_PLUVIO_HTML',
    CARTO_PLUVIO_HTML_LABEL: 'cartoPluvioHtml',
    CARTO_PLUVIO_SIT: 'CARTO_PLUVIO_SIT',
    CARTO_PLUVIO_SIT_LABEL: 'cartoPluvioSituation',
    CARTO_QUALITO: 'CARTO_QUALITO',
    CARTO_QUALITO_LABEL: 'cartoQualito',
    CARTO_QUALITO_HTML: 'CARTO_QUALITO_HTML',
    CARTO_QUALITO_HTML_LABEL: 'cartoQualitoHtml',
    CARTO_QUALITO_SIT: 'CARTO_QUALITO_SIT',
    CARTO_QUALITO_SIT_LABEL: 'cartoQualitoSituation',
    // DISTRIBUTION
    DISTRIBUTION_ALL: 'DISTRIBUTION_ALL',
    // HYDRO
    HYDRO_ALL: 'HYDRO_ALL',
    HYDRO_BULLETINS: 'HYDRO_BULLETINS',
    HYDRO_BULLETINS_LABEL: 'hydroBulletins',
    HYDRO_DATA_CONSULT: 'HYDRO_DATA_CONSULT',
    HYDRO_DATA_CONSULT_LABEL: 'hydroDataConsult',
    HYDRO_DATA_MODIF: 'HYDRO_DATA_MODIF',
    HYDRO_DATA_MODIF_LABEL: 'hydroDataModif',
    HYDRO_FICHE_EDITION: 'HYDRO_FICHE_EDITION',
    HYDRO_FICHE_EDITION_LABEL: 'hydroFicheEdition',
    HYDRO_FICHE_EVENEMENT: 'HYDRO_FICHE_EVENEMENT',
    HYDRO_FICHE_EVENEMENT_LABEL: 'hydroFicheEvenement',
    HYDRO_FICHE_MODIF: 'HYDRO_FICHE_MODIF',
    HYDRO_FICHE_MODIF_LABEL: 'hydroFicheModif',
    HYDRO_FICHE_SUPPR: 'HYDRO_FICHE_SUPPR',
    HYDRO_FICHE_SUPPR_LABEL: 'HydroFicheSupress',
    HYDRO_IMPORT: 'HYDRO_IMPORT',
    HYDRO_IMPORT_LABEL: 'hydroImport',
    HYDRO_JOURNAUX: 'HYDRO_JOURNAUX',
    HYDRO_JOURNAUX_LABEL: 'hydroJournaux',
    HYDRO_POMPAGES: 'HYDRO_POMPAGES',
    HYDRO_POMPAGES_LABEL: 'hydroPompages',
    HYDRO_SITUATIONS: 'HYDRO_SITUATIONS',
    HYDRO_SITUATIONS_LABEL: 'hydroSituation',
    HYDRO_SUPERPOSITION: 'HYDRO_SUPERPOSITION',
    HYDRO_SUPERPOSITION_LABEL: 'hydroSuperposition',
    HYDRO_GESTION_MODELE: 'HYDRO_GESTION_MODELE',
    HYDRO_GESTION_MODELE_LABEL: 'hydroGestionModel',
    // INSTALLATION
    INSTALLATION_ALL: 'INSTALLATION_ALL',
    // MATERIEL
    MAT_BATTERIES: 'MAT_BATTERIES',
    MAT_BATTERIES_LABEL: 'powerSupply',
    MAT_CAPTEURS: 'MAT_CAPTEURS',
    MAT_CAPTEURS_LABEL: 'sensor',
    MAT_CENTRALES: 'MAT_CENTRALES',
    MAT_CENTRALES_LABEL: 'central',
    MAT_TELECOMS: 'MAT_TELECOMS',
    MAT_TELECOMS_LABEL: 'telecom',
    MAT_SIMS: 'MAT_SIMS',
    MAT_SIMS_LABEL: 'sims',
    MAT_CLES: 'MAT_CLES',
    MAT_CLES_LABEL: 'key',
    MAT_DIVERS: 'MAT_DIVERS',
    MAT_DIVERS_LABEL: 'variousMateriel',
    MAT_FOURNISSEURS: 'MAT_FOURNISSEURS',
    MAT_FOURNISSEURS_LABEL: 'contributors',
    MAT_MODEMS: 'MAT_MODEMS',
    MAT_MODEMS_LABEL: 'modem',
    // PIEZO
    PIEZO_ALL: 'PIEZO_ALL',
    PIEZO_AEP: 'PIEZO_AEP',
    PIEZO_AEP_LABEL: 'piezoAep',
    PIEZO_BANCARISATION: 'PIEZO_BANCARISATION',
    PIEZO_BANCARISATION_LABEL: 'piezoBancarisation',
    PIEZO_BULLETINS: 'PIEZO_BULLETINS',
    PIEZO_BULLETINS_LABEL: 'piezoBulletins',
    PIEZO_COURBES_REF: 'PIEZO_COURBES_REF',
    PIEZO_COURBES_REF_LABEL: 'piezoCourbeRef',
    PIEZO_DATA_CONSULT: 'PIEZO_DATA_CONSULT',
    PIEZO_DATA_CONSULT_LABEL: 'piezoDataConsult',
    PIEZO_DATA_MODIF: 'PIEZO_DATA_MODIF',
    PIEZO_DATA_MODIF_LABEL: 'piezoDataModif',
    PIEZO_DATA_TRAVAIL: 'PIEZO_DATA_TRAVAIL',
    PIEZO_DATA_TRAVAIL_LABEL: 'piezoDataTravail',
    PIEZO_EXPORT: 'PIEZO_EXPORT',
    PIEZO_EXPORT_LABEL: 'piezoExport',
    PIEZO_FICHE_EDITION: 'PIEZO_FICHE_EDITION',
    PIEZO_FICHE_EDITION_LABEL: 'piezoFicheEdition',
    PIEZO_FICHE_EVENEMENT: 'PIEZO_FICHE_EVENEMENT',
    PIEZO_FICHE_EVENEMENT_LABEL: 'piezoFicheEvenement',
    PIEZO_FICHE_MODIF: 'PIEZO_FICHE_MODIF',
    PIEZO_FICHE_MODIF_LABEL: 'piezoFicheModif',
    PIEZO_FICHE_SUPPR: 'PIEZO_FICHE_SUPPR',
    PIEZO_FICHE_SUPPR_LABEL: 'piezoFicheSuppr',
    PIEZO_IMPORT: 'PIEZO_IMPORT',
    PIEZO_IMPORT_LABEL: 'piezoImport',
    PIEZO_JOURNAUX: 'PIEZO_JOURNAUX',
    PIEZO_JOURNAUX_LABEL: 'piezoJournaux',
    PIEZO_RAPPORT: 'PIEZO_RAPPORT',
    PIEZO_RAPPORT_LABEL: 'piezoRapport',
    PIEZO_SITUATIONS: 'PIEZO_SITUATIONS',
    PIEZO_SITUATIONS_LABEL: 'piezoSituations',
    PIEZO_MAP_SITUATIONS: 'PIEZO_MAP_SITUATIONS',
    PIEZO_SUPERPOSITION: 'PIEZO_SUPERPOSITION',
    PIEZO_SUPERPOSITION_LABEL: 'piezoSuperposition',
    PIEZO_VALIDATION: 'PIEZO_VALIDATION',
    PIEZO_VALIDATION_LABEL: 'piezoValidation',
    PIEZO_CONTRIB: 'PIEZO_CONTRIB',
    PIEZO_CONTRIB_LABEL: 'piezoContributor',
    PIEZO_NETWORK: 'PIEZO_NETWORK',
    PIEZO_NETWORK_LABEL: 'piezoNetwork',
    PIEZO_MODEL: 'PIEZO_MODEL',
    PIEZO_MODEL_LABEL: 'models',
    // PLUVIO
    PLUVIO_ALL: 'PLUVIO_ALL',
    PLUVIO_BANCARISATION: 'PLUVIO_BANCARISATION',
    PLUVIO_BANCARISATION_LABEL: 'pluvioBancarisation',
    PLUVIO_BULLETINS: 'PLUVIO_BULLETINS',
    PLUVIO_BULLETINS_LABEL: 'pluvioBulletins',
    PLUVIO_COURBES_REF: 'PLUVIO_COURBES_REF',
    PLUVIO_COURBES_REF_LABEL: 'pluvioCourbeRef',
    PLUVIO_DATA_CONSULT: 'PLUVIO_DATA_CONSULT',
    PLUVIO_DATA_CONSULT_LABEL: 'pluvioDataConsult',
    PLUVIO_DATA_MODIF: 'PLUVIO_DATA_MODIF',
    PLUVIO_DATA_MODIF_LABEL: 'pluvioDataModif',
    PLUVIO_EXPORT: 'PLUVIO_EXPORT',
    PLUVIO_EXPORT_LABEL: 'pluvioExport',
    PLUVIO_FICHE_EDITION: 'PLUVIO_FICHE_EDITION',
    PLUVIO_FICHE_EDITION_LABEL: 'pluvioFicheEdition',
    PLUVIO_FICHE_EVENEMENT: 'PLUVIO_FICHE_EVENEMENT',
    PLUVIO_FICHE_EVENEMENT_LABEL: 'pluvioFicheEvenement',
    PLUVIO_FICHE_MODIF: 'PLUVIO_FICHE_MODIF',
    PLUVIO_FICHE_MODIF_LABEL: 'pluvioFicheModif',
    PLUVIO_FICHE_SUPPR: 'PLUVIO_FICHE_SUPPR',
    PLUVIO_FICHE_SUPPR_LABEL: 'pluvioFicheSuppr',
    PLUVIO_IMPORT: 'PLUVIO_IMPORT',
    PLUVIO_IMPORT_LABEL: 'pluvioImport',
    PLUVIO_JOURNAUX: 'PLUVIO_JOURNAUX',
    PLUVIO_JOURNAUX_LABEL: 'pluvioJournaux',
    PLUVIO_RAPPORT: 'PLUVIO_RAPPORT',
    PLUVIO_RAPPORT_LABEL: 'pluvioRapport',
    PLUVIO_SITUATIONS: 'PLUVIO_SITUATIONS',
    PLUVIO_SITUATIONS_LABEL: 'pluvioSituation',
    PLUVIO_SUPERPOSITION: 'PLUVIO_SUPERPOSITION',
    PLUVIO_SUPERPOSITION_LABEL: 'pluvioSuperposition',
    PLUVIO_VALIDATION: 'PLUVIO_VALIDATION',
    PLUVIO_VALIDATION_LABEL: 'pluvioValidation',
    // PRODUCTION
    PRODUCTION_ALL: 'PRODUCTION_ALL',
    // QUALITO
    QUALITO_ALL: 'QUALITO_ALL',
    QUALITO_BANCARISATION: 'QUALITO_BANCARISATION',
    QUALITO_BANCARISATION_LABEL: 'qualitoBancarisation',
    QUALITO_BULLETINS: 'QUALITO_BULLETINS',
    QUALITO_BULLETINS_LABEL: 'qualitoBulletins',
    QUALITO_COURBES_REF: 'QUALITO_COURBES_REF',
    QUALITO_COURBES_REF_LABEL: 'qualitoCourbesRef',
    QUALITO_DATA_CONSULT: 'QUALITO_DATA_CONSULT',
    QUALITO_DATA_CONSULT_LABEL: 'qualitoDataConsult',
    QUALITO_DATA_MODIF: 'QUALITO_DATA_MODIF',
    QUALITO_DATA_MODIF_LABEL: 'qualitoDataModif',
    QUALITO_DEMANDES: 'QUALITO_DEMANDES',
    QUALITO_DEMANDES_LABEL: 'qualitoDemandes',
    QUALITO_EXPORT: 'QUALITO_EXPORT',
    QUALITO_EXPORT_LABEL: 'qualitoExport',
    QUALITO_FICHE_EDITION: 'QUALITO_FICHE_EDITION',
    QUALITO_FICHE_EDITION_LABEL: 'qualitoFicheEdition',
    QUALITO_FICHE_EVENEMENT: 'QUALITO_FICHE_EVENEMENT',
    QUALITO_FICHE_EVENEMENT_LABEL: 'qualitoFicheEvenement',
    QUALITO_FICHE_MODIF: 'QUALITO_FICHE_MODIF',
    QUALITO_FICHE_MODIF_LABEL: 'qualitoFicheModif',
    QUALITO_FICHE_SUPPR: 'QUALITO_FICHE_SUPPR',
    QUALITO_FICHE_SUPPR_LABEL: 'qualitoFicheSuppr',
    QUALITO_IMPORT: 'QUALITO_IMPORT',
    QUALITO_IMPORT_LABEL: 'qualitoImport',
    QUALITO_JOURNAUX: 'QUALITO_JOURNAUX',
    QUALITO_JOURNAUX_LABEL: 'qualitoJournaux',
    QUALITO_PRESELECTION: 'QUALITO_PRESELECTION',
    QUALITO_PRESELECTION_LABEL: 'qualitoPreselection',
    QUALITO_RAPPORT: 'QUALITO_RAPPORT',
    QUALITO_RAPPORT_LABEL: 'qualitoRapport',
    QUALITO_RECHERCHES: 'QUALITO_RECHERCHES',
    QUALITO_RECHERCHES_LABEL: 'qualitoRecherches',
    QUALITO_RESEAUX: 'QUALITO_RESEAUX',
    QUALITO_RESEAUX_LABEL: 'qualitoReseaux',
    QUALITO_SEUILS: 'QUALITO_SEUILS',
    QUALITO_SEUILS_LABEL: 'qualitoSeuils',
    QUALITO_SITUATIONS: 'QUALITO_SITUATIONS',
    QUALITO_SITUATIONS_LABEL: 'qualitoSituation',
    QUALITO_SUPERPOSITION: 'QUALITO_SUPERPOSITION',
    QUALITO_SUPERPOSITION_LABEL: 'qualitoSuperposition',
    QUALITO_VALIDATION: 'QUALITO_VALIDATION',
    QUALITO_VALIDATION_LABEL: 'qualitoValidation',
    // SITUATION
    SITUATION_ALL: 'SITUATION_ALL',
    SITUATION_MONITORING: 'SITUATION_MONITORING',
    SITUATION_MONITORING_LABEL: 'situationMonitoring',
    SITUATION_MANAGEMENT: 'SITUATION_MANAGEMENT',
    SITUATION_MANAGEMENT_LABEL: 'situationManagement',
    SITUATION_POLLUTION: 'SITUATION_POLLUTION',
    SITUATION_POLLUTION_LABEL: 'situationPollution',
    SITUATION_FLOOD: 'SITUATION_FLOOD',
    SITUATION_FLOOD_LABEL: 'situationFlood',
    SITUATION_DIAGNOSTIC_FORAGE: 'SITUATION_DIAGNOSTIC_FORAGE',
    SITUATION_DIAGNOSTIC_FORAGE_LABEL: 'situationDiagnosticForage',
    SITUATION_THEME: 'SITUATION_THEME',
    SITUATION_THEME_LABEL: 'situationDisplayTheme',
    // AGRI
    AGRI_DOCUMENTS: 'AGRI_DOCUMENTS',
    AGRI_DOCUMENTS_LABEL: 'agriDocuments',
    AGRI_SURVEYS: 'AGRI_SURVEYS',
    AGRI_SURVEYS_LABEL: 'agriSurveys',
    AGRI_TERRITORY: 'AGRI_TERRITORY',
    AGRI_TERRITORY_LABEL: 'agriTerritory',
    AGRI_RSEAU: 'AGRI_RSEAU',
    AGRI_RSEAU_LABEL: 'RSEau',
}
