import React from 'react'
import { Button } from '@mui/material'
import { styled } from '@mui/system'
import { mainGreen, mainOrange } from './theme'
import { mainRed } from 'utils/constants/ColorTheme'

export const MainButton = styled(({ sx, ...props }) => (
    <Button
        variant='contained'
        sx={{
            height: props.height || 'auto',
            width: props.noFullWidth ? '' : '100%',
            border: props.reverse ? `1px solid ${mainGreen}` : 'none',
            color: `${props.reverse ? 'grey' : 'white'} !important`,
            backgroundColor: props.disabled ? '' : `${props.reverse ? 'white' : mainGreen} !important`,
            margin: '5px 0',
            marginTop: props.margintop ? '1rem' : '0',
            borderRadius: '15px',
            ...sx,
        }}
        {...props}
    />
))({})

export const RedButton = styled((props) => (
    <Button
        variant='contained'
        sx={{
            height: props.height || 'auto',
            width: props.noFullWidth ? '' : '100%',
            border: props.reverse ? `1px solid ${mainRed}` : 'none',
            color: `${props.reverse ? 'grey' : 'white'} !important`,
            backgroundColor: props.disabled ? '' : `${props.reverse ? 'white' : mainRed} !important`,
            margin: '5px 0',
            marginTop: props.margintop ? '1rem' : '0',
        }}
        style={{
            borderRadius: 15,
        }}
        {...props}
    />
))({})

export const OrangeButton = styled((props) => (
    <Button
        variant='contained'
        sx={{
            height: props.height || 'auto',
            width: props.noFullWidth ? '' : '100%',
            border: props.reverse ? `1px solid ${mainOrange}` : 'none',
            color: `${props.reverse ? 'grey' : 'white'} !important`,
            backgroundColor: props.disabled ? '' : `${props.reverse ? 'white' : mainOrange} !important`,
            margin: '5px 0',
            marginTop: props.margintop ? '1rem' : '0',
        }}
        style={{
            borderRadius: 15,
        }}
        {...props}
    />
))({})