export default class DtoSamplePoint {
    constructor(obj) {
        this.idQualitometer = obj.idQualitometer // Double
        this.idPoint = obj.idPoint // Option[Double]
        this.identifiant = obj.identifiant // Option[String]
        this.name = obj.name // Option[String]
        this.startDate = obj.startDate // Option[DateTime]
        this.endDate = obj.endDate // Option[DateTime]
        this.totalLength = obj.totalLength // Option[Double]
        this.xUpstream = obj.xUpstream // Option[Double]
        this.yUpstream = obj.yUpstream // Option[Double]
        this.xDownstream = obj.xDownstream // Option[Double]
        this.yDownstream = obj.yDownstream // Option[Double]
        this.mainObject = obj.mainObject // Option[String]
        this.advise = obj.advise // Option[String]
        this.x = obj.x // Option[Double]
        this.y = obj.y // Option[Double]
        this.projection = obj.projection // Option[Double]
        this.advisedDepth = obj.advisedDepth // Option[Double]
        this.comment = obj.comment // Option[String]
        this.flowWidth = obj.flowWidth // Option[Double]
        this.photo1 = obj.photo1 // Option[String]
        this.photo2 = obj.photo2 // Option[String]
        this.photo3 = obj.photo3 // Option[String]
        this.photo4 = obj.photo4 // Option[String]
        this.photo5 = obj.photo5 // Option[String]
        this.photo6 = obj.photo6 // Option[String]
        this.startDepth = obj.startDepth // Option[Double]
        this.endDepth = obj.endDepth // Option[Double]
        this.codeSupport = obj.codeSupport // Option[Float]
        this.code = obj.code // Option[String]
    }
}
