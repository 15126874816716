/* eslint-disable spaced-comment */
/* eslint-disable max-nested-callbacks */
/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect, useMemo, useRef, useState } from 'react'

//! care when using the dependencies, it can cause some side effect if the fetch is launch multiple time before the first one is finish
const useProgressDispatch = (callbackPromise = () => [], dependencies = []) => {
    // Used to prevent state update if the component is unmounted
    const cancelRequest = useRef(false)

    const promises = useMemo(() => callbackPromise(cancelRequest), [...dependencies])

    const [progress, setProgress] = useState(0)
    const total = promises.length

    useEffect(() => {
        cancelRequest.current = false
        setProgress(0)
        promises.forEach(promise => promise.then(() => !cancelRequest.current && setProgress(p => p + 1)))
        return () => {
            cancelRequest.current = true
            promises.map(p => p.abort?.())
        }
    }, [promises])

    return {
        progress: total ? (progress / total) * 100 : 100,
        isLoaded: progress === total,
    }
}

export default useProgressDispatch