export default class DtoContact {
    constructor(object) {
        this.id = object.id
        this.code = object.code
        this.name = object.name
        this.status = object.status
        this.labelWithCode = object.code ? `${object.name || ''} [${object.code}]` : (object.name || '')
        this.cityCode = object.cityCode
        this.phoneTel = object.phoneTel
        this.desktopTel = object.desktopTel
        this.mobile = object.mobile
        this.socialReason = object.socialReason
        this.postalCode = object.postalBox
        this.postalBox = object.postalBox
        this.address = object.address
        this.email = object.email
        this.nullValue = object.picto
        this.noNewsLetterDate = object.noNewsLetterDate
        this.noNewLetterLogin = object.noNewLetterLogin
        this.headers = ['nullValue', 'name', 'postalCode', 'city', 'phoneTel', 'desktopTel', 'mobile', 'email', 'socialReason']
    }
}