/* eslint-disable no-process-env */
import thunk from 'redux-thunk'
import { createLogger } from 'redux-logger'
import { configureStore, combineReducers } from '@reduxjs/toolkit'
import { connectRouter, routerMiddleware } from 'connected-react-router'
import { createHashHistory } from 'history'
import HomeReducer, { initialState as HomeReducerStore } from '../pages/home/reducers/HomeReducer'
import AccountReducer, { initialState as AccountReducerStore } from '../pages/account/reducers/AccountReducer'
import ContactReducer, { initialState as ContactReducerStore } from '../pages/contact/reducers/ContactReducer'
import CmsReducer, { initialState as CmsReducerStore } from '../pages/cms/reducers/CmsReducer'
import DataManagerReducer, { initialState as DataManagerReducerStore } from '../store/DataManagerReducer'
import FollowReducer, { initialState as FollowReducerStore } from '../pages/online/follows/reducers/FollowReducer'
import ResourcesReducer, { initialState as ResourcesReducerStore } from '../pages/online/resources/reducers/ResourcesReducer'

export const history = createHashHistory()

const middlewares = [thunk, routerMiddleware(history)]

const logger = createLogger()

if (process.env.NODE_ENV !== 'production') {
    middlewares.push(logger)
}

const initialStore = {
    HomeReducer: HomeReducerStore,
    AccountReducer: AccountReducerStore,
    ContactReducer: ContactReducerStore,
    CmsReducer: CmsReducerStore,
    DataManagerReducer: DataManagerReducerStore,
    FollowReducer: FollowReducerStore,
    ResourcesReducer: ResourcesReducerStore,
}


const appReducers = combineReducers({
    HomeReducer,
    AccountReducer,
    ContactReducer,
    CmsReducer,
    DataManagerReducer,
    FollowReducer,
    ResourcesReducer,
    router: connectRouter(history),
})

const rootReducer = (state, action) => {
    if (action.type === 'RESET_ALL_STORE') {
        const { applicationSettings } = state.HomeReducer
        const homeReducer = {
            ...initialStore.HomeReducer,
            applicationSettings,
        }
        return {
            ...state,
            ...initialStore,
            HomeReducer: homeReducer,
        }
    }
    return appReducers(state, action)
}

// Make enchancers if app need offline reducer as: enhancers: (defaultEnhancers) => [offline, ...defaultEnhancers] and the result in a final setup is: [offline, applyMiddleware, devToolsExtension]

const AppStore = configureStore({
    reducer: rootReducer,
    middleware: (getDefaultMiddleware) => getDefaultMiddleware({ serializableCheck: false }).concat(...middlewares),
    // enhancers: (defaultEnhancers) => [offline, ...defaultEnhancers],
})

export default AppStore
