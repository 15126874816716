import DtoReferencial from 'pages/online/follows/dto/DtoReferencial'

export default class DtoContributorGroup extends DtoReferencial {
    constructor(object) {
        super(object)
        this.code = object.groupCode
        this.name = object.name
        this.siren = object.siren
        this.numberOfMembers = object.count
        this.contributors = object.contributors
    }
}