export default class DtoWorkLink {
    constructor(obj) {
        this.idStation = obj.idStation
        this.stationType = obj.stationType
        this.sensorType = obj.sensorType
        this.phone = obj.phone
        this.workDepth = obj.workDepth
        this.sensorDepth = obj.sensorDepth
        this.serialNumber = obj.serialNumber
        this.internalDiameter = obj.internalDiameter
        this.externalDiameter = obj.externalDiameter
        this.comment = obj.comment
    }
}