export default class DtoOperationHydrobio {
    constructor(obj) {
        this.id = obj.id
        this.date = obj.date
        this.qualification = obj.qualification
        this.status = obj.status
        this.support = obj.support
        this.laboratory = obj.laboratoryId
        this.producer = obj.producer
        this.producerRef = obj.producerRef
        this.nullValue = obj.nullValue
        this.qualificationTableColor = obj.qualificationTableColor
        this.analysisNumber = obj.analysisNumber
        this.refAnalysis = obj.refAnalysis
        this.point = obj.point
        this.sampler = obj.sampler
        this.determiner = obj.determiner
        this.analysisMethod = obj.analysisMethod
        this.countAnaNotQualified = obj.countAnaNotQualified || 0
        this.countCorrectAnalysis = obj.countCorrectAnalysis || 0
        this.countIncorrectAnalysis = obj.countIncorrectAnalysis || 0
        this.countUncertainAnalysis = obj.countUncertainAnalysis || 0
        this.nbResult = obj.nbResult || 0
        this.headers = ['qualificationTableColor', 'statusAndDate', 'date', 'nbAnalysis', 'qualification', 'status', 'support', 'laboratory', 'producer', 'nullValue']
    }
}