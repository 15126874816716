import { Grid } from '@mui/material'
import stormError from 'assets/pictures/pictos/stormError.png'
import React from 'react'
import i18n from 'simple-react-i18n'

const AquaKwak = () => (
    <div className='col s12'>
        <div className='row no-margin'>
            <div className='card'>
                <div className='row card-content'>
                    <Grid container justifyContent='center' alignItems='center' spacing={3}>
                        <Grid item xs={8}>
                            <img src={stormError} style={{
                                display: 'block',
                                marginLeft: 'auto',
                                marginRight: 'auto',
                                width: '30%',
                            }}
                            />
                        </Grid>
                        <Grid item xs={8}>
                            <h1 style={{ textAlign: 'center' }}>{i18n.somethingWentWrong}</h1>
                        </Grid>
                        <Grid item xs={8}>
                            <label style={{ textAlign: 'center', fontSize: '2rem' }}>{i18n.sorryError}</label>
                        </Grid>
                    </Grid>
                </div>
            </div>
        </div>
    </div>
)

export default AquaKwak