import { usedUrl } from 'conf/basepath'

const NOW_REGEX = new RegExp('(#NOW)(?=&|$)', 'g')

const MAPS_REGEX = new RegExp('(#MYMAPS)', 'g')

const parseNowVariableURL = (url, code) => {
    if (code) {
        return url.replace(NOW_REGEX, new Date().toISOString().slice(0, 10)).replace(MAPS_REGEX, `${usedUrl}map/${code}`)
    }
    return url.replace(NOW_REGEX, new Date().toISOString().slice(0, 10)).replace(MAPS_REGEX, `${usedUrl}map/`)
}

const getUrlScreen = () => (window.location.href || document.URL).split('#')[1]

export {
    parseNowVariableURL,
    getUrlScreen,
}
