const nFormatter = (num) => {
    if (num >= 1000000000) {
        return `${(num / 1000000000).toFixed(0).replace(/\.0$/, '')}G`
    }
    if (num >= 1000000) {
        return `${(num / 1000000).toFixed(0).replace(/\.0$/, '')}M`
    }
    if (num >= 1000) {
        return `${(num / 1000).toFixed(0).replace(/\.0$/, '')}K`
    }
    return num
}

const getNumberFormat = (number) => {
    return Number(number).toLocaleString('fr-FR')
}

const getLongNumber = (number) => {
    return nFormatter(number)
}

const formatNumber = (number) => {
    return number.toFixed(3)
}

const formatFixedNumber = (number, fixedNumber) => {
    return number.toFixed(fixedNumber)
}

const fixedNumber = (number, fixed = 2) => {
    if (number) {
        return Number(number).toFixed(fixed)
    }
    return 0
}

const getSuperiorAround = (value) => {
    return Math.ceil(value)
}
const getInferiorAround = (value) => {
    return Math.floor(value)
}

const hasValue = (value) => {
    return value || value === 0 || value === '0'
}

const hasBooleanValue = (value) => {
    return value === true || value === false
}

const round = (number = 0, precision = 2) => {
    const factor = Math.pow(10, precision)
    return Math.round(number * factor) / factor
}


export { getNumberFormat, round, getLongNumber, nFormatter, formatNumber, formatFixedNumber, fixedNumber, getSuperiorAround, getInferiorAround, hasValue, hasBooleanValue }
