import React, { useMemo } from 'react'
import PropTypes from 'prop-types'
import { maxBy, some } from 'lodash'
import DtoFile from '../referencials/documents/dto/DtoFile'
import { PICTURES_EXTENSIONS } from '../referencials/documents/constants/FileConstants'
import { getDocumentTypeIcon, getFileExtension } from 'utils/FileUtils'
import { capitalizeFirstLetter } from 'utils/StringUtil'
import { Grid } from '@mui/material'

const FilePanel = ({
    files = [],
    showLast = false,
    editable = false,
}) => {
    const create = file => {
        const nameFormat = file.isAllDataName ? capitalizeFirstLetter(file.shortName) : file.name
        const fileExtension = getFileExtension(file.name)
        return (
            <Grid item xs={6} className='padding-1'>
                { editable ? (
                    <span style={ {
                        marginRight: '10px',
                        color: 'red',
                        fontWeight: 'bold',
                        cursor: 'pointer',
                    } }
                    >X</span>
                ) : '' }
                <a style={{ border: 'solid 2px black', borderRadius: '5px', padding: 5, textDecoration: 'none' }} href={ file.url } target='_blank' data-caption={ nameFormat } className='valign-wrapper'>
                    <img src={ getDocumentTypeIcon(fileExtension) } style={{ maxWidth: 15, height: 'auto' }} />
                    <b className='truncate clickable padding-left-1' style={{ fontSize: 10, color: 'black' }}>{ nameFormat }</b>
                </a>
            </Grid>
        )
    }

    const componentfiles = useMemo(() => {
        const filterFiles = files.filter(f => !some(PICTURES_EXTENSIONS, extension => f.name.endsWith(extension)))
        if (showLast) {
            return filterFiles.length ? [create(maxBy(filterFiles, 'updateDate'))] : []
        }
        return filterFiles.map(f => create(f))
    }, [files, showLast])

    return componentfiles.length !== 0 && (
        <Grid container>
            { componentfiles }
        </Grid>
    )
}

FilePanel.propTypes = {
    code: PropTypes.string,
    files: PropTypes.arrayOf(PropTypes.instanceOf(DtoFile)),
    showLast: PropTypes.bool,
    editable: PropTypes.bool,
    hideTitle: PropTypes.bool,
}

export default FilePanel