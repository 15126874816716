import DtoLocation from './DtoLocation'


export default class DtoProductionUnit {
    constructor(obj) {
        // champs en BD

        this.id = obj.id
        this.code = obj.code
        this.name = obj.name
        this.description = obj.description
        this.startDate = obj.startDate
        this.comments = obj.comments
        this.x = obj.x
        this.y = obj.y
        this.projection = obj.projection
        this.townCode = obj.townCode
        this.declarationTownCode = obj.declarationTownCode
        this.statusCode = obj.statusCode
        this.max3hCapacity = obj.max3hCapacity
        this.volumeAllowDay = obj.volumeAllowDay
        this.dataOrigin = obj.dataOrigin
        this.status = obj.status
        this.statusLogin = obj.statusLogin
        this.statusDate = obj.statusDate

        this.productionUnitType = obj.productionUnitType
        this.directUsage = obj.directUsage
        this.waterNature = obj.waterNature
        this.controlFrequency = obj.controlFrequency
        this.realisedFrequency = obj.realisedFrequency
        this.decretFrequency = obj.decretFrequency
        this.authorizedFlow = obj.authorizedFlow
        this.annualWaterAmountExploited = obj.annualWaterAmountExploited
        this.perimeter = obj.perimeter
        this.nbStationInService = obj.nbStationInService
        this.contextType = obj.contextType
        this.hydrogeologicalEntity = obj.hydrogeologicalEntity
        this.captive = obj.captive
        this.aquiferType = obj.aquiferType
        this.watershedArea = obj.watershedArea
        this.watermass = obj.watermass
        this.hydrographicZone = obj.hydrographicZone
        this.hydrographicContextDescription = obj.hydrographicContextDescription
        this.owner = obj.owner
        this.exploitationCode = obj.exploitationCode
        this.mnemonic = obj.mnemonic
        this.declarationTownCode = obj.declarationTownCode
        this.prefectoralArrestDate = obj.prefectoralArrestDate
        this.arrestDuration = obj.arrestDuration
        this.contributors = obj.contributors
        this.builtArea = obj.builtArea // Option[Double] = None,
        this.nonBuiltArea = obj.nonBuiltArea // Option[Double] = None,
        this.renovationDate = obj.renovationDate // Option[DateTime]


        // Bonus
        this.typeName = 'productionUnit'
        this.localisation = new DtoLocation(obj)

        // Jointure
        this.buildingOwnerId = obj.buildingOwnerId
        this.buildingOwner = obj.buildingOwnerName || obj.buildingOwner
        this.buildingOwnerType = obj.buildingOwnerType
        this.nameBuildingOwnerType = obj.nameBuildingOwnerType
        this.operator = obj.operatorName || obj.operator
        this.operatorId = obj.operatorId
        this.operatorType = obj.operatorType
        this.nameOperatorType = obj.nameOperatorType

        // this.updateLogin = obj.updateLogin
        // this.updateDate = obj.updateDate
        this.headers = [
            'code',
            'city',
            'name',
            'startDate',
            'statusCode',
            'operator',
            'buildingOwner',
            'authorizedFlow',
            'volumeAllowDay',
            'max3hCapacity',
        ]
    }
}