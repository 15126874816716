import { SIEAU_LOGIN } from 'pages/home/constants/HomeConstants'

const getLocalStorageJson = (key) => {
    const stored = localStorage.getItem(key)
    if (stored) {
        return JSON.parse(decodeURIComponent(window.atob(stored)))
    }
    return null
}

const setLocalStorageJson = (key, obj) => {
    const encoded = window.btoa(encodeURIComponent(JSON.stringify(obj)))
    localStorage.setItem(key, encoded)
}

const isRunningApp = () => {
    return document.URL.indexOf('http') !== 0 || document.URL.includes('localhost')
}

const isLargeWindow = () => {
    return window.innerWidth >= 700
}

const isOffLine = () => {
    return !window.navigator.onLine
}

const getLogin = () => {
    return localStorage.getItem(SIEAU_LOGIN)
}

export { getLocalStorageJson, setLocalStorageJson, isRunningApp, isLargeWindow, isOffLine, getLogin }