import DtoAltimetrySystem from './DtoAltimetrySystem'
import DtoDespoliation from './DtoDespoliation'
import DtoGeoData from './DtoGeoData'
import DtoLandmark from './DtoLandmark'
import DtoLithology from './DtoLithology'
import DtoLithologyType from './DtoLithologyType'
import DtoLocation from './DtoLocation'
import DtoLocationLink from './DtoLocationLink'
import DtoMeasureMethod from './DtoMeasureMethod'
import DtoNetworkLink from './DtoNetworkLink'
import DtoPiezometerContributorLink from './DtoPiezometerContributorLink'
import DtoStationContacts from './DtoStationContacts'
import DtoWatermassLink from './DtoWatermassLink'
import DtoWorkLink from './DtoWorkLink'


export default class DtoHydrometricStation {
    constructor(object) {
        this.id = object.id
        this.code = object.code
        this.name = object.name
        this.stationType = object.stationType
        this.creationDate = object.creationDate
        this.closeDate = object.closeDate
        this.x = object.x
        this.y = object.y
        this.projection = object.projection
        this.projectionType = object.projectionType
        this.altitude = object.altitude
        this.comment = object.comment
        this.townCode = object.townCode
        this.contactCode = object.contactCode
        this.localisation = new DtoLocation(object, object.altitude)
        this.updateLogin = object.updateLogin
        this.updateDate = object.updateDate

        this.link_altimetrySystems = object.link_altimetrySystems && object.link_altimetrySystems.length ? object.link_altimetrySystems.map(l => new DtoAltimetrySystem(l)) : []
        this.link_contributors = object.link_contributors ? object.link_contributors.map(c => new DtoPiezometerContributorLink(c)): []
        this.link_despoliationModes = object.link_despoliationModes ? object.link_despoliationModes.map(d => new DtoDespoliation(d)) : []
        this.link_geo = object.link_geo ? object.link_geo.map(g => new DtoGeoData(g)) : []
        this.link_landmarks = object.link_landmarks ? object.link_landmarks.map(l => new DtoLandmark(l)) : []
        this.link_lithologicType = object.link_lithologicType ? object.link_lithologicType.map(lt => new DtoLithologyType(lt)) : []
        this.link_lithology = object.link_lithology ? object.link_lithology.map(ll => new DtoLithology(ll)) : []
        this.link_locations = object.link_locations ? object.link_locations.map(l => new DtoLocationLink(l)) : []
        this.link_measureMethod = object.link_measureMethod ? object.link_measureMethod.map(lmm => new DtoMeasureMethod(lmm)) : []
        this.link_networks = object.link_networks ? object.link_networks.map(l => new DtoNetworkLink(l)) : []
        this.link_watermasses = object.link_watermasses ? object.link_watermasses.map(l => new DtoWatermassLink(l)) : []
        this.link_work = object.link_work ? object.link_work.map(lw => new DtoWorkLink(lw)) : []
        this.link_contacts = object.link_contacts ? object.link_contacts.map(lc => new DtoStationContacts(lc)) : []

        this.typeName = 'hydrometry'
        this.nature = object.hydroObjectNature
        this.cote = object.cote
        this.fieldMode = object.fieldMode
        this.designation = object.designation
        this.waterSourceType = object.waterpointType
        this.address = object.address
        this.jobExecutionId = object.jobExecutionId
        this.watershedCode = object.watershedCode
    }
}