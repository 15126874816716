import React from 'react'
import PropTypes from 'prop-types'
import { orderBy, take } from 'lodash'
import { Grid } from '@mui/material'
import { contentsPath } from 'conf/basepath'
import DtoPicture from '../referencials/documents/dto/DtoPicture'

const SmallPicturePanel = ({
    pictures = [],
}) => {
    const createPicture = (picture) => {
        const url = picture.url.startsWith('http') ? picture.url : `${contentsPath}${picture.url}`
        return (
            <Grid item xs={2} sx={{ textAlign: 'center' }}>
                <a href={url} data-fancybox='images' target='_blank'
                    data-caption={picture.name}
                >
                    <img src={url} className='small-picture' style={{ maxWidth: '100%', maxHeight: 50 }} />
                </a>
            </Grid>
        )
    }

    const getPictures = () => take(orderBy(pictures, 'date', 'desc'), 3).map(picture => createPicture(picture))

    return pictures.length > 0 && (
        <Grid container alignItems='center' justifyContent='flex-end'>
            {getPictures()}
        </Grid>
    )
}

SmallPicturePanel.propTypes = {
    element: PropTypes.string,
    pictures: PropTypes.arrayOf(PropTypes.instanceOf(DtoPicture)),
    showTitle: PropTypes.bool,
}

export default SmallPicturePanel