import i18n from 'simple-react-i18n'
import ApplicationConf from 'conf/ApplicationConf'
import { genericPromise } from 'utils/ActionUtils'
import ToastrAction from 'toastr/actions/ToastrAction'
import { createAsyncThunk } from '@reduxjs/toolkit'
import LogAction from 'log/actions/LogAction'

const ContactAction = {

    fetchContact: createAsyncThunk(
        'fetchContact',
        async(code, { rejectWithValue, dispatch }) => {
            try {
                return await genericPromise(ApplicationConf.referencial.contact(code))
            } catch (err) {
                dispatch(LogAction.logError(`${i18n.fetchError} ${i18n.contact}: ${err}`))
                ToastrAction.error(i18n.fetchError + i18n.contact)
                return rejectWithValue(err)
            }
        }
    ),

    updateContact: createAsyncThunk(
        'updateContact',
        async(contact, { rejectWithValue, dispatch }) => {
            try {
                const response = await genericPromise(ApplicationConf.referencial.contacts(), 'PUT', contact)
                if (response.update === 1) {
                    dispatch(ContactAction.fetchContact(contact.id))
                    ToastrAction.success(i18n.contactSuccessUpdated)
                    return contact.id
                }
                throw new Error()
            } catch (err) {
                dispatch(LogAction.logError(`${i18n.updateError} ${i18n.contact}: ${err}`))
                ToastrAction.error(i18n.updateError + i18n.contact)
                return rejectWithValue(err)
            }
        }
    ),

    createContact: createAsyncThunk(
        'createContact',
        async(contact, { rejectWithValue, dispatch }) => {
            try {
                const response = await genericPromise(ApplicationConf.referencial.contacts(), 'POST', contact)
                if (response.insert === 1) {
                    return response.id
                }
                throw new Error()
            } catch (err) {
                dispatch(LogAction.logError(`${i18n.createError} ${i18n.contact}: ${err}`))
                ToastrAction.error(i18n.createError + i18n.contact)
                return rejectWithValue(err)
            }
        }
    ),

    getMessagesOf: createAsyncThunk(
        'getMessagesOf',
        async(login, { rejectWithValue, dispatch }) => {
            try {
                const response = await genericPromise(ApplicationConf.contact.getMessagesOf(login))
                return response
            } catch (err) {
                dispatch(LogAction.logError(`${i18n.fetchError}: ${err}`))
                ToastrAction.error(i18n.fetchError)
                return rejectWithValue(err)
            }
        }
    ),

    getMessage: createAsyncThunk(
        'getMessage',
        async(id, { rejectWithValue, dispatch }) => {
            try {
                const response = await genericPromise(ApplicationConf.contact.getMessage(id))
                return response
            } catch (err) {
                dispatch(LogAction.logError(`${i18n.fetchError}: ${err}`))
                ToastrAction.error(i18n.fetchError)
                return rejectWithValue(err)
            }
        }
    ),

    sendMessage: createAsyncThunk(
        'sendMessage',
        async(userMessage, { rejectWithValue, dispatch }) => {
            try {
                const response = await genericPromise(ApplicationConf.contact.sendMessage(), 'POST', userMessage)
                if (response.id) {
                    return response.id
                }
                throw new Error()
            } catch (err) {
                dispatch(LogAction.logError(`${i18n.createError}: ${err}`))
                ToastrAction.error(i18n.createError)
                return rejectWithValue(err)
            }
        }
    ),

    defConv: createAsyncThunk(
        'defConv',
        async(userMessageDest, { rejectWithValue, dispatch }) => {
            try {
                const response = await genericPromise(ApplicationConf.contact.defConv(), 'POST', userMessageDest)
                if (response.id) {
                    return response.id
                }
                throw new Error()
            } catch (err) {
                dispatch(LogAction.logError(`${i18n.createError}: ${err}`))
                ToastrAction.error(i18n.createError)
                return rejectWithValue(err)
            }
        }
    ),

}

export default ContactAction
