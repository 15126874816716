import DtoAccountHabilitation from '../dto/DtoAccountHabilitation'
import KeyFigureDto from '../dto/KeyFigureDto'
import DtoAccountSettings from '../dto/DtoAccountSettings'
import DtoAccountStatistic from '../dto/DtoAccountStatistic'
import DtoAccountStationStatistic from '../dto/DtoAccountStationStatistic'
import DtoAccountUsageStatistic from '../dto/DtoAccountUsageStatistic'
import DtoAccountStation from '../dto/DtoAccountStation'
import DtoUserCmsStatistic from '../dto/DtoUserCmsStatistic'
import DtoUser from '../dto/DtoUser'
import { createSlice } from '@reduxjs/toolkit'
import AccountAction from '../actions/AccountAction'

export const initialState = {
    accountUser: {},
    accountHabilitations: [],
    accountUserKeyFigures: [],
    accountUserSettings: [],
    accountStatistics: [],
    accountStationStatistics: [],
    accountUsageStatistics: [],
    accountStations: [],
    accountUserCmsStatistics: [],
    accountContributor: {},
}

const store = createSlice({
    name: 'Account',
    initialState,
    reducers: {
        reset: (state) => {
            state.accountUser = {}
            state.accountHabilitations = []
            state.accountUserKeyFigures = []
            state.accountUserSettings = []
            state.accountStatistics = []
            state.accountStationStatistics = []
            state.accountUsageStatistics = []
            state.accountStations = []
            state.accountUserCmsStatistics = []
            state.accountContributor = {}
        },
    },
    extraReducers: {
        [AccountAction.fetchUserData.fulfilled]: (state, action) => {
            state.accountUser = new DtoUser(action.payload[0].payload)
            state.accountHabilitations = action.payload[1].payload
            state.accountUserKeyFigures = action.payload[2].payload
            state.accountUserSettings = action.payload[3].payload
            state.accountStatistics = action.payload[4].payload
            state.accountStationStatistics = action.payload[5].payload
            state.accountUsageStatistics = action.payload[6].payload
            state.accountStations = action.payload[7].payload
            state.accountUserCmsStatistics = action.payload[8].payload
        },
        [AccountAction.fetchUser.fulfilled]: (state, action) => {
            state.accountUser = new DtoUser(action.payload)
        },
        [AccountAction.fetchAccountHabilitations.fulfilled]: (state, action) => {
            state.accountHabilitations = action.payload.map(el => new DtoAccountHabilitation(el))
        },
        [AccountAction.fetchAccountUserCmsStatistic.fulfilled]: (state, action) => {
            state.accountUserCmsStatistics = action.payload.map(el => new DtoUserCmsStatistic(el))
        },
        [AccountAction.fetchAccountUserKeyfigure.fulfilled]: (state, action) => {
            state.accountUserKeyFigures = action.payload.map(el => new KeyFigureDto(el))
        },
        [AccountAction.fetchAccountUserSettings.fulfilled]: (state, action) => {
            state.accountUserSettings = action.payload.map(el => new DtoAccountSettings(el))
        },
        [AccountAction.fetchAccountStatistics.fulfilled]: (state, action) => {
            state.accountStatistics = action.payload.map(el => new DtoAccountStatistic(el))
        },
        [AccountAction.fetchAccountStationStatistics.fulfilled]: (state, action) => {
            state.accountStationStatistics = action.payload.map(el => new DtoAccountStationStatistic(el))
        },
        [AccountAction.fetchAccountUsageStatistics.fulfilled]: (state, action) => {
            state.accountUsageStatistics = action.payload.map(el => new DtoAccountUsageStatistic(el))
        },
        [AccountAction.fetchAccountStations.fulfilled]: (state, action) => {
            state.accountStations = action.payload.map(el => new DtoAccountStation(el))
        },
        [AccountAction.fetchContributorAccount.fulfilled]: (state, action) => {
            state.accountContributor = action.payload
        },
    },
})

export const AccountActionConstant = store.actions
export default store.reducer