import { WAIT } from './WaitConstants'

const WaitAction = {
    wait(display) {
        return {
            type: WAIT,
            wait: display,
        }
    },
    waitStart() {
        return WaitAction.wait(true)
    },
    waitStop() {
        return WaitAction.wait(false)
    },
}

export default WaitAction
