import React, { useEffect } from 'react'
import { push } from 'connected-react-router'
import { shallowEqual, useDispatch, useSelector } from 'react-redux'
import Menu from './Menu'
import NavBar from './NavBar'
import useApplicationSetting from 'utils/customHook/useApplicationSetting'
import CmsAction from 'pages/cms/actions/CmsAction'

const App = () => {
    const {
        cmsTitle,
    } = useSelector(store => ({
        cmsTitle: store.CmsReducer.cmsTitle,
    }), shallowEqual)

    const cmsTitleId = useApplicationSetting('cmsTitre', setting => setting ? parseInt(setting) : undefined)

    const dispatch = useDispatch()

    useEffect(() => {
        if (window.location.href.split('#')[1] === '/') {
            dispatch(push('/home'))
            window.scrollTo(0, 0)
        }
    }, [])

    useEffect(() => {
        if (cmsTitleId) {
            dispatch(CmsAction.fetchCmsTitle(cmsTitleId))
        }
    }, [cmsTitleId])

    const haveCmsTitle = !!cmsTitle?.id

    return (
        <div style={{ marginBottom: haveCmsTitle ? '128px' : '98px' }}>
            <Menu />
            <NavBar />
        </div>
    )
}

export default App
