module.exports = {
    BLUE: 'BLUE',
    WHITE: 'WHITE',
    YELLOW: 'YELLOW',
    GREEN: 'GREEN',
    RED: 'RED',
    ORANGE: 'ORANGE',
    GREY: 'GREY',
    DARKGREEN: 'DARKGREEN',
    LIGHTBLUE: 'LIGHTBLUE',
    DARKBLUE: 'DARKBLUE',
    LIGHTGREY: 'LIGHTGREY',
    BROWN: 'BROWN',
    BLUE_OBSERVATIONS: 'BLUE_OBSERVATIONS',
    YELLOW_OBSERVATIONS: 'YELLOW_OBSERVATIONS',
    ORANGE_OBSERVATIONS: 'ORANGE_OBSERVATIONS',
    RED_OBSERVATIONS: 'RED_OBSERVATIONS',
    GREY_OBSERVATIONS: 'GREY_OBSERVATIONS',
    COLOR_TAB: {
        1: ['BLUE', 'RED'],
        2: ['BLUE', 'ORANGE', 'RED'],
        3: ['BLUE', 'YELLOW', 'ORANGE', 'RED'],
        4: ['BLUE', 'GREEN', 'YELLOW', 'ORANGE', 'RED'],
    },
}
