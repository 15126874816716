/* eslint-disable consistent-return */
import ReactECharts from 'echarts-for-react'
import * as echarts from 'echarts/lib/echarts'
import { countBy, findIndex, groupBy, maxBy, minBy, orderBy, round } from 'lodash'
import moment from 'moment'
import PropTypes from 'prop-types'
import React, { useRef, useState } from 'react'
import i18n from 'simple-react-i18n'
import { getDate } from 'utils/DateUtil'
import { hasValue } from 'utils/NumberUtil'
import { chartLine, chartSymbol, exportPictureIcon, histogramIcon, legendSymbol, lineIcon, logIcon, stackIcon, thresholdIcon } from './EChartUtils'
import { AUTO, MONTH, WEEK, YEAR, THRESHOLD, AREA, NOTHING, getIntervalFormatter } from './ChartConstant'

const DEFAULT_Y_AXIS = 'defaultYAxis'
const LINE = 'line'
const BAR = 'bar'
const ALL = 'all'

const REGEX_BACKGROUND_COLOR = /background-color:[#\w]+;/

// Voir avec Damien pour faire des modifs si elles ne sont pas urgentes
// Le composant est complètement générique et est utilisé à plusieurs endroit, normalement il n'y a plus besoin de le modifier
// Toutes les valeurs par défaut ne doivent pas être modifier car cela peut entrainer des problèmes aux endroits ou le composant est déjà utilisé
const QualityChart = ({
    data = [],
    thresholds = [],
    title = '',
    exportName,
    yAxis = {},
    xAxis = {},
    graphHeight = 400,
    componentHeight = 500,
    roundValue = 3,
    areaColor = [],
    markLines = [],

    defaultDisplayMarker = true,
    defaultDisplayLine = true,
    defaultStateThreshold = THRESHOLD,

    withToolTypeLine = false,
    withToolTypeBar = false,
    withToolTypeStack = false,
    withToolLog = false,
    withToolThreshold = false,
    withToolLegend = false,
    withToolMarker = false,
    withToolLine = false,
    withArea = false,
    withDataZoom = false,

    dataZoomPosition = { },
    gridPosition = { },
    toolboxPosition = { },
    legendPosition = { },

    // setColors = () => {},
}) => {
    // use on mouse event
    let echartRef = useRef(undefined)
    let displayToolbox = useRef(false)

    const [displayLog, setDisplayLog] = useState(false)
    const [displayMarker, setDisplayMarker] = useState(defaultDisplayMarker)
    const [displayLegend, setDisplayLegend] = useState(true)
    const [displayLine, setDisplayLine] = useState(defaultDisplayLine)
    const [stateThreshold, setStateThreshold] = useState(defaultStateThreshold)
    const [chartType, setChartType] = useState(LINE)
    const [stack, setStack] = useState()

    const defaultTimestamp = moment().valueOf()

    const thresholdsWithValue = thresholds.filter(({ value }) => hasValue(value))

    const allData = data.flatMap(d => d.dataList)
    const thresholdGroupByYAxis = groupBy(thresholdsWithValue, t => t.idYAxis ?? DEFAULT_Y_AXIS)

    const minDate = moment(minBy(allData, 'date')?.date ?? defaultTimestamp)
    const maxDate = moment(maxBy(allData, 'date')?.date ?? defaultTimestamp)
    const chartMinDate = minDate.startOf(xAxis.xAxisSpace ?? YEAR)
    const chartMaxDate = maxDate.endOf(xAxis.xAxisSpace ?? YEAR)

    const {
        formatter,
        interval,
    } = getIntervalFormatter(chartMinDate, chartMaxDate, xAxis.maxXAxisSpace)
    // } = getAxisLabelInterval(chartMaxDate, chartMinDate)

    const {
        left: nbYAxisLeft = 0,
        right: nbYAxisRight = 0,
    } = countBy(yAxis, axis => axis.position ?? 'left')

    // use to offset the axis on both side
    let indexLeft = -1
    let indexRight = -1
    const yAxisList = yAxis.map(({ position = 'left', name, inverse, min, max, idYAxis = DEFAULT_Y_AXIS, nameGap = 50, showSplitLine = true }) => {
        if (position === 'left') {
            indexLeft++
        }
        if (position === 'right') {
            indexRight++
        }
        return {
            type: displayLog ? 'log' : 'value',
            position,
            nameLocation: 'center',
            nameGap,
            offset: (position === 'left' ? indexLeft : indexRight) * 70,
            boundaryGap: true,
            name,
            idYAxis, // not from echart, use to link serie to yAxis
            inverse,
            splitLine: {
                show: showSplitLine,
            },
            max: value => {
                if (displayLog) {
                    return
                }
                return max ?? round(orderBy(thresholdGroupByYAxis[idYAxis], 'value').find(t => t.value > value.max)?.value || value.max, 6)
            },
            min: value => {
                if (displayLog) {
                    return
                }
                if (chartType === BAR || stack) {
                    return 0
                }
                return min ?? round(orderBy(thresholdGroupByYAxis[idYAxis], 'value', 'desc').find(t => t.value < value.min)?.value || value.min, 6)
            },
        }
    })

    const series = data.map(({ name, unit, dataList, color, type, lineStyle, idYAxis = DEFAULT_Y_AXIS, showSymbol = true, connectNulls = true, uniqId }) => {
        const dataListFiltered = displayLog ? dataList.filter(d => d.value > 0) : dataList
        const dataOrdered = orderBy(dataListFiltered, 'date').map(({ date, value, marker, color: markerColor, unit: valueUnit }) => ({
            value: [date, value],
            symbol: displayMarker ? (marker || 'circle') : 'none',
            itemStyle: chartType !== BAR ? {
                color: markerColor,
            } : undefined,
            unit: valueUnit ?? unit,
            // lineColor: color, // used for tooltip
        }))
        return {
            uniqId, // used to send the color to the parent
            type: type || chartType,
            data: dataOrdered,
            name,
            connectNulls,
            showSymbol,
            // showAllSymbol: true,
            symbolSize: 6,
            lineStyle: {
                opacity: displayLine ? undefined : 0,
                type: lineStyle,
            },
            color,
            stack,
            yAxisIndex: findIndex(yAxisList, axis => axis.idYAxis === idYAxis),
        }
    })

    const markLineSerie = markLines.length ? [{
        type: LINE,
        color: 'black',
        markLine: {
            symbol: 'none',
            // symbol: ['none', 'arrow'],
            data: markLines.map(ml => ({
                yAxis: ml.value,
                label: {
                    formatter: ml.label,
                    position: 'middle',
                },
            })),
            silent: true,
        },
    }] : []

    const thresholdsSeries = stateThreshold === THRESHOLD ? thresholdsWithValue.map(({ value, name = i18n.threshold, idYAxis = DEFAULT_Y_AXIS, color = 'black', labelColor = color }) => ({
        type: LINE,
        color,
        yAxisIndex: findIndex(yAxisList, axis => axis.idYAxis === idYAxis),
        markLine: {
            symbol: 'none',
            // symbol: ['none', 'arrow'],
            data: [{
                label: {
                    position: 'middle',
                    formatter: d => `${name}: ${d.value}`,
                    color: labelColor,
                },
                yAxis: value,
            }],
            lineStyle: {
                type: 'dashed',
                // opacity: stateThreshold === THRESHOLD ? 1 : 0,
            },
            silent: true,
        },
    })) : []

    const markArea = stateThreshold === AREA && yAxisList.length === 1 && thresholdsWithValue.length ? [{
        type: LINE,
        markArea: {
            data: [
                ...thresholdsWithValue.map(({ value }, i) => ({
                    0: {
                        yAxis: value,
                        itemStyle: {
                            color: areaColor[i] || 'white',
                            opacity: 0.2,
                        },
                    },
                    1: { yAxis: i === 0 ? 0 : thresholds[i - 1].value },
                })),
                {
                    0: {
                        yAxis: thresholdsWithValue[thresholdsWithValue.length-1].value,
                        itemStyle: {
                            color: areaColor[thresholdsWithValue.length] || 'white',
                            opacity: 0.2,
                        },
                    },
                    // 1: { yAxis: max(Object.keys(groupMax).map(k => groupMax[k])) },
                    1: { yAxis: 'min' }, // max value :shrug:
                },
            ],
        },
    }] : []

    // these functions are called in the option object as well as in the setOption in the mouseEvent
    const getLegend = () => ({
        top: '5%',
        left: '2%',
        // adding boolan give number
        right: displayToolbox.current ? `${30 * (2 + withToolTypeLine + withToolTypeBar + withToolTypeStack + withToolLog + withToolThreshold + withToolLegend + withToolMarker + withToolLine) + (parseInt(toolboxPosition.right?.replace('px', '')) || 35)}px` : '2%',
        ...legendPosition,
        type: 'scroll',
        show: displayLegend,
    })

    const getToolbox = () => ({
        top: '4%',
        right: '35px',
        ...toolboxPosition,
        showTitle: false,
        itemSize: 18,
        tooltip: {
            position: 'bottom',
            show: true,
            formatter: (param) => param.title,
        },
        feature: {
            myToolLine: {
                show: displayToolbox.current && withToolTypeLine,
                title: i18n.lines,
                icon: lineIcon,
                onclick: () => {
                    setStack(undefined)
                    setChartType(LINE)
                },
                iconStyle: {
                    borderColor: chartType === LINE ? '#4d93c9' : '#5c5c5c',
                },
            },
            myToolBar: {
                show: displayToolbox.current && withToolTypeBar,
                title: i18n.histogram,
                icon: histogramIcon,
                onclick: () => {
                    setStack(undefined)
                    setChartType(BAR)
                    setDisplayLog(false)
                },
                iconStyle: {
                    borderColor: chartType === BAR && !stack ? '#4d93c9' : '#5c5c5c',
                },
            },
            myToolStack: {
                show: displayToolbox.current && withToolTypeStack,
                title: i18n.stack,
                icon: stackIcon,
                onclick: () => {
                    setStack(ALL)
                    setChartType(BAR)
                    setDisplayLog(false)
                },
                iconStyle: {
                    borderColor: chartType === BAR && stack === ALL ? '#4d93c9' : '#5c5c5c',
                },
            },
            myToolLog: {
                show: displayToolbox.current && withToolLog,
                title: i18n.logarithm,
                icon: logIcon,
                onclick: () => {
                    setStack(undefined)
                    setChartType(LINE)
                    setDisplayLog(prevDisplayLog => !prevDisplayLog)
                },
                iconStyle: {
                    borderColor: displayLog ? '#4d93c9' : '#5c5c5c',
                },
            },
            myToolThreshold: {
                show: displayToolbox.current && withToolThreshold && !!thresholdsWithValue.length,
                title: withArea ? i18n.thresholdAreaNothing : i18n.toggleThreshold,
                icon: thresholdIcon,
                onclick: () => setStateThreshold(prevStateThreshold => {
                    if (withArea) {
                        return (prevStateThreshold + 1) % 3
                    }
                    return prevStateThreshold === THRESHOLD ? NOTHING : THRESHOLD
                }),
                iconStyle: {
                    borderColor: stateThreshold !== NOTHING ? '#4d93c9' : '#5c5c5c',
                },
            },
            myToolToggleLegend: {
                show: displayToolbox.current && withToolLegend,
                title: i18n.toggleLegend,
                icon: legendSymbol,
                onclick: () => setDisplayLegend(prevHiddenLegend => !prevHiddenLegend),
                iconStyle: {
                    borderColor: displayLegend ? '#4d93c9' : '#5c5c5c',
                },
            },
            myToolToggleMarker: {
                show: displayToolbox.current && withToolMarker,
                title: i18n.toggleMarker,
                icon: chartSymbol,
                onclick: () => setDisplayMarker(prevHiddenMarker => !prevHiddenMarker),
                iconStyle: {
                    borderColor: displayMarker ? '#4d93c9' : '#5c5c5c',
                },
            },
            myToolToggleLine: {
                show: displayToolbox.current && withToolLine,
                title: i18n.toggleLine,
                icon: chartLine,
                onclick: () => setDisplayLine(prevHiddenLine => !prevHiddenLine),
                iconStyle: {
                    borderColor: displayLine ? '#4d93c9' : '#5c5c5c',
                },
            },
            saveAsImage: {
                show: displayToolbox.current,
                title: i18n.pictureExport,
                icon: exportPictureIcon,
                name: exportName || title,
            },
        },
    })

    const options = {
        title: {
            text: title,
            x: 'center',
            top: '1%',
        },
        series: [...thresholdsSeries, ...markArea, ...markLineSerie, ...series],
        legend: getLegend(),
        xAxis: [{
            type: 'time',
            boundaryGap: true,
            axisLabel: {
                formatter,
                rotate: 50,
            },
            splitLine: {
                show: xAxis?.showSplitLine ?? true,
            },
            interval,
            min: value => {
                const date = moment(value.min).startOf('year')
                return date.valueOf()
            },
            max: value => {
                const date = moment(value.max).endOf('year')
                return date.valueOf()
            },
        }],
        yAxis: yAxisList,
        tooltip: {
            show: true,
            trigger: 'axis',
            axisPointer: {
                type: 'shadow',
                snap: true,
            },
            formatter: params => {
                const date = getDate(params[0].axisValue)
                const listLine = params.map(({ marker, seriesIndex, seriesName, value: [, result], data: { unit = '', lineColor } }) => {
                    const color = lineColor ?? echartRef.current?.getEchartsInstance().getVisual({
                        seriesIndex,
                    }, 'color')
                    const newMarker = marker.replace(REGEX_BACKGROUND_COLOR, `background-color:${color};`)
                    return `<br/>${newMarker} ${seriesName}: ${result && round(result, roundValue)} ${unit}`
                })
                return `${date}${listLine.join('')}`
            },
        },
        grid: {
            top: '14%',
            bottom: '80px',
            left: `${37 * nbYAxisLeft + 20}px`,
            right: `${37 * nbYAxisRight + 20}px`,
            ...gridPosition,
            containLabel: true,
            height: graphHeight,
        },
        dataZoom: withDataZoom ? [{
            bottom: '10px',
            ...dataZoomPosition,
            handleSize: '80%',
            filterMode: 'none',
            xAxisIndex: 0,
            handleStyle: {
                color: '#fff',
                shadowBlur: 3,
                shadowColor: 'rgba(0, 0, 0, 0.6)',
                shadowOffsetX: 2,
                shadowOffsetY: 2,
            },
        }] : [],
        toolbox: getToolbox(),
    }
    return (
        <div
            onMouseOver={() => {
                displayToolbox.current = true
                echartRef.current?.getEchartsInstance().setOption({
                    legend: getLegend(),
                    toolbox: getToolbox(),
                })
            }}
            onMouseOut={() => {
                displayToolbox.current = false
                echartRef.current?.getEchartsInstance().setOption({
                    legend: getLegend(),
                    toolbox: getToolbox(),
                })
            }}
        >
            <ReactECharts
                echarts={echarts}
                option={options}
                notMerge={true}
                lazyUpdate={true}
                className={'row no-margin'}
                style={{ height: componentHeight }}
                ref={e => {
                    echartRef.current = e
                }}
            />
        </div>
    )
}

QualityChart.propTypes = {
    data: PropTypes.arrayOf(PropTypes.shape({
        name: PropTypes.string.isRequired,
        unit: PropTypes.string,
        color: PropTypes.string,
        idYAxis: PropTypes.string,
        type: PropTypes.string,
        lineStyle: PropTypes.string,
        showSymbol: PropTypes.bool,
        connectNulls: PropTypes.bool,
        dataList: PropTypes.arrayOf(PropTypes.shape({
            date: PropTypes.number,
            value: PropTypes.number,
            marker: PropTypes.string,
            color: PropTypes.string,
            unit: PropTypes.string,
        })).isRequired,
    })).isRequired,
    thresholds: PropTypes.arrayOf(PropTypes.shape({
        value: PropTypes.number,
        name: PropTypes.string,
        idYAxis: PropTypes.string,
        color: PropTypes.string,
    })),
    title: PropTypes.string,
    exportName: PropTypes.string,
    xAxis: PropTypes.shape({
        showSplitLine: PropTypes.bool,
        xAxisSpace: PropTypes.oneOf([YEAR, MONTH, WEEK, AUTO]),
        maxXAxisSpace: PropTypes.oneOf([YEAR, MONTH, WEEK, AUTO]),
    }),
    yAxis: PropTypes.arrayOf(PropTypes.shape({
        position: PropTypes.string,
        name: PropTypes.string,
        inverse: PropTypes.bool,
        idYAxis: PropTypes.string,
        min: PropTypes.number,
        max: PropTypes.number,
        nameGap: PropTypes.number,
        showSplitLine: PropTypes.bool,
    })),
    graphHeight: PropTypes.number,
    componentHeight: PropTypes.number,
    roundValue: PropTypes.number,
    areaColor: PropTypes.arrayOf(PropTypes.string), // 1 more than thresholds
    markLines: PropTypes.arrayOf(PropTypes.shape({
        value: PropTypes.number,
        label: PropTypes.string,
    })),

    defaultDisplayMarker: PropTypes.bool,
    defaultDisplayLine: PropTypes.bool,
    defaultStateThreshold: PropTypes.oneOf([THRESHOLD, AREA, NOTHING]),

    withToolTypeLine: PropTypes.bool,
    withToolTypeBar: PropTypes.bool,
    withToolTypeStack: PropTypes.bool,
    withToolLog: PropTypes.bool,
    withToolThreshold: PropTypes.bool,
    withToolLegend: PropTypes.bool,
    withToolMarker: PropTypes.bool,
    withToolLine: PropTypes.bool,
    withArea: PropTypes.bool,
    withDataZoom: PropTypes.bool,

    dataZoomPosition: PropTypes.shape({
        bottom: PropTypes.string,
        top: PropTypes.string,
    }),
    gridPosition: PropTypes.shape({
        bottom: PropTypes.string,
        top: PropTypes.string,
    }),
    toolboxPosition: PropTypes.shape({
        bottom: PropTypes.string,
        top: PropTypes.string,
        right: PropTypes.string,
    }),
    legendPosition: PropTypes.shape({
        bottom: PropTypes.string,
        top: PropTypes.string,
    }),
}

export default QualityChart