/* eslint-disable consistent-return */
import moment from 'moment'
import { capitalizeFirstLetter } from './StringUtil'
import { isNull, isUndefined } from 'lodash'

const formatDate = (timestamp) => {
    if (timestamp) {
        return new Date(timestamp).toLocaleDateString()
    }
    return ''
}

const getBeginingOfTheYear = year => {
    if (isUndefined(year) || isNull(year)) {
        return
    }
    return moment().year(year).month(0).date(1).valueOf()
}

const getEndOfTheYear = year => {
    if (isUndefined(year) || isNull(year)) {
        return
    }
    return moment().year(year).month(11).date(31).valueOf()
}

const getDate = (date) => {
    if (date) {
        return moment(date).format('DD/MM/YYYY')
    }
    return ''
}
const getHour = (hour) => {
    if (hour) {
        return moment(hour).format('HH:mm:ss')
    }
    return ''
}

const getDateObj = (date) => {
    if (date) {
        return moment(`${getDate(date)}`, 'DD/MM/YYYY').format()
    }
    return ''
}

const getHourObj = (hour, format = '') => {
    if (hour) {
        return moment(`${getHour(hour)}`, 'HH:mm:ss').format(format)
    }
    return ''
}

const getFullDate = (date) => {
    if (date) {
        return moment(date).format('DD/MM/YYYY - HH:mm:ss')
    }
    return ''
}

const getFullDateMini = (date) => {
    if (date) {
        return moment(date).format('DD/MM/YYYY HH:mm')
    }
    return ''
}

const getDateAndHourObj = (date, hour, format = '') => {
    if (date && hour) {
        return moment(`${getDate(date)} ${getHour(hour)}`, 'DD/MM/YYYY HH:mm:ss').format(format)
    }
    return ''
}

const getDateAndHour = (date, hour) => {
    if (date && hour) {
        return getDateAndHourObj(date, hour, 'DD/MM/YYYY HH:mm')
    }
    return ''
}

const getDateWithHour = (date, hour) => {
    if (date && hour) {
        const mhour = moment(hour)
        return moment(date).hour(mhour.hour()).minute(mhour.minute()).second(mhour.second())
    }
    if (date) {
        return moment(date)
    }
    return null
}

const getHourAndMinute = (hour) => {
    if (hour) {
        return moment(hour).format('HH:mm')
    }
    return ''
}
const getMiniDate = (date) => {
    if (date) {
        return moment(date).format('DD/MM/YY')
    }
    return ''
}
const getYearDate = (date) => {
    if (date) {
        return moment(date).format('YYYY')
    }
    return ''
}

const getMonthYear = timestamp => getDate(timestamp, 'MM/YYYY')
const getWeekYear = timestamp => getDate(timestamp, 'ww/YYYY')

const getDateExport = () => {
    return moment().format('YYYYMMDDHHmmss')
}

const getYear = (date) => {
    return moment(date).year()
}

const getYearOrString = (date, string) => {
    return getYear(date) === getYear(new Date()) ? string : getYear(date)
}

const getDayDiff = (date1, date2) => {
    return moment(date1).diff(date2, 'days')
}

const getDay = (dayNumber) => {
    return capitalizeFirstLetter(moment().isoWeekday(dayNumber).format('dddd'))
}

const enumerateBetweenDates = (start, end, format = 'days') => {
    return Array.from(moment().range(start, end).by(format))
}

const sortByDate = (a, b) => {
    const updateDateA = a.updateDate
    const updateDateB = b.updateDate
    let comparison = 0
    if (updateDateA && updateDateB) {
        if (updateDateA > updateDateB) {
            comparison = 1
        } else if (updateDateA < updateDateB) {
            comparison = -1
        }
        return comparison
    }
    return comparison
}

export {
    formatDate,
    getDate,
    getMiniDate,
    getDayDiff,
    getYearDate,
    getYear,
    getHour,
    getDateExport,
    getYearOrString,
    getDateObj,
    getFullDate,
    getFullDateMini,
    getDateAndHourObj,
    getDateWithHour,
    getDateAndHour,
    getHourAndMinute,
    getDay,
    getHourObj,
    enumerateBetweenDates,
    sortByDate,
    getBeginingOfTheYear,
    getEndOfTheYear,
    getMonthYear,
    getWeekYear,
}
