export default class DtoPiezometerLight {
    constructor(obj = []) {
        this.id = obj[0]
        this.code = obj[1]
        this.name = obj[2]
        this.x = obj[3]
        this.y = obj[4]
        this.projection = obj[5]
        this.townCode = obj[6]
        this.typeName = 'piezometry'
    }
}
