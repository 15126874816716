import React, { Component } from 'react'
import { connect } from 'react-redux'
import i18n from 'simple-react-i18n'
import PropTypes from 'prop-types'
import { push } from 'connected-react-router'
import { Grid, Icon, TextField } from '@mui/material'
import HomeAction from '../actions/HomeAction'
import { getLoginPassword } from '../../../utils/ActionUtils'
import { withStyles } from '@mui/styles'
import { InputRow } from 'components/styled/Inputs'
import { PrimaryButton } from 'components/styled/Buttons'
import { cmsPath } from 'conf/basepath'
import DtoUser from 'pages/account/dto/DtoUser'
import ToastrAction from 'toastr/actions/ToastrAction'
import AccountAction from 'pages/account/actions/AccountAction'
import { KEY_RECAPTCHA, SIEAU_TOKEN } from '../constants/HomeConstants'
import DialogCGU from './DialogCGU'
import DialogUpdatePassword from 'pages/account/components/DialogUpdatePassword'
import DtoApplicationSettings from '../dto/DtoApplicationSettings'
import DtoCMSEvent from 'pages/cms/dto/DtoCMSEvent'
import bg1 from 'ressources/pictures/bg1.jpg'
import aquasysLogo from 'ressources/pictures/logos/AquasysPetitLogo.png'
import ReCAPTCHA from 'react-google-recaptcha'
import { getSettingInt } from 'utils/FormUtils'
import CmsAction from 'pages/cms/actions/CmsAction'
import { aquasysPath } from 'conf/SieauConstants'
import moment from 'moment'
import ProgressBar from 'components/progress/ProgressBar'
import { secondaryBlue } from 'utils/constants/ColorTheme'
import { mainBlack } from 'pages/online/components/styled/theme'
import LoginMenu from './LoginMenu'

const styles = () => ({
    gridTop: {
        backgroundSize: 'cover',
        height: '30%',
    },
    gridInTop: {
        backgroundColor: 'white',
        padding: '20px 20px 0 20px',
        textAlign: 'center',
        width: '100%',
    },
    container: {
        overflowY: 'auto !important',
        height: '70%',
        padding: 20,
    },
    logo: {
        height: 60,
        width: 'auto',
    },
    clickable: {
        cursor: 'pointer',
    },
    img: {
        cursor: 'pointer',
        height: '5rem',
        width: 'auto',
        padding: '1rem 0.75rem',
    },
})

const TAB_OBS = 1
const TAB_CONTACT = 2
const TAB_INFOS = 3
const TAB_LOGIN = 4
const TAB_PASSWORD = 5

class Login extends Component {
    constructor(props) {
        super(props)
        const credentials = getLoginPassword() || ['', '']
        this.state = {
            login: credentials[0],
            password: credentials[1],
            openModal: false,
            cguLoaded: false,
            loginError: false,
            passwordError: false,
            openModalPassword: false,
            images: [],
            cmsCGUDate: 0,
            newMdp: '',
            mdpConfirmation: '',
            settingsLoaded: false,
            backgroundUrl: '',
            loading: false,
            tab: TAB_LOGIN,
            disabled: true,
            showRetrievePasswordMessage: false,
            cmsHome: undefined,
            cmsInfos: undefined,
            cmsPartners: [],
        }
    }

    setCMSHome = (applicationSettings) => {
        const cmsHomeId = getSettingInt(applicationSettings, 'cmsAccueil')
        if (cmsHomeId) {
            this.props.fetchCMSEvent(cmsHomeId).then(cmsHome => this.setState({ cmsHome }))
        }
    }

    setCMSInfos = (applicationSettings) => {
        const cmsInfosId = getSettingInt(applicationSettings, 'cmsInformations')
        if (cmsInfosId) {
            this.props.fetchCMSEvent(cmsInfosId).then(cmsInfos => this.setState({ cmsInfos }))
        }
    }

    setCmsPartners = (applicationSettings) => {
        const categoryId = getSettingInt(applicationSettings, 'categorieCmsPartenaires')
        if (categoryId) {
            this.props.fetchCmsByCateg(categoryId).then(cmsEvents => this.setState({ cmsPartners: cmsEvents.map(c => new DtoCMSEvent(c)).filter(c => c?.document[0]) }))
        }
    }

    componentDidMount() {
        this.props.fetchApplicationSettings().then(() => {
            const { applicationSettings } = this.props
            this.setCMSHome(applicationSettings)
            this.setCMSInfos(applicationSettings)
            this.setCmsPartners(applicationSettings)
            this.getCGU()
            const applicationName = applicationSettings.find(({ parameter }) => parameter === 'applicationName') || {}
            if (applicationName.value) {
                document.title = applicationName.value
            }
            this.setState({ settingsLoaded: true })
        })
    }

    getCGU = () => {
        this.props.getAllCGU().then(() => {
            const { cgu } = this.props
            this.setState({
                cmsCGUDate: cgu.lastDate,
                cguLoaded: true,
            })
        })
    }

    toggleModalPassword = () => {
        const { openModalPassword } = this.state
        this.setState({ openModalPassword: !openModalPassword })
    }

    toggleDialogCGU = () => {
        const { openModal } = this.state
        this.setState({ openModal: !openModal })
    }

    onUpdatePassword = () => {
        const { newMdp, mdpConfirmation, login } = this.state
        const { accountUser, applicationSettings } = this.props
        if (
            newMdp !== null &&
            newMdp.length &&
            mdpConfirmation !== null &&
            mdpConfirmation.length
        ) {
            if (newMdp === mdpConfirmation) {
                const regex = RegExp(applicationSettings.find((s) => s.parameter === 'passwordPolicy').value)
                const regexHelp = applicationSettings.find((s) => s.parameter === 'securityPasswordDescription').value
                if (regex.test(newMdp)) {
                    if (newMdp !== login) {
                        this.props.updatePassword(newMdp, accountUser.resetPassword).then(() => {
                            this.props.login({ login, newMdp }).then(() => {
                                this.toggleModalPassword()
                                this.checkCGU()
                            })
                        })
                    } else {
                        ToastrAction.error(i18n.passwordMustBeDifferent, true)
                    }
                } else {
                    ToastrAction.error(regexHelp, true)
                }
            } else {
                ToastrAction.error(i18n.passwordsNotSame, true)
            }
        }
    }

    onSubmit = () => {
        const { login, password, loading } = this.state
        if (!login) {
            this.setState({ loginError: true })
        }
        if (!password) {
            this.setState({ passwordError: true })
        }
        if (password && login && !loading) {
            this.setState({ loading: true })
            this.props.login({ login, password }).then(() => {
                this.setState({ loading: false })
                this.props.fetchUser(login).then(() => {
                    const { accountUser } = this.props
                    if (!accountUser.resetPassword) {
                        this.checkCGU()
                    } else if (accountUser.resetPassword.length > 4) {
                        this.setState({ openModalPassword: true })
                    } else {
                        this.props.logout()
                        ToastrAction.warning(
                            i18n.requestChangePassword,
                            true,
                        )
                    }
                })
            })
        }
    }

    checkCGU = () => {
        const { login } = this.state
        this.props.getDateValidCGU(login).then(() => {
            const { dateValidCgu } = this.props
            const { cmsCGUDate } = this.state
            const dateUser = new Date(dateValidCgu)
            const dateCGU = new Date(cmsCGUDate)
            if (localStorage.getItem(SIEAU_TOKEN)) {
                if (dateUser.getTime() < dateCGU.getTime()) {
                    this.toggleDialogCGU()
                } else if (!dateUser.getTime()) {
                    this.toggleDialogCGU()
                } else {
                    this.props.push('/home')
                }
            }
        })
    }

    sendDateValidCGU = () => {
        const { login } = this.state
        const eventType = 'CGU'
        const module = 'CGU'
        const postDateCGU = { login, eventType, module, version: `${moment().valueOf()}` }
        this.props.sendDateCgu(postDateCGU).then(() => {
            this.toggleDialogCGU()
            this.props.push('/home')
        })
    }

    onRefuseCGU = () => {
        this.props.logout()
        this.toggleDialogCGU()
    }

    openWebSite = (link) => {
        if (link) {
            window.open(link, '_blank')
        }
    }

    getLogoPartenaires = () => {
        const { cmsPartners } = this.state
        const { classes } = this.props
        const images = cmsPartners.map(c => ({
            link: c.link,
            docName: c.document[0].name,
            name: c.title,
        }))
        return [(
            <Grid item>
                <img
                    src={aquasysLogo}
                    alt='logo aquasys'
                    className={classes.img}
                    onClick={() => this.openWebSite(aquasysPath)}
                />
            </Grid>
        ), ...images.map((i, index) => {
            return (
                <Grid item key={index}>
                    <img
                        src={`${cmsPath}${i.docName}`}
                        alt={`logo partenaire ${i.name}`}
                        className={classes.img}
                        onClick={() => this.openWebSite(i.link)}
                    />
                </Grid>
            )
        })]
    }

    onKeyDown = (e) => {
        if (e.key === 'Enter') {
            this.onSubmit()
        }
    }

    onReset = () => {
        const { login } = this.state
        this.props.resetPassword(login).then(() => this.setState({ showRetrievePasswordMessage: true }))
    }

    isValidEmail = (email) => (!!/^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/.test(email))

    onSendEmail = () => {
        const { name, mail, text } = this.state
        const { applicationSettings } = this.props
        const mailSupport = getSettingInt(applicationSettings, 'supportMailAddress') || 'support@aquasys.fr'
        if (name && mail && text) {
            if (!this.isValidEmail(mail)) {
                ToastrAction.error(i18n.invalidEmail)
            } else {
                this.setState({ loading: true })
                const email = {
                    to: mailSupport,
                    from: mailSupport,
                    subject: `Contact de :  ${name} (${mail})`,
                    message: `Contenu du message : ${text}\n${mail}`,
                }
                this.props.sendMailToAdmin(email).then(() => {
                    this.setState({ loading: false, name: '', mail: '', text: '' })
                })
            }
        } else {
            ToastrAction.error(i18n.fillAllFields)
        }
    }

    getTab = (cmsHome, cmsInfos) => {
        const { login, loginError, password, name, mail, text, passwordError, nameError, emailError, loading, tab, disabled, showRetrievePasswordMessage } = this.state

        switch (tab) {
            case TAB_OBS: default:
                return (
                    <Grid item xs={12}>
                        <div style={{ fontSize: 26, fontWeight: 'bold', paddingBottom: 20, textAlign: 'center' }}>{cmsHome?.title || ''}</div>
                        <div style={{ fontSize: 15, paddingTop: 15, paddingBottom: 20, maxHeight: '50vh', overflowY: 'auto', overflowX: 'hidden' }} dangerouslySetInnerHTML={{ __html: cmsHome?.comment || '' }} />
                    </Grid>
                )
            case TAB_CONTACT:
                return (
                    <Grid container item xs={12} justifyContent='center'>
                        <Grid item xs={12} sx={{ textAlign: 'center', fontWeight: 'bold', paddingBottom: '1.25rem', fontSize: '1.75rem' }}>
                            {i18n.contactUs}
                        </Grid>
                        {!loading ? (
                            <>
                                <Grid container item xs={12} alignItems='center' sx={{ marginBottom: '1.5rem' }}>
                                    <Grid item xs={1} />
                                    <Grid item xs={1}>
                                        <Icon>person</Icon>
                                    </Grid>
                                    <Grid item xs={8}>
                                        <InputRow
                                            id='name'
                                            label={i18n.name}
                                            type='text'
                                            value={name}
                                            onChange={(e) => this.setState({ name: e.target.value, nameError: false })}
                                            error={nameError}
                                            helperText={nameError && i18n.fillField}
                                            onKeyDown={(e) => this.onKeyDown(e)}
                                        />
                                    </Grid>
                                    <Grid item xs={2} />
                                </Grid>
                                <Grid container item xs={12} alignItems='center' sx={{ marginBottom: '1.5rem' }}>
                                    <Grid item xs={1} />
                                    <Grid item xs={1}>
                                        <Icon>email</Icon>
                                    </Grid>
                                    <Grid item xs={8}>
                                        <InputRow
                                            id='email'
                                            label={i18n.email}
                                            type='text'
                                            value={mail}
                                            onChange={(e) => this.setState({ mail: e.target.value, emailError: false })}
                                            variant='outlined'
                                            error={emailError}
                                            helperText={emailError && i18n.fillField}
                                            onKeyDown={(e) => this.onKeyDown(e)}
                                        />
                                    </Grid>
                                    <Grid item xs={2} />
                                </Grid>
                                <Grid container item xs={12} justifyContent='center' alignItems='center' sx={{ marginBottom: '1.5rem' }}>
                                    <Grid item xs={8}>
                                        <TextField
                                            id='message'
                                            color='primary'
                                            label={i18n.yourMessage}
                                            type='text'
                                            value={text}
                                            onChange={(e) => this.setState({ text: e.target.value, textError: false })}
                                            variant='outlined'
                                            multiline
                                            sx={{ width: '100%', lineHeight: '15px' }}
                                            rows={4}
                                        />
                                    </Grid>
                                </Grid>
                                <Grid container item xs={12} justifyContent='center' sx={{ marginBottom: '1.5rem' }}>
                                    <Grid item>
                                        <ReCAPTCHA
                                            sitekey={KEY_RECAPTCHA}
                                            onChange={() => this.setState({ disabled: false })}
                                        />
                                    </Grid>
                                </Grid>
                                <Grid item xs={8}>
                                    <PrimaryButton onClick={this.onSendEmail} disabled={disabled}>
                                        {i18n.sendMessage}
                                    </PrimaryButton>
                                </Grid>
                            </>
                        ) : <ProgressBar indeterminate />}
                    </Grid>
                )
            case TAB_INFOS:
                return (
                    <Grid item xs={12}>
                        <div style={{ fontSize: 26, fontWeight: 'bold', paddingBottom: 20, textAlign: 'center' }}>{cmsInfos?.title || ''}</div>
                        <div style={{ fontSize: 15, paddingTop: 15, paddingBottom: 20, maxHeight: '50vh', overflowY: 'auto', overflowX: 'hidden' }} dangerouslySetInnerHTML={{ __html: cmsInfos?.comment || '' }} />
                    </Grid>
                )
            case TAB_LOGIN:
                return (
                    <Grid container item xs={12} justifyContent='center'>
                        <Grid item xs={12} sx={{ textAlign: 'center', fontWeight: 'bold', paddingBottom: '1.25rem', fontSize: '1.75rem' }}>
                            {i18n.toLogin}
                        </Grid>
                        {!loading ? (
                            <>
                                <Grid container item xs={12} alignItems='center' sx={{ marginBottom: '1.5rem' }}>
                                    <Grid item xs={1} />
                                    <Grid item xs={1}>
                                        <Icon>person</Icon>
                                    </Grid>
                                    <Grid item xs={8}>
                                        <InputRow
                                            id='login'
                                            label={i18n.identifier}
                                            type='text'
                                            value={login}
                                            onChange={(e) => this.setState({ login: e.target.value, loginError: false })}
                                            error={loginError}
                                            helperText={loginError && i18n.fillField}
                                            onKeyDown={(e) => this.onKeyDown(e)}
                                        />
                                    </Grid>
                                    <Grid item xs={2} />
                                </Grid>
                                <Grid container item xs={12} alignItems='center' sx={{ marginBottom: '1.5rem' }}>
                                    <Grid item xs={1} />
                                    <Grid item xs={1}>
                                        <Icon>lock</Icon>
                                    </Grid>
                                    <Grid item xs={8}>
                                        <InputRow
                                            id='password'
                                            label={i18n.password}
                                            type='password'
                                            value={password}
                                            onChange={(e) => this.setState({ password: e.target.value, passwordError: false })}
                                            error={passwordError}
                                            helperText={passwordError && i18n.fillField}
                                            onKeyDown={(e) => this.onKeyDown(e)}
                                        />
                                    </Grid>
                                    <Grid item xs={2} />
                                </Grid>
                                <Grid item xs={8}>
                                    <PrimaryButton onClick={this.onSubmit}>
                                        {i18n.connexion}
                                    </PrimaryButton>
                                </Grid>
                            </>
                        ) : <ProgressBar indeterminate />}
                        <Grid item xs={12} className='clickable' sx={{ marginTop: '1.5rem', textAlign: 'center', color: secondaryBlue }}>
                            <span onClick={() => this.setState({ tab: TAB_PASSWORD })}>
                                {i18n.forgotPassword}
                            </span>
                        </Grid>
                    </Grid>
                )
            case TAB_PASSWORD:
                return (
                    <Grid container item xs={12} justifyContent='center'>
                        <Grid item xs={12} sx={{ textAlign: 'center', fontWeight: 'bold', paddingBottom: '1.25rem', fontSize: '1.75rem' }}>
                            {i18n.forgotPassword}
                        </Grid>
                        <Grid item xs={10} sx={{ paddingBottom: '1.25rem', textAlign: 'center' }}>
                            {i18n.fillFormToCreateNewPassword}
                        </Grid>
                        <Grid container item xs={12} alignItems='center' sx={{ marginBottom: '1.5rem' }}>
                            <Grid item xs={1} />
                            <Grid item xs={1}>
                                <Icon>person</Icon>
                            </Grid>
                            <Grid item xs={8}>
                                <InputRow
                                    id='login'
                                    label={i18n.identifier}
                                    type='text'
                                    value={login}
                                    onChange={(e) => this.setState({ login: e.target.value, loginError: false })}
                                    variant='outlined'
                                    error={loginError}
                                    helperText={loginError && i18n.fillField}
                                    onKeyDown={(e) => this.onKeyDown(e)}
                                />
                            </Grid>
                            <Grid item xs={2} />
                        </Grid>
                        {showRetrievePasswordMessage && (
                            <Grid item xs={12} sx={{ marginBottom: '1.5rem', textAlign: 'center' }}>
                                {i18n.mailHasBeenSent}
                            </Grid>
                        )}
                        <Grid item xs={8} sx={{ marginBottom: '1.5rem' }}>
                            <PrimaryButton onClick={this.onReset}>
                                {i18n.retrievePassword}
                            </PrimaryButton>
                        </Grid>
                        <Grid item xs={12} className='clickable' sx={{ marginTop: '1.5rem', textAlign: 'center', color: secondaryBlue }}>
                            <span onClick={() => this.setState({ tab: TAB_LOGIN })}>
                                {i18n.returnToLogin}
                            </span>
                        </Grid>
                    </Grid>
                )
        }
    }

    getImageURL = (cms) => {
        if (cms && cms?.cmsDocument?.length) {
            const docName = cms.cmsDocument[0]?.name
            if (docName) {
                return cmsPath + docName
            }
            return bg1
        }
        return bg1
    }

    render() {
        const { cgu } = this.props
        const { cguLoaded, openModal, openModalPassword, settingsLoaded, tab, cmsHome, cmsInfos } = this.state

        return (
            <Grid container sx={{ height: '100vh' }} alignItems='stretch'>
                <Grid
                    item md={6} xs={0}
                    sx={{
                        backgroundImage: `url(${this.getImageURL(cmsHome)})`,
                        backgroundSize: 'cover',
                        backgroundPosition: 'center top',
                        backgroundRepeat: 'no-repeat',
                    }}
                />
                <Grid
                    container item
                    md={6} xs={12}
                    direction='column'
                    justifyContent='space-between'
                    alignItems='stretch'
                    sx={{ height: '100vh', paddingRight: '0.5rem' }}
                >
                    <LoginMenu
                        cmsHome={cmsHome}
                        cmsInfos={cmsInfos}
                        tab={tab}
                        onChangeTab={(v) => this.setState({ tab: v })}
                    />
                    <Grid container item xs={8} justifyContent='center' alignItems='center' sx={{ padding: '0.5rem 0' }}>
                        <Grid
                            container item xs={11}
                            alignItems='center'
                            sx={{ overflowY: 'auto' }}
                        >
                            {this.getTab(cmsHome, cmsInfos)}
                        </Grid>
                    </Grid>
                    <Grid container item xs={2.5} justifyContent='space-around' alignItems='center' sx={{ padding: '0.5rem 0 1rem', height: '25vh', maxHeight: '25vh', borderTop: `1px solid ${mainBlack}`, overflowY: 'auto' }}>
                        <Grid
                            container item xs={11.5}
                            justifyContent='center'
                            alignItems='center'
                        >
                            {this.getLogoPartenaires()}
                        </Grid>
                    </Grid>
                </Grid>
                {cguLoaded && (
                    <DialogCGU
                        open={openModal}
                        cgu={cgu}
                        onRefuse={this.onRefuseCGU}
                        onValidate={this.sendDateValidCGU}
                    />
                )}
                {settingsLoaded && openModalPassword && (
                    <DialogUpdatePassword
                        open={openModalPassword}
                        toggleModal={this.toggleModalPassword}
                        handleChangeNewMdp={(e) => this.setState({ newMdp: e.target.value })}
                        handleChangeConfirmation={(e) => this.setState({ mdpConfirmation: e.target.value })}
                        onSavePassword={this.onUpdatePassword}
                        firstLogin
                    />
                )}
            </Grid>
        )
    }
}

const mapDispatchToProps = {
    login: HomeAction.login,
    logout: HomeAction.logout,
    fetchApplicationSettings: HomeAction.fetchApplicationSettings,
    fetchCMSHome: HomeAction.fetchCMSHome,
    getAllCGU: HomeAction.getAllCGU,
    getCmsPartenaire: HomeAction.getCmsPartenaire,
    sendDateCgu: HomeAction.sendDateCgu,
    updatePassword: HomeAction.updatePassword,
    getDateValidCGU: HomeAction.getDateValidCGU,
    sendMailToAdmin: HomeAction.sendMailToAdmin,
    resetPassword: HomeAction.resetPassword,
    fetchCMSEvent: CmsAction.fetchCMSEvent,
    fetchCmsByCateg: CmsAction.fetchCmsByCateg,
    fetchUser: AccountAction.fetchUser,
    push,
}

const mapStateToProps = (store) => {
    return {
        dateValidCgu: store.HomeReducer.dateValidCgu,
        cms: store.HomeReducer.cms,
        cgu: store.HomeReducer.cgu,
        accountUser: store.AccountReducer.accountUser,
        applicationSettings: store.HomeReducer.applicationSettings,
    }
}

Login.propTypes = {
    push: PropTypes.func,
    sendMailToAdmin: PropTypes.func,
    login: PropTypes.func,
    classes: PropTypes.instanceOf(PropTypes.shape({})),
    applicationSettings: PropTypes.arrayOf(PropTypes.instanceOf(DtoApplicationSettings)),
    dateValidCgu: PropTypes.string,
    accountUser: PropTypes.instanceOf(DtoUser),
    cgu: PropTypes.arrayOf(PropTypes.instanceOf(DtoCMSEvent)),
    fetchApplicationSettings: PropTypes.func,
    fetchCMSHome: PropTypes.func,
    getAllCGU: PropTypes.func,
    updatePassword: PropTypes.func,
    getCmsPartenaire: PropTypes.func,
    fetchUser: PropTypes.func,
    getDateValidCGU: PropTypes.func,
    resetPassword: PropTypes.func,
    fetchCMSEvent: PropTypes.func,
    fetchCmsByCateg: PropTypes.func,
    sendDateCgu: PropTypes.func,
    logout: PropTypes.func,
}

export default connect(mapStateToProps, mapDispatchToProps)(withStyles(styles)(Login))
