/* eslint-disable react/forbid-prop-types */
import React, { forwardRef } from 'react'
import PropTypes from 'prop-types'

const Icon = forwardRef(({
    size = '',
    className = '',
    children,
    icon = '',
    onClick = () => {},
    clickable = true,
    style = {},
    'data-cy': dataCy,
}, ref) => {
    return (
        <i
            style={{ ...style }}
            className={`material-icons ${clickable ? 'clickable' : ''} ${size} ${className}`}
            onClick={onClick}
            data-cy={dataCy}
            ref={ref}
        >
            {children || icon}
        </i>
    )
})

Icon.propTypes = {
    size: PropTypes.string,
    className: PropTypes.string,
    tooltip: PropTypes.oneOfType([
        PropTypes.string,
        PropTypes.element,
    ]),
    children: PropTypes.string, // depracated
    icon: PropTypes.string.isRequired,
    onClick: PropTypes.func,
    clickable: PropTypes.bool,
    style: PropTypes.object,
    'data-cy': PropTypes.string,
}

export default Icon
