import moment from 'moment'
import { v4 as uuidv4 } from 'uuid'
import { getComponentWithId } from './StoreUtils'

const onChangeDate = (v, callback = () => {}) => {
    if (v.length >= 10) {
        const regex = new RegExp('\\d\\d\\/\\d\\d\\/\\d\\d\\d\\d')
        const date = moment(v, 'DD/MM/YYYY')
        if (v.length === 10 && v[2] === '/' && v[5] === '/' && date.isValid() && regex.test(v)) {
            callback(date.valueOf())
        }
    }
}

const onChangeHour = (v, callback = () => {}) => {
    if (v.length >= 8) {
        const regex = /\d\d:\d\d:\d\d/g
        const date = moment(v, 'HH:mm:ss')
        if (v.length === 8 && v[2] === ':' && v[5] === ':' && date.isValid() && regex.test(v)) {
            callback(date.valueOf())
        }
    }
}

const isValidEmail = (email) => {
    const regex = /.+@+./g
    return regex.test(email)
}

const getSetting = (settingList, parameter) => {
    const found = settingList.find(s => s.parameter === parameter)
    return found ? found.value : null
}

const getSettingInt = (settingList, parameter) => {
    const found = settingList.find(s => s.parameter === parameter)
    return found && parseInt(found.value) ? parseInt(found.value) : null
}

const setSieauTooltip = (id, content, position) => {
    getComponentWithId('#sieautooltip').setTooltip(id, content, position)
}

// if content is not a simple string, pass it as a function : const content = () => <myReactContent/>
// that would be more optimized (the react content would be loaded just on the tooltip time)
const sieauTooltip = (content, chosenId, position) => {
    const id = chosenId || uuidv4()
    return {
        onMouseOver: () => setSieauTooltip(id, typeof content === 'function' ? content() : content, position),
        onMouseOut: () => setSieauTooltip(null),
        id,
        onMouseDown: () => setSieauTooltip(null),
    }
}

export { onChangeHour, onChangeDate, isValidEmail, getSetting, getSettingInt, setSieauTooltip, sieauTooltip }
