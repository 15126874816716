import { useCallback, useEffect, useRef } from 'react'

/**
 * Hook use by useDebounce
 * @param {function} callback - The callback
 * @param {number} delay - The delay
 * @return {{reset: function, clear: function}} clear cancel the timeout, reset restart the timeout
 */
const useTimeout = (callback, delay) => {
    const callbackRef = useRef(callback)
    const timeoutRef = useRef()

    useEffect(() => {
        callbackRef.current = callback
    }, [callback])

    const set = useCallback(() => {
        timeoutRef.current = setTimeout(() => callbackRef.current(), delay)
    }, [delay])

    const clear = useCallback(() => timeoutRef.current && clearTimeout(timeoutRef.current), [])

    useEffect(() => {
        set()
        return clear
    }, [delay, set, clear])

    const reset = useCallback(() => {
        clear()
        set()
    }, [clear, set])

    return { reset, clear }
}

export default useTimeout