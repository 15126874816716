import React from 'react'
import { FormControl, TextField } from '@mui/material'
import { styled } from '@mui/styles'

export const InputRow = styled(({ InputProps: { sx, startAdornment, ...otherInputProps } = {}, ...other }) => (
    <TextField
        fullWidth
        variant='outlined'
        color='primary'
        InputLabelProps={{
            sx: {
                top: startAdornment ? '0.35rem' : 0,
                '&.Mui-focused': {
                    top: '0.35rem',
                },
            },
        }}
        InputProps={{
            sx: {
                margin: '5px 0',
                width: '100% !important',
                zIndex: '0',
                '& input': {
                    padding: '0.75rem',
                },
                ...sx,
            },
            startAdornment,
            ...otherInputProps,
        }}
        {...other}
    />
))({})

export const FormControlRow = styled(FormControl)({
    margin: '5px 0',
    width: '100%',
})
