/* eslint-disable consistent-return */
/* eslint-disable max-nested-callbacks */
import { groupBy, isUndefined, mean, orderBy, range, round, uniqBy } from 'lodash'
import PropTypes from 'prop-types'
import React, { useMemo } from 'react'
import { shallowEqual, useSelector } from 'react-redux'
import i18n from 'simple-react-i18n'
import { getRemarks, validatedData, searchAverageValue, searchMaxTabValue, searchMinTabValue, searchPercentile90, getValueAnalyse, getTabColorsBySize, getThresholdType, THRESHOLD_TYPE } from 'utils/AnalyseUtils'
import { hasValue } from 'utils/NumberUtil'
import { getLabel } from 'utils/StoreUtils'
import QualityChart from './QualityChart'
import { AUTO, DEFAULT_NB_POINT_MOVING_AVERAGE, MONTH, WEEK, YEAR } from './ChartConstant'
import DtoQualityThreshold from 'pages/online/follows/dto/DtoQualityThreshold'

const getStationName = (station, parameter) => {
    if (station?.code) {
        return `[${station?.code}] ${station?.name || ''}, ${parameter?.displayName || ''}`
    }
    return `${station?.name || ''}, ${parameter?.displayName || ''}`
}

const ParameterGraph = ({
    analysis = [],
    additionalData = [],
    thresholds = [],

    graphOptions = {},

    graphHeight = 470,
    componentHeight = 600,

    withToolLegend = false,
    withArea = false,

    exportName,

    dataZoomPosition = { bottom: '45px' },
    legendPosition = { top: '565px', right: '2%' },
    gridPosition = { top: '50px' },
    toolboxPosition = { top: '0px' },
}) => {
    const {
        qualitometers,
        parameters,
        units,
        settings,
    } = useSelector(store => ({
        qualitometers: store.HomeReducer.qualitometersLight,
        parameters: store.FollowReducer.parameters,
        units: store.FollowReducer.units,
        settings: store.AccountReducer.accountUserSettings,
    }), shallowEqual)

    const {
        regroupAxis = false,
        graphicTitle,

        displayStatistics = false,
        displayAverages = false,
        nbPointMovingAverage = DEFAULT_NB_POINT_MOVING_AVERAGE,
        displayHype = false,

        minY,
        maxY,
        xAxisSpace,
        maxXAxisSpace,
        showXSplitLines,
        showYSplitLines,

        stationsOptions = [],
    } = graphOptions

    const parametersCode = useMemo(() => uniqBy(analysis, 'parameter').map(a => a.parameter), [analysis])
    const onlyOneParameter = useMemo(() => parametersCode.length === 1, [parametersCode.length])

    const onlyOneQualitometer = useMemo(() => uniqBy(analysis, 'qualitometer').length === 1, [analysis])
    const unitsCode = useMemo(() => uniqBy(analysis, 'unit').map(a => a.unit || 'unknown'), [analysis])
    const unitByParam = useMemo(() => {
        const groupByParameter = groupBy(analysis, 'parameter')
        return Object.keys(groupByParameter).reduce((acc, paramCode) => {
            const uniqUnit = uniqBy(groupByParameter[paramCode], 'unit').map(a => a.unit || 'unknown')
            acc[paramCode] = {
                unitsCode: uniqUnit.join('#'),
                unitslabel: uniqUnit.map(code => getLabel(units, code, 'symbolWithCode') || i18n.unknown).join(', '),
            }
            return acc
        }, {})
    }, [analysis, units])

    const paramThreshold = useMemo(() => {
        if (!onlyOneParameter || (unitsCode.length > 1 && !regroupAxis)) {
            return
        }
        const [parameter] = parametersCode
        return thresholds.find(t => t.parameterCode === parameter)
    }, [onlyOneParameter, parametersCode, regroupAxis, thresholds, unitsCode.length])

    const thresholdType = useMemo(() => getThresholdType(paramThreshold), [paramThreshold])

    const getMarkerColor = (color) => {
        if (!onlyOneParameter || isUndefined(paramThreshold)) {
            return undefined
        }
        return color === 'white' ? 'black' : color
    }

    const analysisFormated = useMemo(() => {
        const groupAnalysis = groupBy(analysis, a => regroupAxis ? `${a.qualitometer}#${a.parameter}` : `${a.qualitometer}#${a.parameter}#${a.unit || 'unknown'}`)
        return Object.keys(groupAnalysis).flatMap(key => {
            const [stationId, paramCode, unitCode] = key.split('#')
            const parseStationId = parseInt(stationId)
            const stationOptions = stationsOptions.find(o => o.id === parseStationId)
            const {
                [key]: analysisFiltered = [],
            } = groupAnalysis
            const parameter = parameters.find(p => p.code === paramCode)
            const qualitometer = qualitometers.find(q => q.id === parseStationId)
            return {
                uniqId: parseStationId,
                name: getStationName(qualitometer, parameter),
                unit: unitByParam[paramCode]?.unitslabel,
                idYAxis: regroupAxis ? unitByParam[paramCode]?.unitsCode : unitCode,
                color: stationOptions?.color || (onlyOneQualitometer && onlyOneParameter ? 'black' : undefined),
                dataList: analysisFiltered.map(a => ({
                    date: a.sampleDate,
                    value: a.result,
                    unit: getLabel(units, a.unit, 'symbol') || i18n.unknown,
                    marker: getRemarks(a.remark)?.plot,
                    color: getMarkerColor(a.color),
                })),
            }
        })
    }, [analysis, onlyOneParameter, onlyOneQualitometer, parameters, qualitometers, regroupAxis, stationsOptions, units, unitByParam])

    const additionalDataFormated = useMemo(() => {
        return additionalData.map(d => ({
            name: d.name,
            unit: d.unit,
            idYAxis: d.unit,
            type: d.type,
            lineStyle: d.lineStyle,
            showSymbol: d.showSymbol,
            connectNulls: d.connectNulls,
            dataList: d.dataList,
        }))
    }, [additionalData])

    const parametersObj = parametersCode.map(code => parameters.find(p => p.code === code)).filter(u => !!u)
    const unitsObj = uniqBy(analysis, 'unit').map(a => units.find(u => u.code === a.unit)).filter(u => !!u)

    const chartTitle = isUndefined(graphicTitle) ? `${parametersObj.map(p => p.displayLabel).join(', ')} ${unitsObj.length ? `(${unitsObj.map(u => u.symbolWithCode).join(', ')})` : ''}` : graphicTitle

    const colorTab = useMemo(() => {
        if (!paramThreshold) {
            return []
        }
        if (thresholdType === THRESHOLD_TYPE.PH) {
            return ['red', 'blue', 'red']
        }
        const allColorTab = getTabColorsBySize()
        const nbThresholds = [1, 2, 3, 4].filter(nb => hasValue(paramThreshold[`threshold${nb}`])).length + 1
        return allColorTab[nbThresholds]
    }, [paramThreshold, thresholdType])

    const thresholdFormated = useMemo(() => {
        if (!paramThreshold) {
            return []
        }
        const [paramCode] = parametersCode
        const [unitCode] = unitsCode

        if (thresholdType === THRESHOLD_TYPE.PH) {
            return [
                {
                    value: paramThreshold.threshold1,
                    idYAxis: regroupAxis ? unitByParam[paramCode]?.unitsCode : unitCode,
                    color: 'red',
                    labelColor: 'black',
                },
                {
                    value: paramThreshold.threshold2,
                    idYAxis: regroupAxis ? unitByParam[paramCode]?.unitsCode : unitCode,
                    color: 'red',
                    labelColor: 'black',
                },
            ]
        }

        return [1, 2, 3, 4].map(nb => ({
            value: paramThreshold[`threshold${nb}`],
            idYAxis: regroupAxis ? unitByParam[paramCode]?.unitsCode : unitCode,
            color: colorTab[nb],
            labelColor: 'black',
        })).filter(t => !!t.value)
    }, [colorTab, paramThreshold, parametersCode, regroupAxis, thresholdType, unitByParam, unitsCode])

    const markLines = useMemo(() => {
        if (!displayStatistics) {
            return []
        }
        const validAnalysis = validatedData(analysis.filter(a => a.remark !== '0' && (hasValue(a.result) || a.remark === '7')))
        if (!validAnalysis.length) {
            return []
        }
        const minValue = searchMinTabValue(validAnalysis)
        const maxValue = searchMaxTabValue(validAnalysis)
        const average = searchAverageValue(validAnalysis, settings)
        const percentile90 = searchPercentile90(validAnalysis)
        return [
            {
                value: getValueAnalyse(minValue),
                label: i18n.min,
            },
            {
                value: getValueAnalyse(maxValue),
                label: i18n.max,
            },
            {
                value: getValueAnalyse(percentile90),
                label: i18n.percentile90,
            },
            {
                value: parseFloat(average.value),
                label: i18n.average,
            },
        ]
    }, [analysis, displayStatistics, settings])

    const movingAverage = useMemo(() => {
        if (!displayAverages || parametersCode.length !== 1) {
            return []
        }
        const [paramCode] = parametersCode
        const analysisGroup = groupBy(analysis, a => regroupAxis ? a.qualitometer : `${a.qualitometer}#${a.unit || 'unknown'}`)
        return Object.keys(analysisGroup).map(key => {
            const [, unit] = key.split('#')
            const orderedAnalysis = orderBy(analysisGroup[key], 'analysisDate')
            const dataList = orderedAnalysis.flatMap((a, index) => {
                if (index < nbPointMovingAverage || index >= (orderedAnalysis.length - nbPointMovingAverage)) {
                    return []
                }
                const rangeList = range(index - nbPointMovingAverage, index + nbPointMovingAverage + 1)
                const average = mean(rangeList.map(idx => orderedAnalysis[idx].result))
                return { date: a.analysisDate, value: round(average, 3) }
            })
            const { qualitometer: stationId } = analysisGroup[key][0]
            const qualitometer = qualitometers.find(q => q.id === stationId)
            const stationOptions = stationsOptions.find(o => o.id === stationId)
            return {
                name: `[${qualitometer?.code || key}] ${i18n.movingAverage} ${nbPointMovingAverage * 2 + 1}`,
                lineStyle: 'dotted',
                color: stationOptions?.color,
                idYAxis: regroupAxis ? unitByParam[paramCode]?.unitsCode : unit,
                dataList,
            }
        })
    }, [analysis, displayAverages, nbPointMovingAverage, parametersCode, qualitometers, regroupAxis, stationsOptions, unitByParam])

    const yAxis = useMemo(() => {
        const additionalYAxis = uniqBy(additionalData, 'unit').map(d => ({
            name: d.unit,
            idYAxis: d.unit,
            position: 'right',
            inverse: d.inverse,
            min: minY,
            max: maxY,
            showSplitLine: showYSplitLines,
        }))
        if (regroupAxis) {
            const paramAxis = parametersCode.map(code => {
                return {
                    name: unitByParam[code]?.unitslabel,
                    idYAxis: unitByParam[code]?.unitsCode,
                    min: minY,
                    max: maxY,
                    showSplitLine: showYSplitLines,
                }
            })
            return [
                ...uniqBy(paramAxis, 'idYAxis'),
                ...additionalYAxis,
            ]
        }
        const unitAxis = unitsCode.map(code => ({
            name: getLabel(units, code, 'symbolWithCode') || i18n.unknown,
            idYAxis: code,
            min: minY,
            max: maxY,
            showSplitLine: showYSplitLines,
        }))
        return [
            ...unitAxis,
            ...additionalYAxis,
        ]
    }, [additionalData, maxY, minY, parametersCode, regroupAxis, showYSplitLines, unitByParam, units, unitsCode])

    return (
        <QualityChart
            title={chartTitle}
            data={[...additionalDataFormated, ...analysisFormated, ...movingAverage]}
            thresholds={thresholdFormated}
            graphHeight={graphHeight}
            componentHeight={componentHeight}
            dataZoomPosition={dataZoomPosition}
            legendPosition={legendPosition}
            gridPosition={gridPosition}
            toolboxPosition={toolboxPosition}
            xAxis={{
                showSplitLine: showXSplitLines,
                xAxisSpace,
                maxXAxisSpace,
            }}
            yAxis={yAxis}
            exportName={exportName ?? `${i18n.follow} - ${parametersObj.map(p => p.displayName).join(', ')}`}
            markLines={markLines}
            min={0}
            areaColor={colorTab}
            withArea={withArea}
            withToolTypeLine
            withToolTypeBar={!displayHype && !additionalData.length}
            withToolTypeStack={!displayHype && !additionalData.length}
            withToolLog={!displayHype && !additionalData.length}
            withToolLegend={withToolLegend}
            withToolThreshold
            withToolMarker
            withToolLine={!displayHype}
            withDataZoom
        />
    )
}

ParameterGraph.propTypes = {
    analysis: PropTypes.arrayOf(PropTypes.shape({/* ...DtoAnalysis, ...calculateThresholdResult */ })),
    additionalData: PropTypes.arrayOf(PropTypes.shape({
        name: PropTypes.string,
        unit: PropTypes.string,
        dataList: PropTypes.arrayOf(PropTypes.shape({
            date: PropTypes.number,
            value: PropTypes.number,
        })),
    })),
    thresholds: PropTypes.arrayOf(PropTypes.instanceOf(DtoQualityThreshold)),

    hypeRuptures: PropTypes.arrayOf(PropTypes.shape({})),
    hypeTrends: PropTypes.arrayOf(PropTypes.shape({})),

    graphOptions: PropTypes.shape({
        regroupAxis: PropTypes.bool,
        graphicTitle: PropTypes.string,

        displayStatistics: PropTypes.bool,
        displayAverages: PropTypes.bool,
        nbPointMovingAverage: PropTypes.number,
        displayHype: PropTypes.bool,

        minY: PropTypes.number,
        maxY: PropTypes.number,
        xAxisSpace: PropTypes.oneOf([YEAR, MONTH, WEEK, AUTO]),
        maxXAxisSpace: PropTypes.oneOf([YEAR, MONTH, WEEK, AUTO]),
        showXSplitLines: PropTypes.bool,
        showYSplitLines: PropTypes.bool,

        stationsOptions: PropTypes.arrayOf(PropTypes.shape({
            id: PropTypes.number,
            color: PropTypes.string,
        })),
    }),

    graphHeight: PropTypes.number,
    componentHeight: PropTypes.number,

    withToolLegend: PropTypes.bool,
    withArea: PropTypes.bool,

    exportName: PropTypes.string,

    dataZoomPosition: PropTypes.shape({}),
    legendPosition: PropTypes.shape({}),
    gridPosition: PropTypes.shape({}),
    toolboxPosition: PropTypes.shape({}),
}

export default ParameterGraph