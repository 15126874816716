import moment from 'moment'
import i18n from 'simple-react-i18n'
import word from '../ressources/pictures/logos/word.svg'
import pdf from '../ressources/pictures/logos/pdf.svg'
import excel from '../ressources/pictures/logos/excel.svg'
import powerpoint from '../ressources/pictures/logos/powerpoint.svg'
import text from '../ressources/pictures/logos/txt.svg'
import defaultLogo from '../ressources/pictures/logos/text.svg'
import {
    DOCUMENT,
    EXCEL_EXTENSIONS,
    PDF_EXTENSIONS,
    PICTURE,
    POWERPOINT_EXTENSIONS,
    TEXT_EXTENSIONS,
    WORD_EXTENSIONS,
} from '../pages/online/referencials/documents/constants/FileConstants'
import { SANDRE } from '../pages/online/referencials/constants/ReferencialConstants'

const formatedFileName = (fileName) => {
    return fileName ? fileName.normalize('NFD').replace(/[\u0300-\u036f]/g, '').split('_').join('-') : i18n.unknown
}

const getFormatedFileName = (stationCode, active = '0', fileName, extension = 'jpg', fileType, date = moment().format('YYYYMMDD-Hmmss')) => {
    const dateFormatted = (() => {
        if (!new RegExp('^[0-9]+-[0-9]+$').test(date)) {
            return moment(date).format('YYYYMMDD-Hmmss')
        }
        return date
    })()
    return `${stationCode}_${fileType}_${active.toString()}_${dateFormatted}_${formatedFileName(fileName)}.${extension}`
}

const getFileExtension = (fileName) => {
    return fileName.slice((Math.max(0, fileName.lastIndexOf('.')) || Infinity) + 1)
}

const getFileName = (fileName) => {
    return fileName.substr(0, fileName.lastIndexOf('.'))
}

const getFileNatureLabel = (fileName, sandreCodes) => {
    const [, type] = fileName.substr(0, fileName.lastIndexOf('.')).split('_')
    const fileNature = sandreCodes.some(s => s.name === type) ? type : 'INCONNU'
    return fileNature
}

const getDocumentTypeIcon = (fileExtension) => {
    if (WORD_EXTENSIONS.includes(fileExtension)) {
        return word
    } else if (PDF_EXTENSIONS.includes(fileExtension)) {
        return pdf
    } else if (EXCEL_EXTENSIONS.includes(fileExtension)) {
        return excel
    } else if (POWERPOINT_EXTENSIONS.includes(fileExtension)) {
        return powerpoint
    } else if (TEXT_EXTENSIONS.includes(fileExtension)) {
        return text
    } return defaultLogo
}

const getTypeSandreCode = (type) => {
    if (type === PICTURE) {
        return SANDRE.TYPE_PHOTO
    } else if (type === DOCUMENT) {
        return SANDRE.TYPE_DOCUMENT
    } return SANDRE.TYPE_PHOTO
}

export { getFormatedFileName, getFileExtension, getFileName, formatedFileName, getFileNatureLabel, getDocumentTypeIcon, getTypeSandreCode }
