export default class DtoCMSMessage {
    constructor(obj) {
        this.id = obj.id // Long,
        this.cmsId = obj.cmsId // Long,
        this.message = obj.message // Option[String],
        this.archive = obj.archive // Option[Boolean],
        this.author = obj.author // Option[String],
        this.dateMessage = obj.dateMessage // Option[DateTime],
        this.dateArchive = obj.dateArchive // Option[DateTime]
    }
}
