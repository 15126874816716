export default class DtoMeasureMethod {
    constructor(obj) {
        this.idStation = obj.idStation
        this.startDate = obj.startDate
        this.endDate = obj.endDate
        this.method = obj.method
        this.periodicity = obj.periodicity
        this.comment = obj.comment
        this.depth = obj.depth
        this.measureOffset = obj.measureOffset
        this.periodicityAcqui = obj.periodicityAcqui
        this.precision = obj.precision
    }
}