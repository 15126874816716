import { LOGIN } from 'pages/home/constants/HomeConstants'
import PropTypes from 'prop-types'

const getLabel = (propList, code, labelName, key = 'code', defaultReturn = '') => {
    const found = propList.find(elem => elem[key] == code)
    if (found) {
        if (labelName && found[labelName]) {
            return found[labelName]
        }
        if (found.name) {
            return found.name
        }
        if (found.label) {
            return found.label
        }
        if (found.title) {
            return found.title
        }
    }
    return defaultReturn
}

const createIndex = (tab, keyToUse) => {
    const res = {}
    if (keyToUse) {
        if (tab.length) {
            tab.forEach(o => {
                res[o[keyToUse]] = o
            })
            return res
        }
        return {}
    }
    if (tab.length) {
        tab.forEach(o => {
            res[o.id] = o
        })
        return res
    }
    return {}
}

const removeNullKeys = (obj) => {
    return Object.keys(obj).filter(k => !(obj[k] === undefined || obj[k] === null)).reduce((acc, key) => {
        const r = {}
        r[key] = obj[key]
        return Object.assign({}, acc, r)
    }, {})
}

const getUser = (users) => {
    return users.find(u => u.login == localStorage.getItem(LOGIN)) || {}
}

const getSandreList = (sandreCodes, field) => {
    return sandreCodes.filter(c => c.field === field)
}

const arrayOf = (dto) => {
    return PropTypes.arrayOf(PropTypes.instanceOf(dto))
}

const instanceOf = (dto) => {
    return PropTypes.instanceOf(dto)
}

const getComponentWithId = (id) => {
    const dom = $(id)[0]
    if (!dom) {
        return null
    }
    let internalInstance = dom[Object.keys(dom).find(key => key.startsWith('__reactInternalInstance$'))]
    if (!internalInstance) {
        internalInstance = dom[Object.keys(dom).find(key => key.startsWith('__reactFiber$'))]
        if (!internalInstance) {
            return null
        }
    }
    return internalInstance._debugOwner ? internalInstance._debugOwner.stateNode : internalInstance.return.stateNode
}

export { getLabel, getSandreList, getUser, arrayOf, instanceOf, removeNullKeys, createIndex, getComponentWithId }