import { path, contentsPath } from './basepath'

// const localApi = 'http://localhost:8000/'

const lightMode = '?lightMode=true'

export default {
    login: () => `${path}login`,
    logout: () => `${path}logout`,
    resetPassword: () => `${path}reset`,
    loginPicture: () => `${contentsPath}_PUBLIC/login_header.jpg`,
    cgu: () => 'https://www.aquasys.fr/wp-json/wp/v2/pages/3995',
    mailSupport: () => `${path}mail/admin`,
    createStatistic: () => `${path}statisticApplication`,
    log: {
        error: () => `${path}log/error`,
        info: () => `${path}log/info`,
        debug: () => `${path}log/debug`,
    },
    station: {
        observatoryFollowResults: stationType => `${path}${stationType}/situation/observatory`,
        allContributors: (stationType) => `${path}station/contributorLink/${stationType}`,
        dataTypesByProject: (project) => `${path}station/datatypes/${project}`,
    },
    layers: {
        getLayers: () => `${path}layer/theme`,
        getAuthorizedLayers: () => `${path}theme/authorizations`,
    },
    export: {
        export: () => `${path}export`,
        exportData: () => `${path}export/data`,
    },
    piezometer: {
        getAll: () => `${path}piezometer`,
        getAllLight: () => `${path}piezometer${lightMode}`,
        get: id => `${path}piezometer/${id}`,
        natures: () => `${path}nature`,
        nature: (stationId) => `${path}nature/${stationId}`,
        measures: id => `${path}piezometer/measure/${id}`,
        additionalMeasures: (stationId, type) => `${path}piezometer/measure/${stationId}/type/${type}`,
        thresholds: id => `${path}piezometer/piezometerThreshold/${id}`,
        allThresholds: (displayCote) => `${path}piezometer/piezometerThreshold/${displayCote ? `?displayCote=${displayCote}` : ''}`,
        dataTypes: () => `${path}piezometer/dataTypes`,
        measuresStats: id => `${path}piezometer/measure/${id}/stats`,
        chartMeasures: () => `${path}piezometer/chartMeasures`,
        events: id => `${path}piezometer/${id}/event`,
        linkedPiezoMeasures: (id, occurence, nbOccurence) => `${path}piezometer/${id}/${occurence}/${nbOccurence}`,
        linkedPiezoPrel: () => `${path}piezometer/linkedPrel`,
        linkedPiezosPrel: () => `${path}piezometer/linkedPrels`,
        chartOptions: (stationId) => `${path}piezometer/chartOptions/${stationId}`,
        hasSeveralDataPerDay: () => `${path}piezometer/hasSeveralDataPerDay`,
    },
    hydrometricStation: {
        getAll: () => `${path}hydrologicalStation`,
        get: code => `${path}hydrologicalStation/${code}`,
        measures: (id, startDate) => `${path}hydrologicalStation/${id}/measures${!!startDate && `?${startDate}`}`,
        dataTypes: () => `${path}hydrologicalStation/dataTypes`,
        threshold: id => `${path}hydrologicalStation/${id}/threshold`,
        chronicMeasures: () => `${path}hydrologicalStation/chronic/measures`,
        chronicStats: () => `${path}hydrologicalStation/chronic/stats`,
        thresholds: () => `${path}hydrologicalStation/threshold/`,
        events: hydroId => `${path}hydrologicalStation/${hydroId}/event`,
        statistics: () => `${path}hydrologicalStation/chronic/stats`,
    },

    installation: {
        getAll: () => `${path}installation`,
        getAllLight: () => `${path}installation${lightMode}`,
        get: id => `${path}installation/${id}`,
        types: () => `${path}installation/types`,
        events: id => `${path}installation/${id}/event`,
        analysisByCriterias: id => `${path}installation/${id}/analysis`,
    },
    contact: {
        getChat: (login, dest) => `${path}user/messagesDest/conv/${login}/${dest}`,
        getMessage: (id) => `${path}user/messages/${id}`,
        sendMessage: () => `${path}user/messages`,
        defConv: () => `${path}user/messagesDest`,
        getMessagesOf: (login) => `${path}user/messages/login/${login}`,
    },
    referencial: {
        cities: () => `${path}city`,
        city: townCode => `${path}city/${townCode}`,
        contributors: () => `${path}referencial/contributor`,
        contributorsGroups: () => `${path}referencial/contributor/group`,
        contributor: id => `${path}referencial/contributor/${id}`,
        contributorTypes: () => `${path}referencial/contributor/type/qualito`,
        sandreCodes: () => `${path}sandre`,
        resources: () => `${path}referencial/resources`,
        resource: id => `${path}referencial/resources/${id}`,
        associatedLinks: (code, stationType) => `${path}station/link/${code}/${stationType}`,
        associatedLinksLight: (code, stationType) => `${path}station/link/${code}/${stationType}${lightMode}`,
        associatedLinksSeqLight: () => `${path}station/link`, // default in light mode
        associatedLinksWithFollow: (code, stationType) => `${path}station/link/deeper/${code}/${stationType}${lightMode}`,
        units: () => `${path}referencial/unit`,
        watermass: (stationId) => `${path}referencial/watermass/${stationId}`,
        watermasses: () => `${path}referencial/watermass`,
        aquifers: () => `${path}referencial/aquifer`,
        aquifer: (id) => `${path}referencial/aquifer/${id}`,
        contact: (code) => `${path}referencial/contact/${code}`,
        contacts: () => `${path}referencial/contact`,
        fieldModes: () => `${path}referencial/fieldMode`,
        stationEvents: (code, stationType) => `${path}${stationType}/${code}/event`,
        stationsEvents: () => `${path}station/events`,
        qualitoTypes: () => `${path}referencial/type/qualitometers`,
        states: () => `${path}referencial/state`,
        piezoTypes: () => `${path}referencial/type/piezometer`,
        parameters: () => `${path}referencial/parameter`,
        updateContact: () => `${path}referencial/contact`,
        getContact: (id) => `${path}referencial/contact/${id}`,
        globalParameters: () => `${path}referencial/globalParameters`,
        status: () => `${path}referencial/status`,
        qualification: () => `${path}referencial/qualification`,
    },
    cms: {
        getAll: () => `${path}cms`,
        get: (id) => `${path}cms/${id}/${navigator.language || navigator.userLanguage}`,
        category: () => `${path}cms/category`,
        getByCategory: (id) => `${path}cms/${id}/category`,
        getDateValidCGU: (login) => `${path}user/statistic/cgu/${login}`,
        sendDateCgu: () => `${path}statisticApplication/user/cgu`,
        addMessage: () => `${path}cms/message`,
        messages: (id) => `${path}cms/messages/${id}`,
        followCms: (id, login) => `${path}cms/follower/${login}/${id}`,
    },
    pluviometer: {
        getAll: () => `${path}pluviometer`,
        get: code => `${path}pluviometer/${code}`,
        chartMeasures: () => `${path}pluviometer/chartMeasures`,
        threshold: id => `${path}pluviometer/${id}/threshold`,
        measures: (id) => `${path}pluviometer/measure/${id}`,
        measuresStats: (id) => `${path}pluviometer/measure/${id}/stats`,
        events: id => `${path}pluviometer/${id}/event`,
        thresholds: () => `${path}pluviometer/threshold/`,
    },
    productionUnit: {
        getAll: () => `${path}productionUnit`,
        get: (id) => `${path}productionUnit/${id}`,
        associatedLinks: (id) => `${path}station/link/${id}/5`,
        events: (id) => `${path}productionUnit/${id}/event`,
        associatedStations: (id) => `${path}productionUnit/associated/${id}`,
    },
    file: {
        pictures: (stationCode) => `${path}files/picture/${stationCode}`,
        stationPictures: (stationCode, stationType) => `${path}files/picture/${stationCode}/${stationType}`,
        documents: (stationCode) => `${path}files/document/${stationCode}`,
        stationDocuments: (stationCode, stationType) => `${path}files/document/${stationCode}/${stationType}`,
        pictureCompressPath: (pictureName) => `${path}files/compressPicture/${pictureName}/taux/0.5`,
    },
    qualitometers: {
        getAll: () => `${path}qualitometer`,
        getAllLight: () => `${path}qualitometer${lightMode}`,
        get: (id) => `${path}qualitometer/${id}`,
        operations: (id) => `${path}qualitometer/${id}/operation`,
        analysis: (operationId, stationId) => `${path}operation/${stationId}/analysis/${operationId}`,
        samples: (id) => `${path}qualitometers/${id}/sample`,
        threshold: (id) => `${path}quality/threshold/${id}/quality/0`,
        events: id => `${path}qualitometer/${id}/event`,
        allAnalysis: () => `${path}analysis?lightMode=true`,
    },
    user: {
        get: (login) => `${path}user/${login}`,
        getAll: () => `${path}user/`,
        changePassword: () => `${path}user/password`,
        sendMessage: () => `${path}user/messages`,
        getMessages: (login) => `${path}user/messages/login/${login}`,
        defConv: () => `${path}user/messagesDest`,
        getConv: (login, dest) => `${path}user/messagesDest/conv/${login}/${dest}`,
        getMessage: (id) => `${path}user/messages/${id}`,
        getUserCGU: (login) => `${path}user/statistic/cgu/${login}`,
        updateCguDate: () => `${path}statisticApplication/user/cgu`,
        observatoryHabilitations: (login) => `${path}user/habilitations/observatory/${login}`,
        saveUserParameters: (login) => `${path}user/parameters/${login}`,
        updatePassword: (login) => `${path}user/password/${login}`,
        settings: () => `${path}user/parameters`,
        applicationSettings: () => `${path}user/parameter/sieau`,
        getStatistics: (login) => `${path}user/statistic/${login}`,
        setting: () => `${path}user/parameter`,
        getCmsStatistics: (login) => `${path}user/statistic/cms/${login}`,
        userStations: (login) => `${path}user/station/${login}`,
        getUsageStatistics: (login) => `${path}user/statistic/usage/${login}`,
        getStationStatistics: (login) => `${path}user/statistic/station/${login}`,
        getKeyFigures: (login) => `${path}user/keyfigures/${login}`,
    },

}
