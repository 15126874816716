import { useEffect } from 'react'
import useTimeout from './useTimeout'

/**
 * Execute the callback after a while if there was no new update
 * @param {function} callback - The function to execute
 * @param {number} delay - The delay
 * @param {Array} dependencies - The dependencies
 * @return {void}
 */
const useDebounce = (callback, delay, dependencies) => {
    const { reset, clear } = useTimeout(callback, delay)
    useEffect(reset, [...dependencies, reset])
    useEffect(clear, [clear])
}

export default useDebounce