export default class DtoArrangement {
    constructor(obj) {
        this.idStation = obj.idStation
        this.startDate = obj.startDate
        this.endDate = obj.endDate
        this.descriptive = obj.descriptive
        this.value = obj.value
        this.arrangementType = obj.arrangementType
        this.keyCode = obj.keyCode
    }
}