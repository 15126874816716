import {
    STATION_TYPE_CONSTANT,
    STATION_TYPE_NAME,
    OBSERVATORY_STATION_TYPE_NAME,
} from 'pages/home/constants/StationConstants'
import markerInstallation from 'assets/pictures/markers/map_installation_defaut.png'
import { getCatchmentMarker, getHydroMarker, getInstMarker, getPiezoMarker, getPluvioMarker, getQualityMarker, getUnitProdMarker } from './mapUtils/Markers'
import { isNil } from 'lodash'
import { defaultMonitoringColor } from './constants/ColorTheme'

const getMarkerByStationType = (st, color) => {
    switch (st) {
        case STATION_TYPE_CONSTANT.piezometer:
        case STATION_TYPE_NAME.piezometer:
        case STATION_TYPE_NAME.piezometry:
            return `data:image/svg+xml;utf8,${getPiezoMarker(color || 'black')}`
        case STATION_TYPE_CONSTANT.hydrometricStation:
        case STATION_TYPE_NAME.hydrometricStation:
        case STATION_TYPE_NAME.hydrometry:
            return `data:image/svg+xml;utf8,${getHydroMarker(color || 'black')}`
        case STATION_TYPE_CONSTANT.pluviometer:
        case STATION_TYPE_NAME.pluviometer:
        case STATION_TYPE_NAME.pluviometry:
            return `data:image/svg+xml;utf8,${getPluvioMarker(color || 'black')}`
        case STATION_TYPE_CONSTANT.qualitometer:
        case STATION_TYPE_NAME.qualitometer:
        case STATION_TYPE_NAME.quality:
            return `data:image/svg+xml;utf8,${getQualityMarker(color || 'black')}`
        case OBSERVATORY_STATION_TYPE_NAME.catchment:
            return `data:image/svg+xml;utf8,${getCatchmentMarker(color || defaultMonitoringColor)}`
        case STATION_TYPE_CONSTANT.installation:
        case STATION_TYPE_NAME.installation:
            return !isNil(color) ? `data:image/svg+xml;utf8,${getInstMarker(color)}` : markerInstallation
        case STATION_TYPE_CONSTANT.productionUnit:
        case STATION_TYPE_NAME.productionUnit:
            return `data:image/svg+xml;utf8,${getUnitProdMarker(color || defaultMonitoringColor)}`
        default:
            return null
    }
}

const getStationTypeFromName = (stationName) => {
    switch (stationName) {
        case STATION_TYPE_NAME.qualitometer:
            return STATION_TYPE_NAME.quality
        case STATION_TYPE_NAME.piezometer:
            return STATION_TYPE_NAME.piezometry
        case STATION_TYPE_NAME.pluviometer:
            return STATION_TYPE_NAME.pluviometry
        case STATION_TYPE_NAME.hydrometricStation:
            return STATION_TYPE_NAME.hydrometry
        case 'productionUnit':
            return 'productionUnit'
        case 'distributionUnit':
            return 'distributionUnit'
        case 'installation':
            return 'installation'
        default:
            return null
    }
}

const getStationTypeNameFromType = (stationName) => {
    switch (stationName) {
        case STATION_TYPE_NAME.quality:
            return STATION_TYPE_NAME.qualitometer
        case STATION_TYPE_NAME.piezometry:
        case OBSERVATORY_STATION_TYPE_NAME.catchment:
            return STATION_TYPE_NAME.piezometer
        case STATION_TYPE_NAME.hydrometry:
            return OBSERVATORY_STATION_TYPE_NAME.hydrologicalStation
        case STATION_TYPE_NAME.pluviometry:
            return STATION_TYPE_NAME.pluviometer
        case STATION_TYPE_NAME.cce:
            return STATION_TYPE_NAME.cce
        case 'productionUnit':
            return 'productionUnit'
        case 'distributionUnit':
            return 'distributionUnit'
        case 'installation':
            return 'installation'
        default:
            return null
    }
}

const getStationTypeCodeFromType = (stationType) => {
    switch (stationType) {
        case OBSERVATORY_STATION_TYPE_NAME.catchment:
        case STATION_TYPE_NAME.piezometry:
        case STATION_TYPE_NAME.piezometer:
            return 1
        case STATION_TYPE_NAME.pluviometry:
        case STATION_TYPE_NAME.pluviometer:
            return 2
        case STATION_TYPE_NAME.quality:
        case STATION_TYPE_NAME.qualitometer:
            return 3
        case OBSERVATORY_STATION_TYPE_NAME.hydrologicalStation:
        case STATION_TYPE_NAME.hydrometry:
            return 4
        case STATION_TYPE_NAME.productionUnit:
            return 5
        case STATION_TYPE_NAME.distributionUnit:
            return 6
        case STATION_TYPE_NAME.installation:
            return 7
        case STATION_TYPE_NAME.resource:
            return 9
        default:
            return null
    }
}

const filterObsLinkedStations = (linkedStations) => linkedStations.filter(s => ![null, STATION_TYPE_NAME.resource, STATION_TYPE_NAME.installation, STATION_TYPE_NAME.distributionUnit].includes(s.typeName))

export {
    getMarkerByStationType,
    getStationTypeFromName,
    getStationTypeNameFromType,
    getStationTypeCodeFromType,
    filterObsLinkedStations,
}
