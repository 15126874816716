export default class PluviometerThresholdDto {
    constructor(obj) {
        this.id = obj.id || obj.thresholdCode
        this.thresholdCode = obj.id || obj.thresholdCode
        this.code = obj.code
        this.value = obj.value
        this.color = obj.color
        this.title = obj.name || obj.title
        this.name = obj.name
        this.line = obj.stroke || obj.line
        this.stroke = obj.stroke
        this.passingThreshold = obj.isOverrunThreshold || obj.passingThreshold
        this.isOverrunThreshold = obj.isOverrunThreshold
        this.month01 = obj.month01
        this.month02 = obj.month02
        this.month03 = obj.month03
        this.month04 = obj.month04
        this.month05 = obj.month05
        this.month06 = obj.month06
        this.month07 = obj.month07
        this.month08 = obj.month08
        this.month09 = obj.month09
        this.month10 = obj.month10
        this.month11 = obj.month11
        this.month12 = obj.month12
    }
}
