import React, { useState } from 'react'
import PropTypes from 'prop-types'
import { Dialog, DialogTitle, FormControlLabel, Grid, Icon, Radio, RadioGroup } from '@mui/material'
import i18n from 'simple-react-i18n'
import { groupBy } from 'lodash'
import { hasValue } from 'utils/NumberUtil'
import { SecondaryButton, RedButton } from 'components/styled/Buttons'
import { styled } from '@mui/styles'
import { DialogActionsMUI, DialogContentMUI } from './styled/dialog'

const Label = styled('h4')({
    fontSize: 20,
    fontWeight: 'bold',
    marginBottom: 0,
    marginTop: 0,
})

const ExportFileModal = ({
    open = false,
    data = [],
    title = '',
    maxWidth = 'lg',
    groups = [],
    onClose = () => { },
    closeOnExport = false,
    settingsStyle = {},
}) => {
    const [dataChoice, setDataChoice] = useState(0)
    const [formatChoice, setFormatChoice] = useState(data?.[0]?.formats?.[0]?.type)

    const exportObj = data[dataChoice]

    const getCallback = () => {
        exportObj.formats.find(d => d.type === formatChoice)?.callback()
        if (closeOnExport) {
            onClose()
        }
    }

    const dataOptions = data.map((d, i) => ({
        ...d,
        code: i,
        name: d.name,
        group: d.group,
    }))
    const groupByDatas = groupBy(data, 'group')

    return (
        <Dialog
            fullWidth
            maxWidth={maxWidth}
            open={open}
        >
            <DialogTitle style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                <b>{title || i18n.export}</b>
                <Icon className='clickable' onClick={onClose}>close</Icon>
            </DialogTitle>
            <DialogContentMUI>
                {data.length > 1 ? (
                    <Grid container justifyContent='space-between'>
                        <Grid item xs={6}>
                            <Label>{i18n.exportName} :</Label>
                            {Object.keys(groupByDatas).sort().map((key) => {
                                const groupFind = groups.find((g) => g.value === parseInt(key))
                                return (
                                    <RadioGroup
                                        value={dataChoice}
                                        onChange={(e) => {
                                            setDataChoice(e.target.value)
                                            setFormatChoice(dataOptions[e.target.value]?.formats?.[0]?.type)
                                        }}
                                        sx={{ width: '100%' }}
                                        key={key}
                                    >
                                        {dataOptions.filter((d) => groupFind ? d.group === parseInt(key) : !hasValue(d.group)).map(o => (
                                            <FormControlLabel
                                                value={o?.code}
                                                control={<Radio color='primary' />}
                                                key={o.code}
                                                label={o?.name}
                                                sx={{
                                                    margin: 0,
                                                    marginTop: '9px',
                                                    paddingBottom: '9px',
                                                }}
                                            />
                                        ))}
                                    </RadioGroup>
                                )
                            })}
                        </Grid>
                        <Grid item xs={6} className='padding-left-1' style={settingsStyle}>
                            {!!(exportObj && exportObj.settingsContent) && exportObj.settingsContent}
                            <Label>{i18n.type} :</Label>
                            <RadioGroup
                                value={formatChoice}
                                onChange={(e) => setFormatChoice(e.target.value)}
                                sx={{ width: '100%' }}
                            >
                                {exportObj?.formats?.map((d) => ({ ...d, code: d.type, name: d.type })).map(o => (
                                    <FormControlLabel
                                        value={o?.code}
                                        control={<Radio color='primary' />}
                                        key={o.code}
                                        label={o?.name}
                                        sx={{
                                            margin: 0,
                                            marginTop: '9px',
                                            paddingBottom: '9px',
                                        }}
                                    />
                                ))}
                            </RadioGroup>
                        </Grid>
                    </Grid>
                ) : <p>{i18n.chooseExportFormat}</p>}
            </DialogContentMUI>
            <DialogActionsMUI>
                <Grid container justifyContent='space-between'>
                    <Grid item xs={2}>
                        <RedButton onClick={onClose}>{i18n.close}</RedButton>
                    </Grid>

                    {data.length > 1 ? (
                        <Grid item xs={2}>
                            <SecondaryButton onClick={getCallback}>{i18n.export}</SecondaryButton>
                        </Grid>
                    ) : (
                        <Grid item xs='auto' container>
                            {exportObj?.formats?.map((d) => ({ ...d, code: d.type, name: d.type })).map(o => (
                                <Grid item key={o.type} className='padding-left-2'>
                                    <SecondaryButton onClick={() => {
                                        o.callback()
                                        if (closeOnExport) {
                                            onClose()
                                        }
                                    }}
                                    >{o?.name}</SecondaryButton>
                                </Grid>
                            ))}
                        </Grid>
                    )}
                </Grid>
            </DialogActionsMUI>
        </Dialog>
    )
}

ExportFileModal.propTypes = {
    open: PropTypes.bool.isRequired,
    title: PropTypes.string,
    onClose: PropTypes.func.isRequired,
    closeOnExport: PropTypes.bool,
    data: PropTypes.arrayOf(PropTypes.shape({
        name: PropTypes.string,
        group: PropTypes.number,
        settingsContent: PropTypes.element,
        formats: PropTypes.arrayOf(PropTypes.shape({
            type: PropTypes.string,
            callback: PropTypes.func,
        })),
    })).isRequired,
    grouped: PropTypes.bool,
    groups: PropTypes.arrayOf(PropTypes.shape({
        value: PropTypes.number,
        name: PropTypes.string,
    })),
    maxWidth: PropTypes.oneOf(['xs', 'sm', 'md', 'lg', 'xl']),
    settingsStyle: PropTypes.shape({}),
}

export default ExportFileModal