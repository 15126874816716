export default class DtoMeasureStats {
    constructor(obj) {
        this.typeId = obj.typeId
        this.label = obj.label
        this.countTotal = obj.countTotal
        this.startDate = obj.startDate
        this.endDate = obj.endDate
        this.control = obj.control
        this.validation = obj.validation
        this.color = obj.color
        this.isPiezo = obj.isPiezo
        this.warningLimit = obj.warningLimit
        this.alertLimit = obj.alertLimit
        this.unit = obj.unit
        this.codeUnit = obj.codeUnit
        this.showData = obj.showData
        this.order = obj.order
    }
}
