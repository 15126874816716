export default class DtoUser {
    constructor(obj) {
        this.login = obj.login
        this.name = obj.name
        this.firstname = obj.firstname
        this.password = obj.password
        this.isAdmin = obj.isAdmin || obj.admin
        this.contributorCode = obj.contributorCode
        this.metadata = obj.metadata
        this.piezoFilter = obj.piezoFilter
        this.qualitoFilter = obj.qualitoFilter
        this.pluvioFilter = obj.pluvioFilter
        this.hydroFilter = obj.hydroFilter
        this.contactCode = obj.contactCode
        this.organism = obj.organism
        this.labo = obj.labo
        this.startDate = obj.startDate
        this.endDate = obj.endDate
        this.updateDate = obj.updateDate
        this.loginMaj = obj.loginMaj
        this.token = obj.token
        this.resetPassword = obj.resetPassword
        this.isTechnicien = obj.isTechnicien
        this.consultant = obj.consultant
        this.connexionAttempts = obj.connexionAttempts
        this.blocked = obj.blocked
        this.lastConnexion = obj.lastConnexion
        this.picture = obj.picture
        this.mail = obj.mail
        this.mobile = obj.mobile
        this.tel = obj.tel
        this.postal = obj.postal
        this.town = obj.town
        this.beginDate = obj.beginDate
    }
}
