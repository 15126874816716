module.exports = {
    RECEIVE_HYDROMETRIC_STATION: 'RECEIVE_HYDROMETRIC_STATION',
    RECEIVE_ALL_HYDROMETRIC_STATIONS: 'RECEIVE_ALL_HYDROMETRIC_STATIONS',
    RECEIVE_HYDROMETRY_THRESHOLDS: 'RECEIVE_HYDROMETRY_THRESHOLDS',

    // TODO : Move this list on other global constant file
    MONTHS: ['january', 'february', 'march', 'april', 'may', 'june', 'july', 'august', 'september', 'october', 'november', 'december'],
    ALL: 'ALL',
    MAX: 'MAX',
    MIN: 'MIN',
    AVERAGE: 'AVERAGE',
    HYDROMETER_HEIGHT_TYPE: 4,

    // ---------------------
    WINDOW: 'window',
    POINT: 'point',
    MOVING: 'moving',
    NEWPOINT: 'newPoint',
    VALIDATION: 'validation',
    HISTORIC: 'historic',
    PURGE: 'purge',
    IMPORT: 'import',
    COMPENSATION: 'compensation',
    CORRECTION: 'correction',
    OPTIONS: 'options',
    THRESHOLDS: 'thresholds',
    EVENT: 'event',
    AUTO: 'auto',
    MANUAL: 'manual',
    RESET: 'reset',
    DELETE: 'delete',
    // ---------------------

    // ---------------------
}