/* eslint-disable react-hooks/exhaustive-deps */
import { countBy } from 'lodash'
import { useMemo, useState } from 'react'

const useStateProgress = (states = []) => {
    const [defaultStates] = useState(states)
    const total = defaultStates.length

    const {
        idle = 0,
        loading = 0,
        succeeded = 0,
        rejected = 0,
    } = useMemo(() => countBy(states), [...states])

    const progress = succeeded + rejected

    return {
        progress: total ? (progress / total) * 100 : 100,
        isLoaded: progress === total,
        loading,
        succeeded,
        rejected,
        idle,
    }
}

export default useStateProgress