import { Grid, useMediaQuery } from '@mui/material'
import PropTypes from 'prop-types'
import React, { useMemo } from 'react'
import ReactECharts from 'echarts-for-react'
import * as echarts from 'echarts/lib/echarts'
import i18n from 'simple-react-i18n'
import { shallowEqual, useDispatch, useSelector } from 'react-redux'
import { countBy, groupBy, keys, maxBy, uniqWith } from 'lodash'
import { INDICATORS_COLORS } from 'utils/constants/ColorTheme'
import DtoObservatoryFollowData from '../follows/dto/DtoObservatoryFollowData'
import DtoObservatoryFollowResult from '../follows/dto/DtoObservatoryFollowResult'
import { StyledFieldSet, StyledLegend } from 'components/StyledElements'
import { getYear } from 'utils/DateUtil'
import { getNumberFormat } from 'utils/NumberUtil'
import useProgressDispatch from 'utils/customHook/useProgressDispatch'
import FollowAction from '../follows/actions/FollowAction'
import ProgressBar from 'components/progress/ProgressBar'
import { filterObsLinkedStations } from 'utils/StationUtils'
import { formatMilliers } from 'utils/StringUtil'

const UnitIndicatorsChart = ({
    indicators = [],
}) => {
    const groupedIndicators = {
        [i18n.notDetermined]: [],
        [i18n.normal]: [],
        [i18n.vigilance]: [],
        [i18n.crisis]: [],
        [i18n.noData]: [],
        ...groupBy(indicators, 'value'),
    }

    const getIndicatorColor = (key) => {
        if (key === i18n.notDetermined) {
            return INDICATORS_COLORS.BLACK
        } else if (key === i18n.crisis) {
            return INDICATORS_COLORS.RED
        } else if (key === i18n.vigilance) {
            return INDICATORS_COLORS.YELLOW
        } else if (key === i18n.noData) {
            return INDICATORS_COLORS.GREY
        }
        return INDICATORS_COLORS.BLUE
    }

    const formattedData = keys(groupedIndicators).map(key => {
        const data = groupedIndicators[key]
        return {
            value: data.length,
            name: `${data.length} ${key}`,
            itemStyle: {
                color: getIndicatorColor(key),
            },
        }
    })

    const option = {
        tooltip: {
            trigger: 'item',
            formatter: ({ marker, data: { name = '' } }) => `${marker} ${name}`,
        },
        legend: {
            orient: 'vertical',
            top: 'center',
            right: '10%',
            itemWidth: 28,
            itemHeight: 17,
            textStyle: {
                width: 300,
                overflow: 'break',
                fontSize: 13,
                padding: [0, 0, 0, 10],
            },
        },
        series: [
            {
                type: 'pie',
                radius: ['35%', '85%'],
                avoidLabelOverlap: false,
                label: {
                    show: false,
                    position: 'center',
                },
                labelLine: {
                    show: false,
                },
                data: formattedData,
                right: '55%',
            },
        ],
    }

    return (
        <ReactECharts
            echarts={echarts}
            option={option}
            notMerge={true}
            lazyUpdate={true}
            className={'row no-margin'}
            style={{ height: '100%' }}
        />
    )
}

UnitIndicatorsChart.propTypes = {
    indicators: PropTypes.arrayOf(PropTypes.instanceOf(DtoObservatoryFollowData)),
}

const UnitLinkedVolumes = ({}) => {
    const {
        linkedPiezosPrel,
    } = useSelector(store => ({
        linkedPiezosPrel: store.FollowReducer.linkedPiezosPrel,
    }), shallowEqual)

    const option = {
        series: [{
            type: 'bar',
            data: linkedPiezosPrel,
            itemStyle: {
                normal: {
                    color: 'blue',
                },
            },
            tooltip: {
                trigger: 'item',
                formatter: (d) => `${getYear(d.data.date)} : ${formatMilliers(d.value)} m3`,
            },
            barWidth: '60%',
        }],
        xAxis: [{
            type: 'category',
            position: 'bottom',
            data: linkedPiezosPrel.map(d => getYear(d.date)),
            gridIndex: 0,
            axisLabel: {
                show: true,
            },
            axisLine: { show: true },
            axisTick: { show: true },
            showSplitLine: true,
        }],
        yAxis: [{
            type: 'value',
            nameLocation: 'middle',
            name: 'm3',
            gridIndex: 0,
            showSplitLine: true,
            nameGap: (Math.log(maxBy(linkedPiezosPrel, 'value')?.value || 0, 10) * 10) - 1,
            min: 0,
            axisLabel: {
                formatter: getNumberFormat,
            },
        }],
        tooltip: {
            trigger: 'item',
        },
        height: 110,
        grid: {
            top: '25%',
            left: '5%',
            right: '5%',
            containLabel: true,
        },
    }

    return (
        <ReactECharts
            echarts = {echarts}
            option = {option}
            notMerge = {true}
            lazyUpdate = {true}
            className = {'row no-margin'}
            style = {{ height: '100%' }}
        />
    )
}

const UnitIndicatorsPanel = ({
    indicators = [],
}) => {
    const {
        productionUnits,
        linkedStations,
    } = useSelector(store => ({
        productionUnits: store.HomeReducer.productionUnits,
        linkedStations: store.HomeReducer.linkedStations,
    }), shallowEqual)

    const getFormattedIndicators = (unitIndicators) => {
        if (!unitIndicators.length) {
            return { value: i18n.notDetermined, color: INDICATORS_COLORS.BLACK }
        } else if (unitIndicators.some(d => ['red', 'indianred', 'darkmagenta'].includes(d.color) || (d?.value || '').toLowerCase().includes('alerte'))) {
            return { value: i18n.crisis, color: INDICATORS_COLORS.RED }
        } else if (unitIndicators.some(d => (d?.value || '').toLowerCase().includes('vigilance'))) {
            return { value: i18n.vigilance, color: INDICATORS_COLORS.YELLOW }
        } else if (unitIndicators.find(d => ['grey', 'gray'].includes(d.color))) {
            return { value: i18n.noData, color: INDICATORS_COLORS.GREY }
        }
        return { value: i18n.normal, color: INDICATORS_COLORS.BLUE }
    }

    const formattedUnits = useMemo(() => productionUnits.map(unit => {
        const unitIndicators = indicators.find(indic => indic.productionUnit === unit.name)?.data || []
        const filteredLinks = filterObsLinkedStations(linkedStations).filter(s => s.code === unit.code)
        const uniqLinks = uniqWith(filteredLinks, (linkA, linkB) => linkA.stationLinkedCode === linkB.stationLinkedCode && linkA.stationLinkedType === linkB.stationLinkedType)
        const linkedIndicators = uniqLinks.flatMap(link => indicators.find(indic => indic.id === link.stationLinkedId)?.data || [])

        return new DtoObservatoryFollowData(getFormattedIndicators([...unitIndicators, ...linkedIndicators]))
    }), [indicators, linkedStations, productionUnits])

    const formattedUnitsCrisis = useMemo(() => {
        return productionUnits.flatMap(unit => {
            const unitIndicators = indicators.find(indic => indic.productionUnit === unit.name)?.data || []
            const filteredLinks = filterObsLinkedStations(linkedStations).filter(s => s.code === unit.code)
            const uniqLinks = uniqWith(filteredLinks, (linkA, linkB) => linkA.stationLinkedCode === linkB.stationLinkedCode && linkA.stationLinkedType === linkB.stationLinkedType)
            const linkedIndicators = uniqLinks.flatMap(link => indicators.find(indic => indic.id === link.stationLinkedId)?.data || [])

            return [...unitIndicators, ...linkedIndicators]
        }).filter(indic => {
            const value = (indic?.value || '')?.toLowerCase()
            return ['red', 'indianred', 'darkmagenta'].includes(indic?.color) || value.includes('alerte') || value.includes('vigilance')
        })
    }, [productionUnits, indicators, linkedStations])

    const groupedIndicatorsCrisis = countBy(formattedUnitsCrisis, d => d?.value.split(' : ')?.[0])

    return (
        <Grid container sx={{ height: '100%' }} justifyContent='space-between'>
            {!!formattedUnits.length && (
                <>
                    <Grid item xs={3.5}>
                        <StyledFieldSet style={{ width: '100%', height: '100%', margin: 0, padding: '0 1rem' }}>
                            <StyledLegend>{i18n.statuts}</StyledLegend>
                            <UnitIndicatorsChart indicators={formattedUnits} />
                        </StyledFieldSet>
                    </Grid>
                    <Grid container item xs={3.5} alignItems='center' sx={{ fontWeight: '600' }}>
                        {!!formattedUnitsCrisis.length && (
                            <StyledFieldSet style={{ width: '100%', height: '100%', margin: 0, padding: '0 1rem' }}>
                                <StyledLegend>{i18n.overruns}</StyledLegend>
                                <Grid container alignItems='center' sx={{ height: '100%' }}>
                                    {keys(groupedIndicatorsCrisis).map(key => (
                                        <Grid container>
                                            <Grid item xs={10}>
                                                {key}
                                            </Grid>
                                            <Grid item xs={2}>
                                                {groupedIndicatorsCrisis[key]}
                                            </Grid>
                                        </Grid>
                                    ))}
                                </Grid>
                            </StyledFieldSet>
                        )}
                    </Grid>
                    <Grid item xs={3.5}>
                        <StyledFieldSet style={{ width: '100%', height: '100%', margin: 0, padding: '0 1rem' }}>
                            <StyledLegend>{i18n.annualVolumesWithdrawn}</StyledLegend>
                            <UnitLinkedVolumes />
                        </StyledFieldSet>
                    </Grid>
                </>
            )}
        </Grid>
    )
}

UnitIndicatorsPanel.propTypes = {
    indicators: PropTypes.arrayOf(PropTypes.instanceOf(DtoObservatoryFollowResult)),
}

const ProductionUnitStats = ({
    indicators = [],
}) => {
    const {
        productionUnits,
    } = useSelector(store => ({
        productionUnits: store.HomeReducer.productionUnits,
    }), shallowEqual)

    const dispatch = useDispatch()

    const mdMatches = useMediaQuery((t) => t.breakpoints.up('md'))

    const { isLoaded, progress } = useProgressDispatch(() => [
        dispatch(FollowAction.fetchLinkedPiezosPrel(productionUnits.map(uaf => uaf.id))),
    ], [])

    return (
        <Grid container>
            <Grid
                container
                item
                xs={12}
                justifyContent='space-between'
                alignItems='center'
                sx={{
                    backgroundColor: 'white',
                    padding: mdMatches ? '0.7rem 3rem' : '0.7rem 3rem',
                    height: '13rem',
                    boxShadow: '0px 3px 32px 0px rgb(0 0 0 / 10%)',
                }}
            >
                {!isLoaded ? (
                    <ProgressBar progress={progress} />
                ) : (
                    <Grid item xs={12}>
                        <UnitIndicatorsPanel
                            indicators={indicators}
                        />
                    </Grid>
                )}
            </Grid>
        </Grid>
    )
}

ProductionUnitStats.propTypes = {
    indicators: PropTypes.arrayOf(PropTypes.instanceOf(DtoObservatoryFollowResult)),
}

export default ProductionUnitStats