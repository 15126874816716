import React, { useEffect, useState } from 'react'
import { Divider, Drawer, Grid, Icon, List, ListItem, ListItemText, useMediaQuery } from '@mui/material'
import { mainBlack } from 'components/styled/Theme'
import { useDispatch } from 'react-redux'
import i18n from 'simple-react-i18n'
import PropTypes from 'prop-types'
import { mainGrey, mainWhite } from 'pages/online/components/styled/theme'
import HomeAction from 'pages/home/actions/HomeAction'
import { getUrlScreen } from 'utils/mapUtils/UrlUtils'
import { mainBlue } from 'utils/constants/ColorTheme'
import DtoCMSEvent from 'pages/cms/dto/DtoCMSEvent'

const TAB_OBS = 1
const TAB_CONTACT = 2
const TAB_INFOS = 3
const TAB_LOGIN = 4
const TAB_PASSWORD = 5

const Option = ({
    active = false,
    title = '',
    onClick = () => {},
    xs,
}) => (
    <Grid
        item
        xs={xs}
        className='clickable'
        sx={{
            backgroundColor: active && mainBlack,
            padding: '1rem 0',
            color: active ? mainWhite : mainBlack,
            fontWeight: 'bold',
            textAlign: 'center',
            textTransform: 'uppercase',
            borderRadius: '50px',
            transition: 'all 0.3s ease-in-out',
            '&:hover': {
                backgroundColor: mainBlack,
                color: mainWhite,
            },
        }}
        onClick={onClick}
    >
        {title}
    </Grid>
)

Option.propTypes = {
    active: PropTypes.bool,
    title: PropTypes.string,
    onClick: PropTypes.func,
    xs: PropTypes.number,
}

const selectedStyle = { cursor: 'pointer', textTransform: 'uppercase', justifyContent: 'center', textAlign: 'center', backgroundColor: mainBlack, color: 'white' }
const notSelectedStyle = { cursor: 'pointer', textTransform: 'uppercase', justifyContent: 'center', textAlign: 'center', backgroundColor: mainGrey, color: mainBlack }
const styleMenu = { textAlign: 'center', cursor: 'pointer', display: 'flex', alignItems: 'center', padding: '0 20px' }

const LoginMenu = ({
    cmsHome,
    cmsInfos,
    tab,
    onChangeTab = () => {},
}) => {
    const [openBurger, setOpenBurger] = useState(false)

    const lgMatches = useMediaQuery((t) => t.breakpoints.up('lg'))

    const dispatch = useDispatch()

    const url = getUrlScreen().split('/')[1]

    useEffect(() => {
        dispatch(HomeAction.setLoadingData(false))
    }, [url])

    const setTab = (value) => {
        onChangeTab(value)
        setOpenBurger(false)
    }

    return (
        <Grid container item justifyContent='flex-end' alignItems='center' sx={{ padding: '1rem 0 0.5rem', height: '50px' }}>
            {lgMatches ? (
                <>
                    {cmsHome && (
                        <Grid
                            item
                            className='clickable'
                            onClick={() => setTab(TAB_OBS)}
                            style={styleMenu}
                        >
                            <span
                                style={{
                                    padding: tab === TAB_OBS ? '0.65rem 0 0.36rem' : 0,
                                    borderBottom: tab === TAB_OBS && `3px solid ${mainBlue}`,
                                }}
                            >
                                {i18n.observatoireLabel}
                            </span>
                        </Grid>
                    )}
                    <Grid
                        item
                        className='clickable'
                        onClick={() => setTab(TAB_CONTACT)}
                        style={styleMenu}
                    >
                        <span
                            style={{
                                padding: tab === TAB_CONTACT ? '0.65rem 0 0.36rem' : 0,
                                borderBottom: tab === TAB_CONTACT && `3px solid ${mainBlue}`,
                            }}
                        >
                            {i18n.contact}
                        </span>
                    </Grid>
                    {cmsInfos && (
                        <Grid
                            item
                            className='clickable'
                            onClick={() => setTab(TAB_INFOS)}
                            style={styleMenu}
                        >
                            <span
                                style={{
                                    padding: tab === TAB_INFOS ? '0.65rem 0 0.36rem' : 0,
                                    borderBottom: tab === TAB_INFOS && `3px solid ${mainBlue}`,
                                }}
                            >
                                {i18n.informations}
                            </span>
                        </Grid>
                    )}
                    <Grid
                        item
                        className='clickable'
                        onClick={() => setTab(TAB_LOGIN)}
                        style={styleMenu}
                    >
                        <span
                            style={{
                                padding: [TAB_LOGIN, TAB_PASSWORD].includes(tab) ? '0.65rem 0 0.36rem' : 0,
                                borderBottom: [TAB_LOGIN, TAB_PASSWORD].includes(tab) && `3px solid ${mainBlue}`,
                            }}
                        >
                            {i18n.connexion}
                        </span>
                    </Grid>
                    <Grid
                        item
                        style={{ cursor: 'pointer', padding: '0 20' }}
                        onClick={() => setTab(TAB_LOGIN)}
                    >
                        <Icon style={{ fontSize: 35 }}>home</Icon>
                    </Grid>
                </>
            ) : (
                <Grid item xs={12} container justifyContent='center'>
                    <Icon style={{ fontSize: 35 }} className='clickable' onClick={() => setOpenBurger(true)}>menu</Icon>
                </Grid>
            )}
            <Drawer
                anchor='top'
                open={openBurger}
                onClose={() => setOpenBurger(false)}
                style={{ zIndex: '2000' }}
            >
                <List disablePadding>
                    <ListItem style={tab === TAB_OBS ? selectedStyle : notSelectedStyle } onClick={() => setTab(TAB_OBS)}>
                        <ListItemText primary={i18n.observatoireLabel} />
                    </ListItem>
                    <Divider />
                    <ListItem style={tab === TAB_CONTACT ? selectedStyle : notSelectedStyle } onClick={() => setTab(TAB_CONTACT)}>
                        <ListItemText primary={i18n.contact} />
                    </ListItem>
                    <Divider />
                    <ListItem style={tab === TAB_INFOS ? selectedStyle : notSelectedStyle } onClick={() => setTab(TAB_INFOS)}>
                        <ListItemText primary={i18n.informations} />
                    </ListItem>
                    <Divider />
                    <ListItem style={tab === TAB_LOGIN ? selectedStyle : notSelectedStyle } onClick={() => setTab(TAB_LOGIN)}>
                        <ListItemText primary={i18n.connexion} />
                    </ListItem>
                    <Divider />
                </List>
            </Drawer>
        </Grid>
    )
}

LoginMenu.propTypes = {
    tab: PropTypes.string,
    onChangeTab: PropTypes.func,
    cmsHome: PropTypes.instanceOf(DtoCMSEvent),
    cmsInfos: PropTypes.instanceOf(DtoCMSEvent),
}

export default LoginMenu
