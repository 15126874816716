export default class DtoObservatoryFollowResult {
    constructor(obj) {
        this.id = obj.id
        this.code = obj.code
        this.name = obj.name
        this.city = obj.city
        this.aquifer = obj.aquifer
        this.aquiferId = obj.aquiferId
        this.nature = obj.nature
        this.fieldMode = obj.fieldMode
        this.productionUnit = obj.productionUnit
        this.typeName = obj.typeName
        this.x = obj.x
        this.y = obj.y
        this.projection = obj.projection
        this.data = obj.data // Seq[DtoObservatoryFollowData]
    }
}