import i18n from 'simple-react-i18n'
import ToastrAction from 'toastr/actions/ToastrAction'
import { createAsyncThunk } from '@reduxjs/toolkit'
import LogAction from 'log/actions/LogAction'
import ApplicationConf from 'conf/ApplicationConf'
import { genericPromise, genericPromiseHome } from 'utils/ActionUtils'
import { OBSERVATORY_STATION_TYPE_NAME, STATION_TYPE_NAME } from 'pages/home/constants/StationConstants'

const CmsAction = {

    fetchCMSEvents: createAsyncThunk(
        'fetchCMSEvents',
        async(_, { rejectWithValue, dispatch }) => {
            try {
                const response = await genericPromiseHome(ApplicationConf.cms.getAll())
                return response
            } catch (err) {
                dispatch(LogAction.logError(`${i18n.fetchError} ${i18n.articles}: ${err}`))
                dispatch(ToastrAction.error(i18n.fetchError + i18n.articles))
                return rejectWithValue(err)
            }
        }
    ),

    fetchCMSEvent: (id) => dispatch => genericPromiseHome(ApplicationConf.cms.get(id))
        .then(cms => cms)
        .catch(err => {
            dispatch(LogAction.logError(`${i18n.fetchError} ${i18n.article}: ${err}`))
            dispatch(ToastrAction.error(i18n.fetchError + i18n.article))
        }),

    fetchCmsByCateg: (categId) => dispatch => genericPromiseHome(ApplicationConf.cms.getByCategory(categId))
        .then((cmsEvents = []) => cmsEvents)
        .catch(err => {
            dispatch(LogAction.logError(`${i18n.fetchError} ${i18n.article}: ${err}`))
            dispatch(ToastrAction.error(i18n.fetchError + i18n.article))
        }),

    fetchCmsActualities: createAsyncThunk(
        'fetchCmsActualities',
        async(_, { rejectWithValue, dispatch }) => {
            try {
                const response = await genericPromise(ApplicationConf.cms.getByCategory(4))
                return response
            } catch (err) {
                dispatch(LogAction.logError(`${i18n.fetchError}: ${err}`))
                dispatch(ToastrAction.error(i18n.fetchError))
                return rejectWithValue(err)
            }
        }
    ),

    fetchCmsTitle: createAsyncThunk(
        'fetchCmsTitle',
        async(cmsTitleId, { rejectWithValue, dispatch }) => {
            try {
                const response = await genericPromise(ApplicationConf.cms.get(cmsTitleId))
                return response
            } catch (err) {
                dispatch(LogAction.logError(`${i18n.fetchError} ${i18n.article}: ${err}`))
                dispatch(ToastrAction.error(i18n.fetchError + i18n.article))
                return rejectWithValue(err)
            }
        }
    ),

    fetchCmsByCategory: createAsyncThunk(
        'fetchCmsByCategory',
        async(id, { rejectWithValue, dispatch }) => {
            try {
                const response = await genericPromise(ApplicationConf.cms.getByCategory(id))
                return response
            } catch (err) {
                dispatch(LogAction.logError(`${i18n.fetchError}: ${err}`))
                dispatch(ToastrAction.error(i18n.fetchError))
                return rejectWithValue(err)
            }
        }
    ),

    fetchCMSCategories: createAsyncThunk(
        'fetchCMSCategories',
        async(_, { rejectWithValue, dispatch }) => {
            try {
                const response = await genericPromise(ApplicationConf.cms.category())
                return response
            } catch (err) {
                dispatch(LogAction.logError(`${i18n.fetchError + i18n.categories} : ${err}`))
                dispatch(ToastrAction.error(i18n.fetchError + i18n.categories))
                return rejectWithValue(err)
            }
        }
    ),

    fetchCmsMessages: createAsyncThunk(
        'fetchCMSMessages',
        async (id, { rejectWithValue, dispatch }) => {
            try {
                const response = await genericPromise(ApplicationConf.cms.messages(id))
                return response
            } catch (err) {
                dispatch(LogAction.logError(`${i18n.fetchError + i18n.categories} : ${err}`))
                dispatch(ToastrAction.error(i18n.fetchError + i18n.categories))
                return rejectWithValue(err)
            }
        }
    ),

    addMessage: createAsyncThunk(
        'addCMSMessages',
        async (body, { rejectWithValue, dispatch }) => {
            try {
                const response = await genericPromise(ApplicationConf.cms.addMessage(), 'PUT', body)
                ToastrAction.success(i18n.elementAddSuccess)
                return response
            } catch (err) {
                dispatch(LogAction.logError(`${i18n.updateError + i18n.messages} : ${err}`))
                dispatch(ToastrAction.error(i18n.updateError + i18n.messages))
                return rejectWithValue(err)
            }
        }
    ),

    followCms: createAsyncThunk(
        'followCms',
        async ({ id, login }, { rejectWithValue, dispatch }) => {
            try {
                const response = await genericPromise(ApplicationConf.cms.followCms(id, login), 'PUT')
                ToastrAction.success(i18n.successfulSubscription)
                return response
            } catch (err) {
                dispatch(LogAction.logError(`${i18n.updateError + i18n.followCms} : ${err}`))
                dispatch(ToastrAction.error(i18n.updateError + i18n.followCms))
                return rejectWithValue(err)
            }
        }
    ),

    unFollowCms: createAsyncThunk(
        'unFollowCms',
        async ({ id, login }, { rejectWithValue, dispatch }) => {
            try {
                const response = await genericPromise(ApplicationConf.cms.followCms(id, login), 'DELETE')
                ToastrAction.success(i18n.successfulUnsubscription)
                return response
            } catch (err) {
                dispatch(LogAction.logError(`${i18n.deleteError + i18n.followCms} : ${err}`))
                dispatch(ToastrAction.error(i18n.deleteError + i18n.followCms))
                return rejectWithValue(err)
            }
        }
    ),

    createCMSEvent: createAsyncThunk(
        'createCMSEvent',
        async (event, { rejectWithValue, dispatch }) => {
            try {
                const response = await genericPromise(ApplicationConf.cms.getAll(), 'POST', event)
                ToastrAction.success(i18n.elementCreateSuccess)
                return response
            } catch (err) {
                dispatch(LogAction.logError(`${`${i18n.createError} FAQ`} : ${err}`))
                dispatch(ToastrAction.error(`${i18n.createError} FAQ`))
                return rejectWithValue(err)
            }
        }
    ),

    addEvent: createAsyncThunk(
        'addEvent',
        async ({ stationType, stationId, event }, { rejectWithValue, dispatch }) => {
            try {
                const route = (() => {
                    switch (stationType) {
                        case STATION_TYPE_NAME.quality:
                            return ApplicationConf.qualitometers.events(stationId)
                        case OBSERVATORY_STATION_TYPE_NAME.catchment:
                        case STATION_TYPE_NAME.piezometry:
                            return ApplicationConf.piezometer.events(stationId)
                        case STATION_TYPE_NAME.pluviometry:
                            return ApplicationConf.pluviometer.events(stationId)
                        case STATION_TYPE_NAME.hydrometry:
                            return ApplicationConf.hydrometricStation.events(stationId)
                        default:
                            return ''
                    }
                })()
                const response = await genericPromise(route, 'POST', event)
                ToastrAction.success(i18n.elementCreateSuccess)
                return response
            } catch (err) {
                dispatch(LogAction.logError(`${`${i18n.createError}`} : ${err}`))
                dispatch(ToastrAction.error(`${i18n.createError}`))
                return rejectWithValue(err)
            }
        }
    ),
}

export default CmsAction
