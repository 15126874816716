/* eslint-disable react/forbid-prop-types */
import { Grid, Icon, InputAdornment, TextField } from '@mui/material'
import React, { useEffect, useMemo, useState } from 'react'
import i18n from 'simple-react-i18n'
import useBoolean from 'utils/customHook/useBoolean'
import PropTypes from 'prop-types'
import useTitle from 'utils/customHook/useTitle'
import { shallowEqual, useDispatch, useSelector } from 'react-redux'
import UnitCard from 'pages/online/units/UnitCard'
import { searchAllCharacters } from 'utils/StringUtil'
import DocumentCard from 'pages/online/documents/DocumentCard'
import { mainGrey } from 'pages/online/components/styled/theme'
import { push } from 'connected-react-router'
import { getMarkerByStationType } from 'utils/StationUtils'
import PluviometerDto from 'pages/home/dto/PluviometerDto'
import DtoHydrometricStation from 'pages/home/dto/DtoHydrometricStation'
import DtoPiezometerLight from 'pages/home/dto/DtoPiezometerLight'
import DtoQualitometerLight from 'pages/home/dto/DtoQualitometerLight'
import DtoInstallation from 'pages/dto/DtoInstallation'
import ResourceCard from 'pages/online/resources/ResourceCard'
import useDebounce from 'utils/customHook/useDebounce'
import { AccordionDetailsMUI, AccordionMUI, AccordionSummaryMUI } from 'components/styled/Accordions'
import { PaginatedList } from 'components/Pagination'
import HomeAction from 'pages/home/actions/HomeAction'
import ResourcesAction from 'pages/online/resources/actions/ResourcesAction'
import CmsAction from 'pages/cms/actions/CmsAction'
import { OBSERVATORY_STATION_TYPE_NAME, STATION_TYPE_NAME } from 'pages/home/constants/StationConstants'

const RESOURCE = 'RESOURCE'
const UNIT = 'UNIT'
const WORK = 'WORK'
const DOCUMENT = 'DOCUMENT'
const ACTUALITY = 'ACTUALITY'

const styleButton = {
    color: 'white',
    verticalAlign: 'center',
    padding: '0 20',
    height: 30,
    fontSize: 15,
    display: 'flex',
    alignItems: 'center',
}

const TypeButton = ({
    label = '',
    isSelected = true,
    toggle = () => { },
    style = {},
}) => (
    <Grid
        item
        className='clickable bold'
        onClick={toggle}
        style={{
            ...styleButton,
            backgroundColor: isSelected ? '#53A1FF' : 'D5D8DE',
            ...style,
        }}
    >
        {i18n[label]}
    </Grid>
)

TypeButton.propTypes = {
    label: PropTypes.string,
    isSelected: PropTypes.bool,
    toggle: PropTypes.func,
    style: PropTypes.object,
}

const WorkCard = ({
    work = {},
}) => {
    const dispatch = useDispatch()
    const {
        cities,
    } = useSelector(store => ({
        cities: store.HomeReducer.cities,
    }))

    const city = cities.find(c => c.code === work.townCode)

    const img = getMarkerByStationType(work.typeName)

    return (
        <Grid container item xs={12} sx={{ borderBottom: `solid 2px ${mainGrey}`, padding: '0.5em 0' }}>
            <Grid container item xs={12} sx={{ cursor: 'pointer' }}
                onClick={() => {
                    if ([OBSERVATORY_STATION_TYPE_NAME.catchment,
                        STATION_TYPE_NAME.piezometry,
                        STATION_TYPE_NAME.hydrometry,
                        STATION_TYPE_NAME.pluviometry,
                        STATION_TYPE_NAME.quality].includes(work.typeName)) {
                        dispatch(push(`follows/${work.typeName}/${work.id}`))
                    } else if (work.typeName === STATION_TYPE_NAME.productionUnit) {
                        dispatch(push(`units/${work.id}`))
                    } else if (work.typeName === STATION_TYPE_NAME.resource) {
                        dispatch(push(`resources/${work.id}`))
                    }
                }}
            >
                {!!img && (
                    <Grid item>
                        <img src={img} alt={work.typeName} style={{ height: 25 }} />
                    </Grid>
                )}
                {work.code && (
                    <Grid item sx={{ fontWeight: 'bold', fontSize: 20, marginLeft: '15px' }}>
                        <span>{work.code}</span>
                    </Grid>
                )}
                {city && (
                    <Grid item sx={{ fontWeight: 'bold', fontSize: 20, marginLeft: '15px' }}>
                        <span>{city.name || ''} ({city.code})</span>
                    </Grid>
                )}
                {work.name && (
                    <>
                        <Grid item sx={{ fontWeight: 'bold', fontSize: 20, marginLeft: '15px' }}>
                            <span>-</span>
                        </Grid>
                        <Grid item sx={{ fontWeight: 'bold', fontSize: 20, marginLeft: '15px' }}>
                            <span>{work.name}</span>
                        </Grid>
                    </>
                )}
            </Grid>
        </Grid>
    )
}

const SearchField = ({
    searchValue = '',
    setSearchValue = () => { },
}) => {
    const [value, setValue] = useState(searchValue)
    useDebounce(() => setSearchValue(value), 500, [value])

    return (
        <TextField
            id='search'
            label={i18n.search}
            value={value}
            onChange={(e) => setValue(e.target.value)}
            variant='outlined'
            className='margin-bottom-2'
            style={{ width: '400px' }}
            InputProps={{
                endAdornment: (
                    <InputAdornment position='end'>
                        <Icon>search</Icon>
                    </InputAdornment>
                ),
            }}
        />
    )
}

SearchField.propTypes = {
    searchValue: PropTypes.string,
    setSearchValue: PropTypes.func,
}

WorkCard.propTypes = {
    work: PropTypes.oneOfType([
        PropTypes.instanceOf(DtoHydrometricStation),
        PropTypes.instanceOf(DtoPiezometerLight),
        PropTypes.instanceOf(PluviometerDto),
        PropTypes.instanceOf(DtoQualitometerLight),
        PropTypes.instanceOf(DtoInstallation),
    ]),
}

const getLabelNbElements = (nbElements) => `${nbElements} ${nbElements > 1 ? i18n.elements : i18n.element}`

const Research = ({
    location: { search = '' } = {},
}) => {
    const dispatch = useDispatch()
    const {
        productionUnits,
        cmsEvents,
        piezometersLight,
        pluviometers,
        qualitometersLight,
        hydrometers,
        resources,
    } = useSelector(store => ({
        productionUnits: store.HomeReducer.productionUnits,
        cmsEvents: store.CmsReducer.cmsEvents,
        piezometersLight: store.HomeReducer.piezometersLight,
        pluviometers: store.HomeReducer.pluviometers,
        qualitometersLight: store.HomeReducer.qualitometersLight,
        hydrometers: store.HomeReducer.hydrometers,
        resources: store.ResourcesReducer.resources,
    }), shallowEqual)

    const actualities = useMemo(() => cmsEvents.filter((c) => c.idCategory === 2), [cmsEvents])
    const documents = useMemo(() => cmsEvents.filter((c) => c.idCategory !== 2), [cmsEvents])
    const works = useMemo(() => [...piezometersLight, ...pluviometers, ...qualitometersLight, ...hydrometers], [hydrometers, piezometersLight, pluviometers, qualitometersLight])

    useEffect(() => {
        if (!productionUnits.length) {
            dispatch(HomeAction.fetchProductionUnits())
        }
        if (!resources.length) {
            dispatch(ResourcesAction.fetchResources())
        }
        if (!cmsEvents.length) {
            dispatch(CmsAction.fetchCMSEvents())
        }
        if (!piezometersLight.length) {
            dispatch(HomeAction.fetchPiezometersLight())
        }
        if (!hydrometers.length) {
            dispatch(HomeAction.fetchHydrometers())
        }
        if (!pluviometers.length) {
            dispatch(HomeAction.fetchPluviometers())
        }
        if (!qualitometersLight.length) {
            dispatch(HomeAction.fetchQualitometersLight())
        }
    }, [])

    useEffect(() => {
        if (resources.length !== 0) {
            dispatch(HomeAction.fetchAllLinkedStation({ codes: resources.map((p) => p.code), stationType: 9 }))
        }
    }, [resources])

    const { value: displayResources, toggle: toggleDisplayResources } = useBoolean(true)
    const { value: displayUnits, toggle: toggleDisplayUnits } = useBoolean(true)
    const { value: displayWorks, toggle: toggleDisplayWorks } = useBoolean(true)
    const { value: displayDocuments, toggle: toggleDisplayDocuments } = useBoolean(true)
    const { value: displayActualities, toggle: toggleDisplayActualities } = useBoolean(true)

    const [searchValue, setSearchValue] = useState(search.slice(13)) // remove '?searchValue='

    const formattedSearchValue = searchAllCharacters(searchValue)

    useTitle(() => [{
        title: i18n.research,
        href: `/research${search}`,
    }])

    const filteredProductionUnits = useMemo(() => {
        return productionUnits.filter(p => {
            const searchLabel = searchAllCharacters(`${p.code}##${p.name}`)
            return searchLabel.includes(formattedSearchValue)
        }).map(u => ({ ...u, type: UNIT }))
    }, [formattedSearchValue, productionUnits])

    const filteredDocuments = useMemo(() => {
        return documents.filter(d => {
            const searchLabel = searchAllCharacters(`${d.author}##${d.title}`)
            return searchLabel.includes(formattedSearchValue)
        }).map(d => ({ ...d, type: DOCUMENT }))
    }, [documents, formattedSearchValue])

    const filteredActualities = useMemo(() => {
        return actualities.filter(d => {
            const searchLabel = searchAllCharacters(`${d.author}##${d.title}`)
            return searchLabel.includes(formattedSearchValue)
        }).map(a => ({ ...a, type: ACTUALITY }))
    }, [actualities, formattedSearchValue])

    const filteredWorks = useMemo(() => {
        return works.filter(w => {
            const searchLabel = searchAllCharacters(`${w.code}##${w.name}`)
            return searchLabel.includes(formattedSearchValue)
        }).map(w => ({ ...w, type: WORK }))
    }, [formattedSearchValue, works])

    const filteredResources = useMemo(() => {
        return resources.filter(r => {
            const searchLabel = searchAllCharacters(`${r.code}##${r.name}`)
            return searchLabel.includes(formattedSearchValue)
        }).map(r => ({ ...r, type: RESOURCE }))
    }, [formattedSearchValue, resources])

    const resultResources = displayResources ? filteredResources : []
    const resultUnits = displayUnits ? filteredProductionUnits : []
    const resultWorks = displayWorks ? filteredWorks : []
    const resultDocuments = displayDocuments ? filteredDocuments : []
    const resultActualities = displayActualities ? filteredActualities : []

    const allResults = [
        ...resultResources,
        ...resultUnits,
        ...resultWorks,
        ...resultDocuments,
        ...resultActualities,
    ]


    return (
        <>
            <Grid container style={{ padding: '10 50', backgroundColor: 'rgb(231, 234, 239)' }}>
                <TypeButton label='resources' isSelected={displayResources} toggle={toggleDisplayResources} />
                <TypeButton label='units' isSelected={displayUnits} toggle={toggleDisplayUnits} style={{ marginLeft: '2px' }} />
                <TypeButton label='works' isSelected={displayWorks} toggle={toggleDisplayWorks} style={{ marginLeft: '2px' }} />
                <TypeButton label='documents' isSelected={displayDocuments} toggle={toggleDisplayDocuments} style={{ marginLeft: '2px' }} />
                <TypeButton label='actualities' isSelected={displayActualities} toggle={toggleDisplayActualities} style={{ marginLeft: '2px' }} />
            </Grid>
            <Grid container style={{ padding: '15 50' }}>
                <Grid item xs={12}>
                    <SearchField searchValue={searchValue} setSearchValue={setSearchValue} />
                </Grid>

                {
                    allResults.length === 0 && i18n.noDataToDisplay
                }

                {
                    allResults.length !== 0 && (
                        <Grid item xs={12}>
                            <AccordionMUI style={{ marginBottom: 30 }} defaultExpanded>
                                <AccordionSummaryMUI style={{ fontSize: 20 }}>{i18n.result} - {getLabelNbElements(allResults.length)}</AccordionSummaryMUI>
                                <AccordionDetailsMUI>
                                    <PaginatedList nbElements={allResults.length} nbElementsByPage={500}>
                                        {(startIndex, endIndex) => allResults.slice(startIndex, endIndex).map(r => (
                                            <Grid container>
                                                {r.type === RESOURCE && (<ResourceCard resource={r} hideAssociatedStation />)}
                                                {r.type === UNIT && (<UnitCard unit={r} hideAssociatedStation />)}
                                                {r.type === WORK && (<WorkCard work={r} />)}
                                                {r.type === DOCUMENT && (<DocumentCard cms={r} />)}
                                                {r.type === ACTUALITY && (<DocumentCard cms={r} />)}
                                            </Grid>
                                        ))}
                                    </PaginatedList>
                                </AccordionDetailsMUI>
                            </AccordionMUI>
                        </Grid>
                    )
                }
            </Grid>
        </>
    )
}

Research.propTypes = {
    location: PropTypes.shape({
        search: PropTypes.string,
    }),
}

export default Research