/* eslint-disable no-process-env */
import React, { useEffect, useMemo, useRef, useState } from 'react'
import { Checkbox, FormControlLabel, Grid, Icon, Radio, RadioGroup, Slider, Tooltip, useMediaQuery } from '@mui/material'
import PropTypes from 'prop-types'
import { shallowEqual, useDispatch, useSelector } from 'react-redux'
import i18n from 'simple-react-i18n'
import useTitle from 'utils/customHook/useTitle'
import { Feature, Map, Overlay, View } from 'ol'
import { ScaleLine, Rotate, defaults as defaultControls, FullScreen } from 'ol/control'
import TileLayer from 'ol/layer/Tile'
import OSM from 'ol/source/OSM'
import { v4 as uuidv4 } from 'uuid'
import VectorSource from 'ol/source/Vector'
import { Point } from 'ol/geom'
import { fromLonLat, transform } from 'ol/proj'
import VectorLayer from 'ol/layer/Vector'
import SimpleTabSideList from 'pages/components/SimpleTabSideList'
import { mainBlack } from 'components/styled/Theme'
import { mainWhite } from 'pages/online/components/styled/theme'
import HomeAction from '../actions/HomeAction'
import { componentHasHabilitations, isAuthorized } from 'utils/HabilitationUtil'
import { HYDRO, PIEZO, PLUVIO, PRODUCTION, QUALITO } from '../constants/HabilitationConstants'
import { H_HYDRO_MODULE, H_PIEZO_MODULE, H_PLUVIO_MODULE, H_PRODUCTION_MODULE, H_QUALITO_MODULE } from '../constants/AccessRulesConstants'
import { OBSERVATORY_STATION_TYPE_NAME, STATION_TYPE_NAME } from '../constants/StationConstants'
import { compact, isNaN, isNil, keys, sortBy, uniqWith } from 'lodash'
import useStateProgress from 'utils/customHook/useStateProgress'
import ProgressBar from 'components/progress/ProgressBar'
import ActualitiesCard from 'pages/online/documents/ActualitiesCard'
import { EPSG3857, getWGS84Coordinate, loadWMSProjections } from 'utils/mapUtils/CoordinateUtils'
import useAccountSetting from 'utils/customHook/useAccountSetting'
import {
    DEFAULT_MAP_X_COORDINATE,
    DEFAULT_MAP_Y_COORDINATE,
    DEFAULT_MAP_ZOOM,
    HOME_LIST_OF_LAYERS_OPACITY,
    HOME_LIST_OF_SELECTED_LAYERS,
    HOME_LIST_OF_SELECTED_STATIONS,
    HOME_SELECTED_BACKGROUND,
    MY_MAPS,
    OSM_BACKGROUND,
    RELIEF_BACKGROUND,
    ROADMAP_BACKGROUND,
    SATELLITE_BACKGROUND,
} from '../constants/HomeConstants'
import { TileWMS, XYZ } from 'ol/source'
import { KML } from 'ol/format'
import { removeNullKeys } from 'utils/StoreUtils'
import { checkStatus } from 'utils/ActionUtils'
import LogAction from 'log/actions/LogAction'
import { parseNowVariableURL } from 'utils/mapUtils/UrlUtils'
import { Style, Icon as Marker } from 'ol/style'
import { filterObsLinkedStations, getMarkerByStationType } from 'utils/StationUtils'
import { push } from 'connected-react-router'
import FollowAction from 'pages/online/follows/actions/FollowAction'
import { SUCCEEDED } from 'store/DataManagerConstants'
import useApplicationSetting from 'utils/customHook/useApplicationSetting'
import useProgressDispatch from 'utils/customHook/useProgressDispatch'
import { aquasysLogoPath, aquasysPath } from 'conf/SieauConstants'
import DtoAccountSettings from 'pages/account/dto/DtoAccountSettings'
import moment from 'moment'
import { getLogin } from 'utils/LocalStorageUtils'

const OlMap = ({
    coords,
    selectedStations = [],
    selectedLayers = [],
    selectedBackground = OSM_BACKGROUND,
    layersOpacity = [],
}) => {
    const {
        piezometersLight,
        hydrometers,
        pluviometers,
        qualitometersLight,
        productionUnits,
        citiesIndex,
        mapLayers,
        piezoObservatoryFollowResults,
        hydroObservatoryFollowResults,
        pluvioObservatoryFollowResults,
        qualitoObservatoryFollowResults,
        linkedStations,
    } = useSelector(store => ({
        piezometersLight: store.HomeReducer.piezometersLight,
        hydrometers: store.HomeReducer.hydrometers,
        pluviometers: store.HomeReducer.pluviometers,
        qualitometersLight: store.HomeReducer.qualitometersLight,
        productionUnits: store.HomeReducer.productionUnits,
        citiesIndex: store.HomeReducer.citiesIndex,
        mapLayers: store.HomeReducer.mapLayers,
        piezoObservatoryFollowResults: store.FollowReducer.piezoObservatoryFollowResults,
        hydroObservatoryFollowResults: store.FollowReducer.hydroObservatoryFollowResults,
        pluvioObservatoryFollowResults: store.FollowReducer.pluvioObservatoryFollowResults,
        qualitoObservatoryFollowResults: store.FollowReducer.qualitoObservatoryFollowResults,
        linkedStations: store.HomeReducer.linkedStations,
    }), shallowEqual)

    const [map, setMap] = useState()
    const [content, setContent] = useState()

    const rendered = useRef()
    const mapRef = useRef()
    mapRef.current = map

    const getListOfOfr = (typeName) => {
        switch (typeName) {
            case STATION_TYPE_NAME.piezometry:
                return piezoObservatoryFollowResults
            case STATION_TYPE_NAME.hydrometry:
                return hydroObservatoryFollowResults
            case STATION_TYPE_NAME.pluviometry:
                return pluvioObservatoryFollowResults
            case STATION_TYPE_NAME.quality:
                return qualitoObservatoryFollowResults
            default:
                return []
        }
    }

    const filteredLinkedStations = filterObsLinkedStations(linkedStations)

    const getInCrisisTag = allTags => {
        const inCrisisTag = [allTags.find(d => ['red', 'indianred', 'darkmagenta'].includes(d.color) || (d?.value || '')?.includes('alerte'))].filter(d => !!d)
        return inCrisisTag.length ? inCrisisTag.map(tag => ({ ...tag, color: ['red', 'indianred', 'darkmagenta'].includes(tag.color) ? tag.color : 'indianred' })) : []
    }

    const getOnAlertTag = allTags => {
        const alertTag = [allTags.find(d => (d?.value || '')?.includes('vigilance'))].filter(d => !!d)
        return alertTag.length ? alertTag.map(tag => ({ ...tag, color: 'orange' })) : []
    }

    const formatUnitPoint = (list, stationType, catchments) => list.map(l => {
        const links = filteredLinkedStations.filter(s => s.code === l.code)
        const uniqLinks = uniqWith(links, (linkA, linkB) => linkA.stationLinkedCode === linkB.stationLinkedCode && linkA.stationLinkedType === linkB.stationLinkedType)
        const catchmentsIds = catchments.map(c => c.id)
        const allTags = uniqLinks.filter(ul => catchmentsIds.includes(ul.stationLinkedId)).flatMap(ul => {
            const listOfOfr = getListOfOfr(ul.typeName)
            const ofr = listOfOfr.find(o => o.id === ul.stationLinkedId)
            return ofr?.data?.map(d => ({ ...d, stationName: ul.stationLinkedName || ul.stationLinkedCode })) || []
        })
        const tagsInCrisis = getInCrisisTag(allTags)
        const tagsOnAlert = tagsInCrisis.length ? tagsInCrisis : getOnAlertTag(allTags)
        const monitoringTags = tagsOnAlert.length ? tagsOnAlert : [allTags.find(d => ['green', 'lightgreen'].includes(d.color))].filter(d => !!d)
        const tagsNoData = monitoringTags.length ? monitoringTags : [allTags.find(d => ['grey', 'gray'].includes(d.color))].filter(d => !!d)
        return {
            ...l,
            marker: getMarkerByStationType(stationType, tagsNoData[0]?.color),
        }
    })

    const formatCatchmentPoint = (list, stationType) => list.map(l => {
        const listOfOfr = getListOfOfr(l.typeName)
        const ofr = listOfOfr.find(o => o.id === l.id)
        const allTags = ofr?.data?.map(d => ({ ...d, stationName: l.name || l.code })) || []
        const tagsInCrisis = getInCrisisTag(allTags)
        const tagsOnAlert = tagsInCrisis.length ? tagsInCrisis : getOnAlertTag(allTags)
        const monitoringTags = tagsOnAlert.length ? tagsOnAlert : [allTags.find(d => ['green', 'lightgreen'].includes(d.color))].filter(d => !!d)
        const tagsNoData = monitoringTags.length ? monitoringTags : [allTags.find(d => ['grey', 'gray'].includes(d.color))].filter(d => !!d)
        return {
            ...l,
            marker: getMarkerByStationType(stationType, tagsNoData[0]?.color),
        }
    })

    const formatPoint = (list, stationType) => list.map(l => ({
        ...l,
        marker: getMarkerByStationType(stationType, 'black'),
    }))

    const points = useMemo(() => {
        const catchmentsIds = piezoObservatoryFollowResults.filter(p => p.typeName === OBSERVATORY_STATION_TYPE_NAME.catchment).map(p => p.id)
        const catchments = piezometersLight.filter(piezo => catchmentsIds.includes(piezo.id))
        const piezos = piezometersLight.filter(piezo => !catchmentsIds.includes(piezo.id))
        const catchmentStations = selectedStations.includes(OBSERVATORY_STATION_TYPE_NAME.CATCHMENT) ? formatCatchmentPoint(catchments, OBSERVATORY_STATION_TYPE_NAME.catchment) : []
        const piezoStations = selectedStations.includes(OBSERVATORY_STATION_TYPE_NAME.PIEZOMETER) ? [...catchmentStations, ...formatPoint(piezos, STATION_TYPE_NAME.piezometer)] : catchmentStations
        const hydroStations = selectedStations.includes(OBSERVATORY_STATION_TYPE_NAME.HYDROMETRIC_STATION) ? [...piezoStations, ...formatPoint(hydrometers, STATION_TYPE_NAME.hydrometricStation)] : piezoStations
        const pluvioStations = selectedStations.includes(OBSERVATORY_STATION_TYPE_NAME.PLUVIOMETER) ? [...hydroStations, ...formatPoint(pluviometers, STATION_TYPE_NAME.pluviometer)] : hydroStations
        const qualitoStations = selectedStations.includes(OBSERVATORY_STATION_TYPE_NAME.QUALITOMETER) ? [...pluvioStations, ...formatPoint(qualitometersLight, STATION_TYPE_NAME.qualitometer)] : pluvioStations
        return selectedStations.includes(OBSERVATORY_STATION_TYPE_NAME.PRODUCTION_UNIT) ? [...qualitoStations, ...formatUnitPoint(productionUnits, STATION_TYPE_NAME.productionUnit, catchments)] : qualitoStations
    }, [selectedStations])

    const allPoints = useMemo(() => coords ? [{ x: coords.long, y: coords.lat, projection: 16, currentPosition: true }, ...points] : points, [points])

    const sourceBackground = useMemo(() => {
        switch (selectedBackground) {
            case OSM_BACKGROUND:
                return new OSM()
            case ROADMAP_BACKGROUND:
                return new XYZ({
                    url: 'http://mt0.google.com/vt/lyrs=m&hl=en&x={x}&y={y}&z={z}',
                })
            case RELIEF_BACKGROUND:
                return new XYZ({
                    url: 'http://mt0.google.com/vt/lyrs=p&hl=en&x={x}&y={y}&z={z}',
                })
            case SATELLITE_BACKGROUND:
                return new XYZ({
                    attributions: ['Tiles &copy Esri &mdash Source: Esri, i-cubed, USDA, USGS, AEX, GeoEye, Getmapping, Aerogrid, IGN, IGP, UPR-EGP, and the GIS User Community'],
                    url: 'http://server.arcgisonline.com/ArcGIS/rest/services/World_Imagery/MapServer/tile/{z}/{y}/{x}',
                })
            default:
                return new OSM()
        }
    }, [selectedBackground])

    const dispatch = useDispatch()

    const URL_SETTING = useApplicationSetting(MY_MAPS)

    const allMapLayers = useMemo(() => mapLayers.filter(ml => selectedLayers.includes(`${ml.id}`)).map(ml => {
        switch (parseInt(ml.typeLayer)) {
            case 0:
                return new TileLayer({
                    visible: true,
                    opacity: layersOpacity.find(v => v.layerName === ml.layer)?.opacity || 1,
                    source: new TileWMS({
                        url: parseNowVariableURL(ml.url, URL_SETTING),
                        projection: ml.projection || 'EPSG:3857',
                        params: { LAYERS: ml.layer, TILED: true },
                        serverType: 'geoserver',
                    }),
                })
            case 1:
                const color = ml.color || false
                const kmlFormatWater = new KML({
                    extractStyles: !color || false,
                })
                const vct = new VectorSource({
                    loader () {
                        fetch(parseNowVariableURL(ml.url, URL_SETTING), {
                            headers: { Accept: 'application/vnd.google-earth.kml+xml' },
                            method: 'GET',
                        })
                            .then(response => checkStatus({
                                200: ((rep) => rep.text()),
                                204: (() => []),
                            }, response))
                            .then((response = []) => {
                                const feature = kmlFormatWater.readFeatures(response, { featureProjection: EPSG3857 })
                                feature.map(f => f.setId(uuidv4()))
                                vct.addFeatures(feature)
                            }).catch(err => dispatch(LogAction.logError(err.stack)))
                    },
                    format: kmlFormatWater,
                })
                return new VectorLayer(removeNullKeys({
                    source: vct,
                }))
            default:
                return null
        }
    }), [selectedLayers])

    const allMarkersLayers = useMemo(() => allPoints.map((point) => {
        if (point.x && point.y) {
            const city = citiesIndex[point.townCode]
            const coordinates = getWGS84Coordinate(point)
            const { marker } = point
            const markersLayer = new VectorLayer({
                className: 'clickable',
                zIndex: 100,
                source: new VectorSource({
                    features: [
                        new Feature({
                            geometry: new Point(fromLonLat(coordinates)),
                            stationName: (
                                <Grid
                                    key={point.id}
                                    className='clickable'
                                    onClick={() => {
                                        if ([OBSERVATORY_STATION_TYPE_NAME.catchment,
                                            STATION_TYPE_NAME.piezometry,
                                            STATION_TYPE_NAME.hydrometry,
                                            STATION_TYPE_NAME.pluviometry,
                                            STATION_TYPE_NAME.quality].includes(point.typeName)) {
                                            dispatch(push(`follows/${point.typeName}/${point.id}`))
                                        } else if (point.typeName === STATION_TYPE_NAME.productionUnit) {
                                            dispatch(push(`units/${point.id}`))
                                        } else if (point.typeName === STATION_TYPE_NAME.resource) {
                                            dispatch(push(`resources/${point.id}`))
                                        }
                                    }}
                                    container
                                    justifyContent='flex-start'
                                    alignItems='center'
                                >
                                    <Grid item xs='auto' container alignItems='center' className='padding-right-2'>
                                        <img src={point.marker} style={{ height: 30, width: 'auto' }} />
                                    </Grid>
                                    <Grid item xs='auto' container direction='column'>
                                        <Grid>{point.code ? `${point.typeName !== STATION_TYPE_NAME.productionUnit ? `[${point.code}]` : ''} ` : ''}{point.name || ''}</Grid>
                                        <Grid><b>{city ? city.name : ''}</b></Grid>
                                    </Grid>
                                </Grid>
                            ),
                        }),
                    ],
                }),
                style: new Style({
                    image: new Marker({
                        anchor: [0.5, 1],
                        anchorXUnits: 'fraction',
                        anchorYUnits: 'fraction',
                        scale: 0.75,
                        src: marker,
                    }),
                }),
            })
            return markersLayer
        }
        return null
    }).filter(ml => !!ml), [allPoints])

    const userParamX = useAccountSetting(DEFAULT_MAP_X_COORDINATE)
    const userParamY = useAccountSetting(DEFAULT_MAP_Y_COORDINATE)
    const userParamZoom = useAccountSetting(DEFAULT_MAP_ZOOM)

    const applicationX = useApplicationSetting(DEFAULT_MAP_X_COORDINATE)
    const applicationY = useApplicationSetting(DEFAULT_MAP_Y_COORDINATE)
    const applicationZoom = useApplicationSetting(DEFAULT_MAP_ZOOM)

    const { defaultCenter, defaultZoom } = useMemo(() => {
        const valueX = isNil(userParamX) ? applicationX : userParamX
        const valueY = isNil(userParamY) ? applicationY : userParamY
        const valueZoom = isNil(userParamZoom) ? applicationZoom : userParamZoom

        const envX = isNil(valueX) ? process.env.REACT_APP_MAP_X : valueX
        const envY = isNil(valueY) ? process.env.REACT_APP_MAP_Y : valueY
        const envZoom = isNil(valueZoom) ? process.env.REACT_APP_MAP_ZOOM : valueZoom

        return { defaultCenter: fromLonLat([Number(envX), Number(envY)]), defaultZoom: Number(envZoom) }
    }, [userParamX, userParamY, userParamZoom, applicationX, applicationY, applicationZoom])

    const createParameter = (parameter, value) => {
        return new DtoAccountSettings({
            login: getLogin(),
            parameter,
            updateDate: moment().valueOf(),
            value,
        })
    }

    const onChangeMap = (value) => {
        const zoom = value.getView().getZoom()
        const point = transform(value.getView().getCenter(), 'EPSG:3857', 'EPSG:4326')
        const xCoordinate = point[0]
        const yCoordinate = point[1]

        const zoomParameter = createParameter(DEFAULT_MAP_ZOOM, `${zoom}`)
        const xParameter = createParameter(DEFAULT_MAP_X_COORDINATE, `${xCoordinate}`)
        const yParameter = createParameter(DEFAULT_MAP_Y_COORDINATE, `${yCoordinate}`)
        const settings = [xParameter, yParameter, zoomParameter]
        if (!(applicationX === `${xCoordinate}` && applicationY === `${yCoordinate}`)) {
            dispatch(HomeAction.updateMultipleSettings({ settings }))
        }
    }

    useEffect(() => {
        if (!rendered.current && !isNil(allMarkersLayers) && !isNil(allMapLayers)) {
            const container = document.getElementById('popup')
            const closer = document.getElementById('popup-closer')
            const overlay = new Overlay({
                element: container,
                autoPan: {
                    animation: {
                        duration: 250,
                    },
                },
            })
            const layers = [
                new TileLayer({
                    zIndex: 0,
                    source: sourceBackground,
                }),
                ...allMarkersLayers,
                ...allMapLayers,
            ]
            const olMap = new Map({
                target: mapRef.current,
                controls: defaultControls().extend([
                    new ScaleLine({
                        units: 'metric',
                    }),
                    new Rotate(),
                    new FullScreen({
                        className: 'ol-full-screen',
                    }),
                ]),
                layers,
                overlays: [overlay],
            })
            const wgs84Sites = compact(allPoints.map((point) => {
                if (point.x && point.y) {
                    return getWGS84Coordinate(point)
                }
                return null
            }))
            const sumX = wgs84Sites.map((coord) => coord[0]).reduce((a, b) => a + b, 0) || 2.549
            const sumY = wgs84Sites.map((coord) => coord[1]).reduce((a, b) => a + b, 0) || 47.233
            const center = coords && coords.long && coords.lat ? fromLonLat([coords.long, coords.lat]) : (!isNaN(defaultCenter?.[0]) && !isNaN(defaultCenter?.[1])) ? defaultCenter : fromLonLat([sumX / (wgs84Sites.length || 1), sumY / (wgs84Sites.length || 1)])

            olMap.setView(new View({
                center,
                zoom: coords?.zoom ?? (!isNaN(defaultZoom) ? defaultZoom : 6),
            }))

            closer.onclick = () => {
                overlay.setPosition(undefined)
                closer.blur()
                return false
            }
            olMap.on('singleclick', (evt) => {
                const stationName = olMap.forEachFeatureAtPixel(evt.pixel, (feature) => {
                    return feature.get('stationName')
                })
                if (stationName) {
                    container.style.display = 'block'
                    const coordinate = evt.coordinate
                    setContent(stationName)
                    overlay.setPosition(coordinate)
                } else {
                    container.style.display = 'none'
                }
            })
            olMap.on('moveend', (() => {
                onChangeMap(olMap)
            }))

            setMap(olMap)
            rendered.current = true
        }
    }, [allMarkersLayers, allMapLayers, sourceBackground, defaultCenter, defaultZoom])

    useEffect(() => {
        if (!isNil(map)) {
            const layers = [
                new TileLayer({
                    zIndex: 0,
                    source: sourceBackground,
                }),
                ...allMarkersLayers,
                ...allMapLayers,
            ]

            map.setLayers(layers)
        }
    }, [allMarkersLayers, allMapLayers, sourceBackground])

    const mdMatches = useMediaQuery((t) => t.breakpoints.up('md'))

    return (
        <div style={{ position: 'relative' }}>
            <div ref={mapRef} style={{ width: '100%', height: mdMatches ? '100%' : '40vh', borderRadius: '5px', overflow: 'hidden' }} />
            <div id='popup' className='ol-popup'>
                <a href='#' id='popup-closer' className='ol-popup-closer' />
                <div id='popup-content'>{content}</div>
            </div>
            <div style={{ position: 'absolute', bottom: '2rem', right: '0.5rem' }}>
                <a href={aquasysPath} target='_blank' rel='noopener noreferrer'>
                    <img src={aquasysLogoPath} alt='logo Aquasys' width='auto' height='30px' />
                </a>
            </div>
        </div>
    )
}

OlMap.propTypes = {
    coords: PropTypes.shape({
        long: PropTypes.number,
        lat: PropTypes.number,
        zoom: PropTypes.number,
    }),
    selectedStations: PropTypes.arrayOf(PropTypes.string),
    selectedLayers: PropTypes.arrayOf(PropTypes.string),
    layersOpacity: PropTypes.arrayOf(PropTypes.shape({
        layerName: PropTypes.string,
        opacity: PropTypes.number,
    })),
    selectedBackground: PropTypes.string,
}

const MARGE = '5px'

const StationTab = ({
    selectedStations = [],
    setSelectedStations = () => {},
}) => {
    const {
        piezometersLight,
        hydrometers,
        pluviometers,
        qualitometersLight,
        productionUnits,
        piezoObservatoryFollowResults,
    } = useSelector(store => ({
        piezometersLight: store.HomeReducer.piezometersLight,
        hydrometers: store.HomeReducer.hydrometers,
        pluviometers: store.HomeReducer.pluviometers,
        qualitometersLight: store.HomeReducer.qualitometersLight,
        productionUnits: store.HomeReducer.productionUnits,
        piezoObservatoryFollowResults: store.FollowReducer.piezoObservatoryFollowResults,
    }), shallowEqual)

    const stationLength = useMemo(() => piezometersLight.length + hydrometers.length + pluviometers.length + qualitometersLight.length + productionUnits.length, [hydrometers.length, piezometersLight.length, pluviometers.length, productionUnits.length, qualitometersLight.length])

    const moduleByType = useMemo(() => {
        const catchments = piezoObservatoryFollowResults.filter(p => p.typeName === OBSERVATORY_STATION_TYPE_NAME.catchment)
        return {
            [OBSERVATORY_STATION_TYPE_NAME.catchment]: {
                hab: H_PIEZO_MODULE,
                authorized: PIEZO,
                length: catchments.length,
                obsStationType: OBSERVATORY_STATION_TYPE_NAME.CATCHMENT,
            },
            [STATION_TYPE_NAME.piezometer]: {
                hab: H_PIEZO_MODULE,
                authorized: PIEZO,
                length: piezometersLight.length - catchments.length,
                obsStationType: OBSERVATORY_STATION_TYPE_NAME.PIEZOMETER,
            },
            [STATION_TYPE_NAME.hydrometricStation]: {
                hab: H_HYDRO_MODULE,
                authorized: HYDRO,
                length: hydrometers.length,
                obsStationType: OBSERVATORY_STATION_TYPE_NAME.HYDROMETRIC_STATION,
            },
            [STATION_TYPE_NAME.pluviometer]: {
                hab: H_PLUVIO_MODULE,
                authorized: PLUVIO,
                length: pluviometers.length,
                obsStationType: OBSERVATORY_STATION_TYPE_NAME.PLUVIOMETER,
            },
            [STATION_TYPE_NAME.qualitometer]: {
                hab: H_QUALITO_MODULE,
                authorized: QUALITO,
                length: qualitometersLight.length,
                obsStationType: OBSERVATORY_STATION_TYPE_NAME.QUALITOMETER,
            },
            [STATION_TYPE_NAME.productionUnit]: {
                hab: H_PRODUCTION_MODULE,
                authorized: PRODUCTION,
                length: productionUnits.length,
                obsStationType: OBSERVATORY_STATION_TYPE_NAME.PRODUCTION_UNIT,
            },
        }
    }, [hydrometers.length, piezoObservatoryFollowResults, piezometersLight.length, pluviometers.length, productionUnits.length, qualitometersLight.length])

    const stationsTypes = useMemo(() => keys(moduleByType).filter(key => componentHasHabilitations(moduleByType[key].hab) && isAuthorized(moduleByType[key].authorized)), [moduleByType])

    return (
        <Grid container sx={{ padding: MARGE, fontWeight: 'bold' }}>
            <Grid item xs={12} sx={{ backgroundColor: mainBlack, color: mainWhite, height: '35px', textAlign: 'center', display: 'grid', alignItems: 'center', borderRadius: '5px' }}>
                <span>{stationLength} {i18n.availableStations}</span>
            </Grid>
            <Grid container item xs={12} alignItems='center' justifyContent='space-between' sx={{ marginTop: MARGE }}>
                {stationsTypes.map(st => {
                    const img = getMarkerByStationType(st)
                    return (
                        <Grid container key={st} item xs={12} sx={{ marginTop: '9px', borderBottom: `solid 1px ${mainBlack}`, paddingBottom: '9px' }} justifyContent='space-between'>
                            <Grid container item xs={10} sx={{ paddingLeft: '0.5rem' }} alignItems='center'>
                                <Grid item>
                                    {!!img && (
                                        <img src={img} style={{ height: '30px' }} />
                                    )}
                                </Grid>
                                <Grid container item xs={8} alignItems='center' sx={{ paddingLeft: '1rem' }}>
                                    <Grid item xs={12}>
                                        <span>{`${i18n[st]} (${moduleByType[st].length})`}</span>
                                    </Grid>
                                </Grid>
                            </Grid>
                            <Grid item>
                                <Checkbox
                                    checked={selectedStations.includes(moduleByType[st].obsStationType)}
                                    color='primary'
                                    onChange={event => {
                                        if (event.target.checked) {
                                            return setSelectedStations([...selectedStations, moduleByType[st].obsStationType])
                                        }
                                        return setSelectedStations(selectedStations.filter(p => p !== moduleByType[st].obsStationType))
                                    }}
                                />
                            </Grid>
                        </Grid>
                    )
                })}
            </Grid>
        </Grid>
    )
}

StationTab.propTypes = {
    selectedStations: PropTypes.arrayOf(PropTypes.string),
    setSelectedStations: PropTypes.func,
}

const LayerTab = ({
    selectedLayers = [],
    setSelectedLayers = () => {},
    layersOpacity = [],
    setLayersOpacity = () => [],
    setLayersOpacityCommitted = () => [],
}) => {
    const {
        mapLayers,
        cartographyThemes,
    } = useSelector(store => ({
        mapLayers: store.HomeReducer.mapLayers,
        cartographyThemes: store.HomeReducer.cartographyThemes,
    }), shallowEqual)

    const [layers, setLayers] = useState([])

    const dispatch = useDispatch()

    const myMaps = useApplicationSetting(MY_MAPS, setting => setting || '')

    const getLegend = (url, layerName) => {
        if (url.includes('#MYMAPS/?')) {
            return `${parseNowVariableURL(url, myMaps)}&service=WMS&request=GetLegendGraphic&sld_version=1.1.0&format=image/jpeg&layer=${layerName}`
        }
        const urlLower = url.toLowerCase()
        return `${url}${urlLower.indexOf('?') >= 0 ? urlLower.indexOf('version') >= 0 ? '' : 'version=1.3.0' : '?version=1.3.0'}&service=WMS&request=GetLegendGraphic&sld_version=1.1.0&format=image/jpeg&layer=${layerName}`
    }

    const { isLoaded, progress } = useProgressDispatch(() => mapLayers.filter(ml => cartographyThemes.some(ct => ct.id === ml.theme)).map(la => {
        const legend = la.url ? getLegend(la.url, la.layer) : undefined
        if (!legend) {
            setLayers(prev => [...prev, { ...la, legend: undefined }])
            return undefined
        }
        return dispatch(HomeAction.checkUrl(legend)).then(validUrl => setLayers(prev => [...prev, { ...la, legend: validUrl ? legend : undefined }]))
    }).filter(u => !!u), [mapLayers, cartographyThemes])

    return isLoaded ? (
        <Grid container sx={{ padding: MARGE, fontWeight: 'bold' }}>
            <Grid item xs={12} sx={{ backgroundColor: mainBlack, color: mainWhite, height: '35px', textAlign: 'center', display: 'grid', alignItems: 'center', borderRadius: '5px' }}>
                <span>{i18n.mapLayers}</span>
            </Grid>
            <Grid container item xs={12} alignItems='center' justifyContent='space-between' sx={{ marginTop: MARGE }}>
                {sortBy(layers, 'name').map(la => {
                    const layerName = la.layer
                    const layerOpacity = layersOpacity.find(v => v.layerName === layerName)?.opacity || 1

                    return (
                        <Grid container key={la.id} item xs={12} sx={{ marginTop: '9px', borderBottom: `solid 1px ${mainBlack}`, paddingBottom: '9px' }}>
                            <Grid container item xs={12} justifyContent='space-between' alignItems='center'>
                                <Grid container item xs={8.7} alignItems='center'>
                                    <Grid item>
                                        <Checkbox
                                            checked={selectedLayers.includes(`${la.id}`)}
                                            color='primary'
                                            onChange={event => {
                                                if (event.target.checked) {
                                                    return setSelectedLayers([...selectedLayers, `${la.id}`])
                                                }
                                                return setSelectedLayers(selectedLayers.filter(sl => sl !== `${la.id}`))
                                            }}
                                        />
                                    </Grid>
                                    <Grid container item xs={10} alignItems='center' sx={{ paddingLeft: '1rem' }}>
                                        <Grid item xs={12}>
                                            <span>{la.name}</span>
                                        </Grid>
                                    </Grid>
                                </Grid>
                                <Grid container item xs={3} alignItems='center' justifyContent='space-between'>
                                    <Grid item xs={7}>
                                        <Slider
                                            sliderStyle={{ margin: 0 }}
                                            style={{ margin: 0 }}
                                            min={0}
                                            max={1}
                                            step={0.1}
                                            value={layerOpacity}
                                            onChange={(_, opacity) => {
                                                setLayersOpacity([
                                                    ...layersOpacity.filter(p => p.layerName !== layerName),
                                                    { layerName, opacity },
                                                ])
                                            }}
                                            onChangeCommitted={(_, opacity) => setLayersOpacityCommitted([
                                                ...layersOpacity.filter(p => p.layerName !== layerName),
                                                { layerName, opacity },
                                            ])}
                                        />
                                    </Grid>
                                    {!!la.legend && (
                                        <Grid item>
                                            <Tooltip
                                                placement='left-start'
                                                arrow
                                                PopperProps={{
                                                    disablePortal: true,
                                                }}
                                                componentsProps={{
                                                    tooltip: {
                                                        sx: {
                                                            maxWidth: 'fit-content',
                                                            width: 'fit-content',
                                                        },
                                                    },
                                                }}
                                                title={
                                                    <>
                                                        <img src={la.legend} alt={layerName} />
                                                    </>}
                                            >
                                                <Icon>info_outline</Icon>
                                            </Tooltip>
                                        </Grid>
                                    )}
                                </Grid>
                            </Grid>
                            {!!la.source && (
                                <Grid item xs={12} sx={{ paddingLeft: 'calc(42px + 1rem)' }}>
                                    <i style={{ fontSize: '11px' }}>{i18n.source}: { la.source }</i>
                                </Grid>
                            )}
                        </Grid>
                    )
                })}
            </Grid>
        </Grid>
    ) : (
        <Grid container item xs={12}>
            <ProgressBar progress={progress} />
        </Grid>
    )
}

LayerTab.propTypes = {
    selectedLayers: PropTypes.arrayOf(PropTypes.string),
    setSelectedLayers: PropTypes.func,
    layersOpacity: PropTypes.arrayOf(PropTypes.shape({
        layerName: PropTypes.string,
        opacity: PropTypes.number,
    })),
    setLayersOpacity: PropTypes.func,
    setLayersOpacityCommitted: PropTypes.func,
}

const BackgroundTab = ({
    selectedBackground,
    setSelectedBackground = () => {},
}) => {
    const backgrounds = useMemo(() => [{
        value: OSM_BACKGROUND,
        label: i18n.openStreetMap,
    }, {
        value: ROADMAP_BACKGROUND,
        label: i18n.roadMap,
    }, {
        value: RELIEF_BACKGROUND,
        label: i18n.relief,
    }, {
        value: SATELLITE_BACKGROUND,
        label: i18n.satellite,
    }], [])

    const handleChange = (event) => setSelectedBackground(event.target.value)

    return (
        <Grid container sx={{ padding: MARGE, fontWeight: 'bold' }}>
            <Grid item xs={12} sx={{ backgroundColor: mainBlack, color: mainWhite, height: '35px', textAlign: 'center', display: 'grid', alignItems: 'center', borderRadius: '5px' }}>
                <span>{i18n.backgroundLayers}</span>
            </Grid>
            <Grid container item xs={12} alignItems='center' justifyContent='space-between' sx={{ marginTop: MARGE, paddingLeft: '0.5rem' }}>
                <RadioGroup
                    value={selectedBackground}
                    onChange={handleChange}
                    sx={{ width: '100%' }}
                >
                    {backgrounds.map(o => (
                        <FormControlLabel
                            value={o?.value}
                            control={<Radio color='primary' />}
                            label={o?.label}
                            key={o.value}
                            labelPlacement='start'
                            sx={{
                                margin: 0,
                                justifyContent: 'space-between',
                                marginTop: '9px',
                                borderBottom: `solid 1px ${mainBlack}`,
                                paddingBottom: '9px',
                                '.MuiFormControlLabel-label': {
                                    fontWeight: 'bold',
                                },
                            }}
                        />
                    ))}
                </RadioGroup>
            </Grid>
        </Grid>
    )
}

BackgroundTab.propTypes = {
    selectedBackground: PropTypes.string,
    setSelectedBackground: PropTypes.func,
}

const TAB_STATIONS = 1
const TAB_LAYERS = 2
const TAB_BACKGROUND = 3

const Home = () => {
    const {
        piezometersLight,
        hydrometers,
        pluviometers,
        qualitometersLight,
        productionUnits,
        mapLayers,
        cartographyThemes,
        piezoObservatoryFollowResults,
        hydroObservatoryFollowResults,
        pluvioObservatoryFollowResults,
        qualitoObservatoryFollowResults,

        accountHabilitationsStatus,
        citiesStatus,
        piezometersLightStatus,
        hydrometersStatus,
        pluviometersStatus,
        qualitometersLightStatus,
        productionUnitsStatus,
        mapLayersStatus,
        cartographyThemesStatus,
        piezoObservatoryFollowResultsStatus,
        hydroObservatoryFollowResultsStatus,
        pluvioObservatoryFollowResultsStatus,
        qualitoObservatoryFollowResultsStatus,
        linkedStationsStatus,

        cmsTitle,
    } = useSelector(store => ({
        piezometersLight: store.HomeReducer.piezometersLight,
        hydrometers: store.HomeReducer.hydrometers,
        pluviometers: store.HomeReducer.pluviometers,
        qualitometersLight: store.HomeReducer.qualitometersLight,
        productionUnits: store.HomeReducer.productionUnits,
        mapLayers: store.HomeReducer.mapLayers,
        cartographyThemes: store.HomeReducer.cartographyThemes,
        piezoObservatoryFollowResults: store.FollowReducer.piezoObservatoryFollowResults,
        hydroObservatoryFollowResults: store.FollowReducer.hydroObservatoryFollowResults,
        pluvioObservatoryFollowResults: store.FollowReducer.pluvioObservatoryFollowResults,
        qualitoObservatoryFollowResults: store.FollowReducer.qualitoObservatoryFollowResults,

        accountHabilitationsStatus: store.DataManagerReducer.account.accountHabilitationsStatus,
        citiesStatus: store.DataManagerReducer.home.citiesStatus,
        piezometersLightStatus: store.DataManagerReducer.piezometer.piezometersLightStatus,
        hydrometersStatus: store.DataManagerReducer.hydrometers.hydrometersStatus,
        pluviometersStatus: store.DataManagerReducer.pluviometers.pluviometersStatus,
        qualitometersLightStatus: store.DataManagerReducer.qualitometers.qualitometersLightStatus,
        productionUnitsStatus: store.DataManagerReducer.productionUnits.productionUnitsStatus,
        mapLayersStatus: store.DataManagerReducer.home.mapLayersStatus,
        cartographyThemesStatus: store.DataManagerReducer.home.cartographyThemesStatus,
        piezoObservatoryFollowResultsStatus: store.DataManagerReducer.follow.piezoObservatoryFollowResultsStatus,
        hydroObservatoryFollowResultsStatus: store.DataManagerReducer.follow.hydroObservatoryFollowResultsStatus,
        pluvioObservatoryFollowResultsStatus: store.DataManagerReducer.follow.pluvioObservatoryFollowResultsStatus,
        qualitoObservatoryFollowResultsStatus: store.DataManagerReducer.follow.qualitoObservatoryFollowResultsStatus,
        linkedStationsStatus: store.DataManagerReducer.home.linkedStationsStatus,

        cmsTitle: store.CmsReducer.cmsTitle,
    }), shallowEqual)

    const defaultSelectedStations = useAccountSetting(HOME_LIST_OF_SELECTED_STATIONS, setting => setting?.split(',') || undefined)
    const defaultSelectedLayers = useAccountSetting(HOME_LIST_OF_SELECTED_LAYERS, setting => setting?.split(',') || undefined)
    const defaultLayersOpacity = useAccountSetting(HOME_LIST_OF_LAYERS_OPACITY, v => v ? JSON.parse(v) : [])
    const defaultSelectedBackground = useAccountSetting(HOME_SELECTED_BACKGROUND, setting => setting || undefined)

    const [selectedStations, setSelectedStations] = useState(defaultSelectedStations || [])
    const [selectedLayers, setSelectedLayers] = useState(defaultSelectedLayers || [])
    const [layersOpacity, setLayersOpacity] = useState(defaultLayersOpacity || [])
    const [selectedBackground, setSelectedBackground] = useState(defaultSelectedBackground || OSM_BACKGROUND)

    const [piezoObservatoryProgress, setPiezoObservatoryProgress] = useState(0)
    const [hydroObservatoryProgress, setHydroObservatoryProgress] = useState(0)
    const [pluvioObservatoryProgress, setPluvioObservatoryProgress] = useState(0)
    const [qualitoObservatoryProgress, setQualitoObservatoryProgress] = useState(0)
    const [linkedStationsProgress, setLinkedStationsProgress] = useState(0)

    const { progress, isLoaded } = useStateProgress([
        accountHabilitationsStatus,
        citiesStatus,
        piezometersLightStatus,
        hydrometersStatus,
        pluviometersStatus,
        qualitometersLightStatus,
        productionUnitsStatus,
        mapLayersStatus,
        cartographyThemesStatus,
    ])

    const { isLoaded: linkedStationsLoaded } = useStateProgress([
        linkedStationsStatus,
    ])

    const { isLoaded: observatoryResultsLoaded } = useStateProgress([
        piezoObservatoryFollowResultsStatus,
        hydroObservatoryFollowResultsStatus,
        pluvioObservatoryFollowResultsStatus,
        qualitoObservatoryFollowResultsStatus,
    ])

    const dispatch = useDispatch()

    useEffect(() => {
        if (!mapLayers.length) {
            dispatch(HomeAction.fetchMapLayers())
        }
        if (!cartographyThemes.length) {
            dispatch(HomeAction.fetchCartographyThemes())
        }
        if (!piezometersLight.length) {
            dispatch(HomeAction.fetchPiezometersLight())
        }
        if (!hydrometers.length) {
            dispatch(HomeAction.fetchHydrometers())
        }
        if (!pluviometers.length) {
            dispatch(HomeAction.fetchPluviometers())
        }
        if (!qualitometersLight.length) {
            dispatch(HomeAction.fetchQualitometersLight())
        }
        if (!productionUnits.length) {
            dispatch(HomeAction.fetchProductionUnits())
        }
    }, [])

    useEffect(() => {
        if (!piezoObservatoryFollowResults.length && piezometersLightStatus === SUCCEEDED) {
            const piezoIds = piezometersLight.map(p => p.id)
            dispatch(FollowAction.fetchpiezoObservatoryFollowResults({ ids: piezoIds, progressCallback: setPiezoObservatoryProgress }))
        }
    }, [piezoObservatoryFollowResults.length, piezometersLightStatus])

    useEffect(() => {
        if (!hydroObservatoryFollowResults.length && hydrometersStatus === SUCCEEDED) {
            const hydroIds = hydrometers.map(p => p.id)
            dispatch(FollowAction.fetchhydroObservatoryFollowResults({ ids: hydroIds, progressCallback: setHydroObservatoryProgress }))
        }
    }, [hydroObservatoryFollowResults.length, hydrometersStatus])

    useEffect(() => {
        if (!pluvioObservatoryFollowResults.length && pluviometersStatus === SUCCEEDED) {
            const pluvioIds = pluviometers.map(p => p.id)
            dispatch(FollowAction.fetchpluvioObservatoryFollowResults({ ids: pluvioIds, progressCallback: setPluvioObservatoryProgress }))
        }
    }, [pluvioObservatoryFollowResults.length, pluviometersStatus])

    useEffect(() => {
        if (!qualitoObservatoryFollowResults.length && qualitometersLightStatus === SUCCEEDED) {
            const qualitoIds = qualitometersLight.map(p => p.id)
            dispatch(FollowAction.fetchqualitoObservatoryFollowResults({ ids: qualitoIds, progressCallback: setQualitoObservatoryProgress }))
        }
    }, [qualitoObservatoryFollowResults.length, qualitometersLightStatus])

    useEffect(() => {
        if (productionUnitsStatus === SUCCEEDED) {
            dispatch(HomeAction.fetchAllLinkedUnitsStations({ ids: productionUnits.map((p) => p.id), progressCallback: setLinkedStationsProgress }))
        }
    }, [productionUnits])

    useEffect(() => {
        if (!isNil(defaultSelectedStations)) {
            setSelectedStations(defaultSelectedStations)
        }
    }, [defaultSelectedStations])

    useEffect(() => {
        if (!isNil(defaultSelectedLayers)) {
            setSelectedLayers(defaultSelectedLayers)
        }
    }, [defaultSelectedLayers])

    useEffect(() => {
        if (!isNil(defaultLayersOpacity)) {
            setLayersOpacity(defaultLayersOpacity)
        }
    }, [defaultLayersOpacity])

    useEffect(() => {
        if (!isNil(defaultSelectedBackground)) {
            setSelectedBackground(defaultSelectedBackground)
        }
    }, [defaultSelectedBackground])

    useTitle(() => [
        {
            title: i18n.home,
            href: 'home',
        },
        loadWMSProjections(),
    ], [])

    const tabs = [{
        icon: 'format_list_bulleted',
        constant: TAB_STATIONS,
        label: i18n.stations,
    }, {
        icon: 'layers',
        constant: TAB_LAYERS,
        label: i18n.mapLayers,
    }, {
        icon: 'insert_photo',
        constant: TAB_BACKGROUND,
        label: i18n.backgroundLayers,
    }]

    const heightMenu = cmsTitle.id ? '128px' : '116px'

    const mdMatches = useMediaQuery((t) => t.breakpoints.up('md'))

    return (
        <Grid container sx={{ height: `calc(100vh - ${heightMenu})`, maxHeight: `calc(100vh - ${heightMenu})`, padding: '10px' }}>
            <Grid item md={8.5} xs={12} sx={{ position: 'relative', overflow: 'hidden' }}>
                {(isLoaded && linkedStationsLoaded && observatoryResultsLoaded) ? (
                    <>
                        <SimpleTabSideList
                            position='right'
                            defaultTab={TAB_STATIONS}
                            tabs={tabs}
                        >
                            {
                                tab => (
                                    <>
                                        {
                                            tab === TAB_STATIONS && (
                                                <StationTab
                                                    selectedStations={selectedStations}
                                                    setSelectedStations={newSelectedStations => {
                                                        setSelectedStations(newSelectedStations)
                                                        dispatch(HomeAction.updateSetting({ settingName: HOME_LIST_OF_SELECTED_STATIONS, value: newSelectedStations.join(','), refreshData: true }))
                                                    }}
                                                />
                                            )
                                        }
                                        {
                                            tab === TAB_LAYERS && (
                                                <LayerTab
                                                    selectedLayers={selectedLayers}
                                                    setSelectedLayers={newLayers => {
                                                        setSelectedLayers(newLayers)
                                                        dispatch(HomeAction.updateSetting({ settingName: HOME_LIST_OF_SELECTED_LAYERS, value: newLayers.join(',') }))
                                                    }}
                                                    layersOpacity={layersOpacity}
                                                    setLayersOpacity={newLayersOpacity => setLayersOpacity(newLayersOpacity)}
                                                    setLayersOpacityCommitted={newLayersOpacity => {
                                                        setLayersOpacity(newLayersOpacity)
                                                        dispatch(HomeAction.updateSetting({ settingName: HOME_LIST_OF_LAYERS_OPACITY, value: JSON.stringify(newLayersOpacity) }))
                                                    }}
                                                />
                                            )
                                        }
                                        {
                                            tab === TAB_BACKGROUND && (
                                                <BackgroundTab
                                                    selectedBackground={selectedBackground}
                                                    setSelectedBackground={newSelectedBackground => {
                                                        setSelectedBackground(newSelectedBackground)
                                                        dispatch(HomeAction.updateSetting({ settingName: HOME_SELECTED_BACKGROUND, value: newSelectedBackground }))
                                                    }}
                                                />
                                            )
                                        }
                                    </>
                                )
                            }

                        </SimpleTabSideList>
                        <OlMap
                            selectedStations={selectedStations}
                            selectedLayers={selectedLayers}
                            selectedBackground={selectedBackground}
                            layersOpacity={layersOpacity}
                        />
                    </>
                ) : <ProgressBar progress={(progress + piezoObservatoryProgress + hydroObservatoryProgress + pluvioObservatoryProgress + qualitoObservatoryProgress + linkedStationsProgress) / 6} />}
            </Grid>
            <Grid item md={3.5} xs={12} sx={{ paddingLeft: '10px' }}>
                <ActualitiesCard height={mdMatches ? `calc(100vh - ${cmsTitle.id ? '150px' : '136px'})` : '40vh'} />
            </Grid>
        </Grid>
    )
}

export default Home
