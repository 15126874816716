import { Grid } from '@mui/material'
import React, { useEffect } from 'react'
import i18n from 'simple-react-i18n'
import PropTypes from 'prop-types'
import ReactECharts from 'echarts-for-react'
import * as echarts from 'echarts/lib/echarts'
import { shallowEqual, useDispatch, useSelector } from 'react-redux'
import { countBy, minBy, range } from 'lodash'
import { getColorFromPalette } from 'utils/ColorUtil'
import { getBeginingOfTheYear, getEndOfTheYear, getYear } from 'utils/DateUtil'
import moment from 'moment'
import { push } from 'connected-react-router'
import FollowAction from 'pages/online/follows/actions/FollowAction'
import { exportPictureIcon } from './EChartUtils'
import DtoQualitometer from 'pages/home/dto/DtoQualitometer'

const OperationsGraph = ({
    qualitometer = {},
}) => {
    const dispatch = useDispatch()
    const {
        operations,
    } = useSelector(store => ({
        operations: store.FollowReducer.operations,
    }), shallowEqual)

    const years = range(getYear(minBy(operations, 'date')?.date), moment().year() + 1, 1)

    const numberByYear = countBy(operations, o => getYear(o.date))
    const data = years.map(year => numberByYear[year] || 0)

    const graphicName = `${qualitometer.code || ''} ${i18n.operations}`

    const options = {
        series: [{
            type: 'bar',
            name: graphicName,
            color: getColorFromPalette(0),
            data,
        }],
        xAxis: [{
            type: 'category',
            data: years,
        }],
        yAxis: [{
            type: 'value',
            minInterval: 1,
            showSplitLine: true,
        }],
        grid: {
            top: '15%',
            left: '2%',
            right: '4%',
            bottom: '15%',
            containLabel: true,
            height: 150,
        },
        tooltip: {
            trigger: 'axis',
            axisPointer: {
                type: 'shadow',
                animation: false,
                label: {
                    backgroundColor: '#505765',
                },
            },
        },
        toolbox: {
            top: 0,
            right: '35px',
            showTitle: false,
            itemSize: 18,
            tooltip: {
                show: true,
                position: 'bottom',
                formatter: (param) => param.title,
            },
            feature: {
                saveAsImage: {
                    show: true,
                    title: i18n.pictureExport,
                    icon: exportPictureIcon,
                    name: graphicName,
                },
            },
        },
    }

    return (
        <>
            <h6 style={{ fontWeight: 'bold', textAlign: 'center', margin: '30px 0 0', fontSize: 18 }}>{i18n.operationsHistory}</h6>
            <ReactECharts
                echarts={echarts}
                option={options}
                notMerge={true}
                lazyUpdate={true}
                style={{ height: 210 }}
                onEvents={{
                    click: ({ name: year }) => {
                        const startDate = getBeginingOfTheYear(year)
                        const endDate = getEndOfTheYear(year)
                        dispatch(push(`/station/quality/${qualitometer.id}/hydrobioMonitoring?startDate=${startDate}&endDate=${endDate}`))
                    },
                }}
            />
        </>
    )
}

OperationsGraph.propTypes = {
    qualitometer: PropTypes.instanceOf(DtoQualitometer),
}

const SituationHydrobioPanel = ({
    qualitometer = {},
}) => {
    const dispatch = useDispatch()
    const {
        operations,
    } = useSelector(store => ({
        operations: store.FollowReducer.operations,
    }), shallowEqual)

    useEffect(() => {
        if (!operations.length) {
            dispatch(FollowAction.fetchQualitometerOperations(qualitometer.id))
        }
    }, [dispatch, operations.length, qualitometer.id])

    return !!operations.length && (
        <Grid container >
            <Grid item xs={12}>
                <OperationsGraph qualitometer={qualitometer} />
            </Grid>
        </Grid>
    )
}

SituationHydrobioPanel.propTypes = {
    qualitometer: PropTypes.instanceOf(DtoQualitometer),
}

export default SituationHydrobioPanel