export default class DtoPiezometerContributorLink {
    constructor(obj = {}) {
        this.idStation = obj.idStation
        this.idContributor = obj.idContributor
        this.startDate = obj.startDate
        this.endDate = obj.endDate
        this.internalReference = obj.internalReference
        this.contributorType = obj.contributorType
        this.contactCode = obj.contactCode
    }
}