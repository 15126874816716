import i18n from 'simple-react-i18n'
import DtoReferencial from './DtoReferencial'

export default class WatermassItemDto extends DtoReferencial {
    constructor(object) {
        super(object)
        this.code = object.code
        this.name = object.label
        this.euroCode = object.europeanCode
        this.id = object.code
        this.headers = ['code', 'name', 'euroCode', 'watermassType']

        if (object.watermassType >= 0 && object.watermassType <= 8) {
            this.watermassType = this.getWatermassTypes()[object.watermassType]
        } else {
            this.watermassType = ''
        }
    }

    getWatermassTypes() {
        return [i18n.undergroundWaterPoint, i18n.waterFlow, i18n.waterPlan, i18n.hugeWaterFlow, i18n.marineEnvironnment, i18n.otherWaterFlowAndRavins, i18n.source, i18n.distributedWater, i18n.treatedWater]
    }
}
